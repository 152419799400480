import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { Eyes, EyesHide } from 'components/Icons'
import { useState } from 'react'
import FormText from '../Text'
import useStyles from './styles'

import DATA from './constData.json'

const FormPassword = ({
  id,
  error,
  label,
  value,
  onChange,
  placeholder,
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
    const input = document.getElementById(id)

    setTimeout(
      () => input.setSelectionRange(input.value.length, input.value.length),
      10
    )
  }
  const handleMouseDownPassword = (e) => e.preventDefault()

  const icon = () => {
    if (showPassword) {
      return <EyesHide />
    }

    return <Eyes />
  }

  return (
    <FormText
      className={classes.password}
      error={error}
      id={id}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {icon()}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={placeholder || label}
      name={id}
      onChange={onChange}
      placeholder={placeholder || DATA.label}
      type={showPassword ? 'text' : 'password'}
      value={value}
      {...otherProps}
    />
  )
}

export default FormPassword

FormPassword.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

FormPassword.defaultProps = {
  error: false,
  label: DATA.label,
  onChange: null,
  placeholder: null,
  value: '',
}
