import clevertap from 'clevertap-web-sdk'

const cleverTap = {
  event: (event, properties) => {
    if (process.env.NODE_ENV !== 'test') {
      try {
        if (properties) {
          // eslint-disable-next-line no-console
          console.log(
            `CLEVERTAP PROPERTIES::${JSON.stringify({ ...properties })}`
          )
          clevertap.event.push(event, { ...properties })
        } else {
          clevertap.event.push(event)
        }
        // eslint-disable-next-line no-console
        console.log(`CLEVERTAP_EVENT SENT::${event}`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`CLEVERTAP_EVENT_${event}_ERROR_${error}`)
      }
    }
  },

  initialize: () => {
    try {
      clevertap.privacy.push({ optOut: false }) // Set the flag to true, if the user of the device opts out of sharing their data
      clevertap.privacy.push({ useIP: false }) // Set the flag to true, if the user agrees to share their IP data
      clevertap.spa = true
      clevertap.init(process.env.REACT_APP_CLEVERTAP_APP_ID)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  },
}

export default cleverTap
