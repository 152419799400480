import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const WrapLogo = ({ fill, size, sx }) => (
  <SvgIcon sx={{ fontSize: `${size}rem`, ...sx }} viewBox="0 0 32 32">
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={fill} height="32" rx="12" width="32" />
      <path
        clipRule="evenodd"
        d="M26.4993 9.03401C26.4901 7.08074 24.904 5.5 22.9489 5.5C22.4631 5.5 21.9869 5.5923 21.5786 5.77433L8.64074 11.2685C6.79689 12.0484 5.5 13.8756 5.5 16.0002V22.9493C5.5 24.91 7.08951 26.5 9.05095 26.5C9.54835 26.5 10.0215 26.3971 10.4516 26.2122L23.3345 20.7423C25.1912 19.9677 26.4997 18.1344 26.4997 16.0002L26.4993 9.03401Z"
        fill="#FC4C02"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.5972 18.7899C14.4466 18.7899 14.3242 18.6544 14.3242 18.4874V12.0968C14.3242 11.9296 14.4466 11.7939 14.5972 11.7939C14.7481 11.7939 14.8705 11.9296 14.8705 12.0968V18.4874C14.8705 18.6544 14.7481 18.7899 14.5972 18.7899Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.9701 18.5044V13.5077C16.9701 13.3385 16.8476 13.2014 16.6963 13.2014C16.5458 13.2014 16.4238 13.3385 16.4238 13.5077V18.5044C16.4238 18.6736 16.5458 18.8108 16.6963 18.8108C16.8476 18.8108 16.9701 18.6736 16.9701 18.5044Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.9701 12.075V12.0593C16.9701 11.9128 16.8476 11.7939 16.6963 11.7939C16.5458 11.7939 16.4238 11.9128 16.4238 12.0593V12.075C16.4238 12.2215 16.5458 12.3402 16.6963 12.3402C16.8476 12.3402 16.9701 12.2215 16.9701 12.075Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.6996 18.1161C12.8204 17.9965 12.8204 17.8024 12.6996 17.683C12.579 17.5632 12.3834 17.5632 12.2634 17.683C11.3725 18.5657 9.92399 18.5657 9.03343 17.683C8.14284 16.799 8.14284 15.3615 9.03343 14.478C9.46463 14.0502 10.0384 13.8144 10.648 13.8144C11.2585 13.8144 11.8315 14.0502 12.2627 14.478C12.3834 14.5979 12.579 14.5979 12.6996 14.478C12.8204 14.3581 12.8204 14.1644 12.6996 14.0446C12.1518 13.5008 11.4233 13.2014 10.648 13.2014C9.87309 13.2014 9.14469 13.5008 8.59651 14.0446C7.46523 15.1669 7.46523 16.9932 8.59651 18.1161C9.16224 18.6774 9.90481 18.9579 10.6483 18.9579C11.391 18.9579 12.1339 18.6774 12.6996 18.1161Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.1383 20.0387V16.0737C19.1383 14.8271 20.1543 13.8128 21.4038 13.8128C22.6529 13.8128 23.6693 14.8271 23.6693 16.0737C23.6693 17.3205 22.6529 18.3344 21.4038 18.3344C20.6835 18.3344 20.196 18.0598 20.1922 18.0582C20.0464 17.9728 19.8584 18.0212 19.7729 18.167C19.6877 18.3129 19.7366 18.5 19.8823 18.5858C19.9074 18.6005 20.5087 18.946 21.4038 18.946C22.9909 18.946 24.2818 17.6575 24.2818 16.0737C24.2818 14.4899 22.9909 13.2014 21.4038 13.2014C19.8164 13.2014 18.5254 14.4899 18.5254 16.0737V20.0387C18.5254 20.2072 18.6628 20.3444 18.8318 20.3444C19.0009 20.3444 19.1383 20.2072 19.1383 20.0387Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M26.4464 22.0672C26.4464 22.5547 26.0515 22.9496 25.564 22.9496C25.0765 22.9496 24.6816 22.5547 24.6816 22.0672C24.6816 21.5797 25.0765 21.1848 25.564 21.1848C26.0515 21.1848 26.4464 21.5797 26.4464 22.0672ZM26.3074 22.0672C26.3074 21.6528 25.9735 21.314 25.564 21.314C25.1545 21.314 24.823 21.6528 24.823 22.0672C24.823 22.4816 25.1545 22.8204 25.564 22.8204C25.9735 22.8204 26.3074 22.4816 26.3074 22.0672ZM25.9345 22.5498H25.7785L25.5567 22.1549H25.4105V22.5498H25.2764V21.5821H25.6055C25.7736 21.5821 25.9101 21.7089 25.9101 21.8722C25.9101 22.0136 25.8224 22.1013 25.703 22.1379L25.9345 22.5498ZM25.7736 21.8722C25.7736 21.7747 25.6981 21.7016 25.5908 21.7016H25.4105V22.0404H25.5908C25.6981 22.0404 25.7736 21.9697 25.7736 21.8722Z"
        fill="#FC4C02"
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
)

export default WrapLogo

WrapLogo.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.shape(),
}

WrapLogo.defaultProps = {
  fill: '#FCF1ED',
  size: 4,
  sx: null,
}
