import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const CreditCard = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 32 32">
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.31738V18.3174C22 18.8674 21.8042 19.3382 21.4125 19.7299C21.0208 20.1215 20.55 20.3174 20 20.3174H4C3.45 20.3174 2.97917 20.1215 2.5875 19.7299C2.19583 19.3382 2 18.8674 2 18.3174V6.31738C2 5.76738 2.19583 5.29655 2.5875 4.90488C2.97917 4.51322 3.45 4.31738 4 4.31738H20C20.55 4.31738 21.0208 4.51322 21.4125 4.90488C21.8042 5.29655 22 5.76738 22 6.31738ZM4 8.31738H20V6.31738H4V8.31738ZM4 12.3174V18.3174H20V12.3174H4Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
)

export default CreditCard

CreditCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

CreditCard.defaultProps = {
  color: '#FC4C02',
  size: 1.5,
}
