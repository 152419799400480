import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  Stack,
  List,
  ListItem,
  Button,
  Typography,
  Divider,
  InputAdornment,
  useTheme,
} from '@mui/material'
import TextField from 'components/TextField'
import { BusinessAddressAutocomplete } from 'components/Renders'
import { getGoogleAddressValue } from 'utils'
import { Plus, Search } from 'components/Icons'
import CircularProgress from '@mui/material/CircularProgress'
// eslint-disable-next-line import/no-extraneous-dependencies
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import DATA from './constData.json'

const AddressAutocomplete = (props) => {
  const { onSelect, onClick, placeholder, query, setQuery } = props
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [queryIndex, setQueryIndex] = useState()
  const [open, setOpen] = useState(false)

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
    debounce: DATA.searchDebounceDelay,
    language: DATA.lang,
    options: {
      componentRestrictions: { country: DATA.bounds.country },
      types: DATA.bounds.types,
    },
  })

  const handleSelect = (data) => {
    const zipcode = getGoogleAddressValue(
      data.address_components,
      'postal_code'
    )
    const number = getGoogleAddressValue(
      data.address_components,
      'street_number'
    )
    const street = getGoogleAddressValue(data.address_components, 'route')
    const municipality = getGoogleAddressValue(
      data.address_components,
      'locality'
    )
    const state = getGoogleAddressValue(
      data.address_components,
      'administrative_area_level_1'
    )
    const city = getGoogleAddressValue(data.address_components, 'sublocality')

    if (onSelect) {
      onSelect({
        city,
        municipality,
        number_in: number,
        number_out: null,
        state,
        street,
        zipcode,
      })
    }
  }

  const handleChange = (e) => {
    const { value } = e.target
    const regex = /^[A-Za-z0-9]{1,}\s.*\d+$/

    if (regex.test(value)) {
      getPlacePredictions({ input: value })
    }

    setQuery(value)
  }

  useEffect(() => {
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  useEffect(() => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    if (placePredictions.length && placePredictions[queryIndex]) {
      placesService?.getDetails(
        {
          placeId: placePredictions[queryIndex].place_id,
        },
        (placeDetails) => handleSelect(placeDetails)
      )
    }
  }, [queryIndex])

  return (
    <Stack sx={{ position: 'relative' }}>
      <BusinessAddressAutocomplete>
        <TextField
          id="search-query"
          InputLabelProps={{ shrink: !!query }}
          InputProps={{
            'data-testid': 'search-input',
            endAdornment: (
              <InputAdornment
                onClick={() => setQuery('')}
                position="start"
                sx={{ cursor: 'pointer' }}
              >
                {isPlacePredictionsLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <Plus color={theme.palette.text.primary} rotate={45} />
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search color={theme.palette.text.primary} />
              </InputAdornment>
            ),
          }}
          label={placeholder}
          onBlur={() => {
            setTimeout(() => setOpen(false), 100)
          }}
          onChange={(e) => handleChange(e)}
          onClick={onClick}
          onFocus={() => {
            setOpen(true)
          }}
          // placeholder={placeholder}
          value={query}
          variant="outlined"
        />
      </BusinessAddressAutocomplete>

      {!isPlacePredictionsLoading && query && open && (
        <List
          sx={{
            background: theme.palette.white.main,
            border: `1px solid ${theme.backgrounds[2]}`,
            borderRadius: `${theme.borderRadius[0]}px`,
            boxShadow: `0 6px 12px 0 ${theme.palette.shadows.btnSwitch}`,
            position: 'absolute',
            top: 52,
          }}
        >
          {placePredictions.map((item, index) => (
            <Stack key={item.description}>
              <ListItem
                onClick={() => setQueryIndex(index)}
                sx={{ pl: 0, pr: 0 }}
              >
                <Button
                  fullWidth
                  sx={{ justifyContent: 'flex-start', textAlign: 'left' }}
                >
                  <Typography variant="body0">{item.description}</Typography>
                </Button>
              </ListItem>
              <Divider />
            </Stack>
          ))}
        </List>
      )}
      {!isPlacePredictionsLoading && placePredictions.length === 0 && query && (
        <Typography sx={{ mb: 2, mt: 2 }} variant="body0">
          {DATA.noResults}
        </Typography>
      )}
    </Stack>
  )
}

export default AddressAutocomplete

AddressAutocomplete.propTypes = {
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
}

AddressAutocomplete.defaultProps = {
  onClick: null,
  onSelect: null,
  placeholder: 'Buscar',
}
