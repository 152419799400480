const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_API_URL_2: process.env.REACT_APP_API_URL_2,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_GOOGLE_MAPS_BASE_URL: process.env.REACT_APP_GOOGLE_MAPS_BASE_URL,
  REACT_APP_ME_DASHBOARD_URL: process.env.REACT_APP_ME_DASHBOARD_URL,
  REACT_APP_STORE_BASE_URL: process.env.REACT_APP_STORE_BASE_URL,
}

// routes definition
const OTP_DOMAIN =
  ENV.REACT_APP_ENV === 'prod'
    ? 'https://api.payclip.io'
    : 'https://stage-api.payclip.io'
const ROUTE_HOME = '/'
const ROUTE_ACCOUNT_CREATION = '/cuenta'
const ROUTE_ACCOUNT_ACTIVATION = '/activacion'
const ROUTE_PASSWORD_SETUP = '/password'
const ROUTE_PASSWORD_CONFIRM = '/password-confirm'
const ROUTE_PROFILE_SETUP = '/resumen'
const ROUTE_VALIDATION = '/validacion'
const ROUTE_VERIFY = '/verificacion'
const ROUTE_PHONE = '/telefono'
const ROUTE_VALIDATION_PHONE = '/validacionTelefono'
const ROUTE_BUSINESS_INFO = '/informacion'
const ROUTE_BUSINESS_ADDRESS = '/direccion'
const ROUTE_BUSINESS_ADDRESS_CHOOSE = '/elegirdireccion'
const ROUTE_BUSINESS_ACTIVITY = '/actividad'
const ROUTE_LOGIN = '/login'
const ROUTE_FIRST_SALE = '/primera-venta'
const ROUTE_TAC = '/tyc'
const ROUTE_KYC_ERROR = '/kyc-error'
const ROUTE_SYNC = '/sync'
const ROUTE_OPTIONAL_KYC = '/kyc-opcional'
const ROUTE_PERSONAL_INFO = '/informacion-personal'
const ROUTE_FINANCIAL_INFO = '/informacion-financiera'
const ROUTE_INSTALLMENTS = '/cuotas'
const ROUTE_CLABE = '/clabe'
const ROUTE_FIRST_TRANSACTION = '/primera-transaccion'
const ROUTE_FIRST_TRANSACTION_READER = '/primera-transaccion-lector'
const ROUTE_FIRST_TRANSACTION_TUTORIAL = '/primera-transaccion-tutorial'
const ROUTE_FIRST_TRANSACTION_SUCCESS = '/primera-transaccion-success'
const ROUTE_ASSISTED = '/asistido'
const ROUTE_ASSISTED_EDIT_UI = '/asistido-editar-pi'
const ROUTE_ASSISTED_EDIT_BI = '/asistido-editar-bi'
const ROUTE_WALLET_PROPOSITION = '/propuesta-valor-billetera'

// API Routes
const GET_ZIP_CODES = '/api/md/zipcodes'
const GET_PUBLIC_PROFILE = '/public-profile/get-profile'
const GET_MERCHANT_INFO = '/api/merchant/info'
const SET_INSTALLMENTS = '/api/merchant/installment/edit'

// routes endpoints
const ROUTE_POST_LOGIN = '/api/login'
const ROUTE_API_CHECKMAIL = '/onboarding/check-email' // => [POST]
const ROUTE_API_SIGNUP = '/onboarding/signup' // => [POST]
const ROUTE_API_SIGNUP_VALIDATE = '/onboarding/signup/validate' // => [POST]
const ROUTE_API_USER_PHONE = '/onboarding/user/phone' // => [PATCH]
const ROUTE_API_STATUS = '/onboarding/status' // => [GET]
const ROUTE_API_BUSINESS_ADDRESS = '/onboarding/business/address' // => [PATCH]
const ROUTE_API_BUSINESS_ACTIVITY = '/onboarding/business' // => [PATCH]
const ROUTE_API_ACTIVATE = '/onboarding/activate' // => [POST]
const ROUTE_API_USER_IDENTITY = '/onboarding/user/save-identity' // => [POST]
const ROUTE_API_USER_FINANCIAL = '/onboarding/user/financial-information' // => [POST]
const ROUTE_API_USER = '/onboarding/user' // => [GET]
const ROUTES_API_BANKS = '/api/md/bankcodes'
const ROUTES_API_CREATE_CLABE = '/bank-account'
const ROUTES_API_ASSISTED_GET = '/onboarding/assisted/data' // => [GET]
const ROUTES_API_ASSISTED_POST = '/onboarding/assisted' // => [POST]
const ROUTES_API_ASSISTED_STATUS = '/onboarding/check-assisted'
const ROUTE_API_OTP_SEND = `${OTP_DOMAIN}/otp/send`
const ROUTE_API_OTP_RESEND = `${OTP_DOMAIN}/otp/resend/`
const ROUTE_API_OTP_VALIDATE = `${OTP_DOMAIN}/otp/verify/`
const ROUTE_API_OTP_BACK_REVALIDATE = `${ENV.REACT_APP_API_URL}/onboarding/otp/`

export {
  ENV,
  ROUTE_HOME,
  ROUTE_ACCOUNT_CREATION,
  ROUTE_ACCOUNT_ACTIVATION,
  ROUTE_PASSWORD_SETUP,
  ROUTE_PASSWORD_CONFIRM,
  ROUTE_PROFILE_SETUP,
  ROUTE_VALIDATION,
  ROUTE_VERIFY,
  ROUTE_PHONE,
  ROUTE_BUSINESS_INFO,
  ROUTE_BUSINESS_ADDRESS,
  ROUTE_BUSINESS_ADDRESS_CHOOSE,
  ROUTE_BUSINESS_ACTIVITY,
  ROUTE_LOGIN,
  ROUTE_FIRST_SALE,
  ROUTE_TAC,
  ROUTE_KYC_ERROR,
  ROUTE_SYNC,
  ROUTE_OPTIONAL_KYC,
  ROUTE_PERSONAL_INFO,
  ROUTE_FINANCIAL_INFO,
  ROUTE_INSTALLMENTS,
  ROUTE_CLABE,
  ROUTE_FIRST_TRANSACTION,
  ROUTE_FIRST_TRANSACTION_READER,
  ROUTE_FIRST_TRANSACTION_TUTORIAL,
  ROUTE_FIRST_TRANSACTION_SUCCESS,
  ROUTE_ASSISTED,
  ROUTE_ASSISTED_EDIT_UI,
  ROUTE_ASSISTED_EDIT_BI,
  ROUTE_VALIDATION_PHONE,
  ROUTE_WALLET_PROPOSITION,

  // API Routes
  GET_ZIP_CODES,
  GET_PUBLIC_PROFILE,
  GET_MERCHANT_INFO,
  SET_INSTALLMENTS,

  // endpoints
  ROUTE_POST_LOGIN,
  ROUTE_API_CHECKMAIL,
  ROUTE_API_SIGNUP,
  ROUTE_API_SIGNUP_VALIDATE,
  ROUTE_API_USER_PHONE,
  ROUTE_API_STATUS,
  ROUTE_API_BUSINESS_ADDRESS,
  ROUTE_API_BUSINESS_ACTIVITY,
  ROUTE_API_ACTIVATE,
  ROUTE_API_USER_IDENTITY,
  ROUTE_API_USER_FINANCIAL,
  ROUTE_API_USER,
  ROUTES_API_BANKS,
  ROUTES_API_CREATE_CLABE,
  ROUTES_API_ASSISTED_GET,
  ROUTES_API_ASSISTED_POST,
  ROUTES_API_ASSISTED_STATUS,
  ROUTE_API_OTP_SEND,
  ROUTE_API_OTP_RESEND,
  ROUTE_API_OTP_VALIDATE,
  ROUTE_API_OTP_BACK_REVALIDATE,
}
