import Cookies from 'js-cookie'
import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from 'hooks'
import SITE from 'constants/site.json'

const GlobalContext = createContext(false)

const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (context === undefined) {
    throw new Error(
      'useGlobalContext:: useStateContext must be used within a provider'
    )
  }

  return context
}

const isAndroid = () => typeof Android !== 'undefined' && Android !== null

const GlobalProvider = ({ children }) => {
  const { height, width } = useWindowSize()

  const value = useMemo(
    () => ({
      device: {
        type: isAndroid() ? 'Android' : 'iOS',
      },
      isWebview: Cookies.get(SITE.WEBVIEW_FLAG_COOKIE) !== undefined,
      viewPort: {
        orientation: height > width ? SITE.PORTRAIT : SITE.LANDSCAPE,
        size: {
          height,
          width,
        },
      },
    }),
    [height, width]
  )

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { GlobalProvider, useGlobalContext }
