import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.black.dark,
    opacity: '0.8 !important',
  },
  '& .MuiDialog-paper': {
    borderRadius: theme.space[3],
    boxShadow: 'none',
    height: 200,
    justifyContent: 'center',
    padding: theme.space[4],
    width: 200,
  },
}))

export default CustomDialog
