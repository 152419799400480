import { createStyles, makeStyles } from '@mui/styles'
import 'fonts/styles.css'

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '#root': {
        display: 'flex',
        height: '100%',
        width: '100%',
      },
      '#root .slide-routes': {
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '100%',
      },
      '#root .slide-routes > .item': {
        background: '#F3F4F6',
        display: 'flex',
      },
      '& .MuiDialogContent-root .MuiFormControl-root .MuiList-root .MuiListItem-root':
        {
          width: '100%',
        },
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      body: {
        fontFamily: 'Averta Std',
        height: '100%',
        width: '100%',
      },
      html: {
        '-moz-osx-font-smoothing': 'grayscale',
        '-webkit-font-smoothing': 'antialiased',
        height: '100%',
        width: '100%',
      },
    },
  })
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export default GlobalStyles
