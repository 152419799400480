/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from 'components/Layout'
import { ROUTE_PROFILE_SETUP } from 'constants/routes'
import { getToken } from 'helpers/tokenHelpers'
import { getVersion, setVersion } from 'helpers/versionHelpers'
import { setQueryParamsStringInSessionStorage } from 'helpers/params'
import segment from 'helpers/segment'
import DATA from 'constants/pages/home.json'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { setAppsFlyerUrl } from 'helpers/appsflyer'
import HomePageApp from 'components/Renders/Home/App'
import HomePageWeb from 'components/Renders/Home/Web'
import { isDevice, isWebView } from 'utils'

const HomePage = () => {
  const { device } = useGlobalContext()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const OBAssistedEmail =
    params.get('assisted_email') || sessionStorage.getItem('OBAssistedEmail')

  useEffect(() => {
    const appsFlyerUrl = params.get('af_url')
    const isNotWallet = params.get('is_not_wallet') === 'true'

    cleverTap.event(DATA.CLEVERTAP.EVENTS.WELCOME, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
    })
    segment.page(DATA.SEGMENT.PAGE)
    segment.track(DATA.SEGMENT.EVENTS.VIEW_SPLASH_SCREEN)

    if (getToken()) {
      navigate(ROUTE_PROFILE_SETUP)
    }

    let version = getVersion()

    if (!version || version === 'undefined') {
      version = isNotWallet ? '1.1' : '1.2'
      setVersion(version)
    }

    if (appsFlyerUrl) {
      setAppsFlyerUrl(appsFlyerUrl)
    }

    const newParams = new URLSearchParams(params)

    newParams.delete('af_url')

    if (newParams.toString())
      setQueryParamsStringInSessionStorage(newParams.toString())
  }, [])

  useEffect(() => {
    if (OBAssistedEmail) {
      sessionStorage.setItem('OBAssistedEmail', OBAssistedEmail)
    }
  }, [OBAssistedEmail])

  return (
    <Layout header={isDevice && !isWebView() ? DATA.web.header : null}>
      {isWebView() ? <HomePageApp /> : <HomePageWeb />}
    </Layout>
  )
}

export default HomePage
