import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import { useTheme } from '@mui/material'
import { Thick } from 'components/Icons'

const StepIcon = ({ children, badge }) => {
  const theme = useTheme()
  const settings = {
    '& .MuiBadge-root': {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.success.checkmark,
        border: `2px solid ${theme.palette.white.main}`,
        height: 20,
        minWidth: 20,
        right: -5,
        top: -5,
        width: 16,
      },
      '& svg': {
        opacity: 1,
      },
    },
    '.MuiBadge-badge .MuiSvgIcon-root': {
      fontSize: '0.5rem',
    },
    '.MuiSvgIcon-root': {
      '&.trophy': {
        fontSize: '1.5rem',
      },
      fontSize: '1.25rem',
      opacity: 0.7,
    },
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    border: `2px solid ${theme.backgrounds[2]}`,
    borderRadius: `${theme.borderRadius[2]}px`,
    display: 'flex',
    fontSize: '3rem',
    height: 48,
    justifyContent: 'center',
    width: 48,
  }

  if (badge) {
    return (
      <Box sx={settings}>
        <Badge badgeContent={<Thick size={0.15} />} color="primary">
          {children}
        </Badge>
      </Box>
    )
  }

  return <Box sx={settings}>{children}</Box>
}

StepIcon.propTypes = {
  badge: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
StepIcon.defaultProps = {
  badge: false,
}
export default StepIcon
