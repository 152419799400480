import SvgIcon from '@mui/material/SvgIcon'

const Wallet = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#FEEBE4" height="24" rx="8" width="24" y="0.998047" />
      <path
        d="M14.5943 10.8568L16.1666 10.1891L16.1667 10.9974C16.1667 10.9974 16.1667 10.9974 16.1667 10.9975C16.1667 10.9975 16.1667 10.9975 16.1667 10.9975C16.1667 11.059 16.129 11.1145 16.0722 11.1382L16.0722 11.1382L16.0693 11.1394L14.5 11.8057V10.9974C14.5 10.9361 14.5374 10.8809 14.5936 10.8571L14.5943 10.8568Z"
        stroke="#FC4C02"
      />
      <path
        clipRule="evenodd"
        d="M16 17.6644H8C6.89533 17.6644 6 16.7691 6 15.6644V10.3311C6 9.22639 6.89533 8.33105 8 8.33105H16C17.1047 8.33105 18 9.22639 18 10.3311V15.6644C18 16.7691 17.1047 17.6644 16 17.6644Z"
        fillRule="evenodd"
        stroke="#FC4C02"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)

export default Wallet
