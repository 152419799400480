/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'hooks'
import { useEffect } from 'react'

const AppWrapper = ({ children }) => {
  const { pathname } = useLocation()
  const size = useWindowSize()

  useEffect(() => {
    if (size.width) {
      const htmlTag = document.querySelector('html')

      htmlTag.style.width = `${size.width}px`
      htmlTag.style.height = `${size.height}px`
    }
  }, [size])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Helmet>
        <meta
          content="user-scalable=no, width=device-width, height=device-height"
          name="viewport"
        />
      </Helmet>
      {children}
    </>
  )
}

export default AppWrapper

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
