import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const DollarSign = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 32 32">
    <svg
      fill="none"
      height="19"
      viewBox="0 0 10 19"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.02472 18.3174C4.74139 18.3174 4.50389 18.2215 4.31223 18.0299C4.12056 17.8382 4.02472 17.6007 4.02472 17.3174V16.1674C3.27472 16.0007 2.61639 15.709 2.04973 15.2924C1.48306 14.8757 1.02473 14.2924 0.674725 13.5424C0.558058 13.309 0.553892 13.0632 0.662225 12.8049C0.770558 12.5465 0.966392 12.359 1.24973 12.2424C1.48306 12.1424 1.72473 12.1465 1.97473 12.2549C2.22473 12.3632 2.41639 12.5424 2.54973 12.7924C2.83306 13.2924 3.19139 13.6715 3.62473 13.9299C4.05806 14.1882 4.59139 14.3174 5.22473 14.3174C5.90806 14.3174 6.48723 14.1632 6.96222 13.8549C7.43722 13.5465 7.67473 13.0674 7.67473 12.4174C7.67473 11.834 7.49139 11.3715 7.12473 11.0299C6.75806 10.6882 5.90806 10.3007 4.57472 9.86738C3.14139 9.41738 2.15806 8.87988 1.62473 8.25488C1.09139 7.62988 0.824725 6.86738 0.824725 5.96738C0.824725 4.88405 1.17473 4.04238 1.87473 3.44238C2.57473 2.84238 3.29139 2.50072 4.02472 2.41738V1.31738C4.02472 1.03405 4.12056 0.796549 4.31223 0.604883C4.50389 0.413216 4.74139 0.317383 5.02472 0.317383C5.30806 0.317383 5.54556 0.413216 5.73723 0.604883C5.92889 0.796549 6.02472 1.03405 6.02472 1.31738V2.41738C6.65806 2.51738 7.20806 2.72155 7.67473 3.02988C8.14139 3.33822 8.52473 3.71738 8.82473 4.16738C8.97473 4.38405 9.00389 4.62572 8.91223 4.89238C8.82056 5.15905 8.63306 5.35072 8.34973 5.46738C8.11639 5.56738 7.87473 5.57155 7.62473 5.47988C7.37473 5.38822 7.14139 5.22572 6.92473 4.99238C6.70806 4.75905 6.45389 4.57988 6.16223 4.45488C5.87056 4.32988 5.50806 4.26738 5.07473 4.26738C4.34139 4.26738 3.78306 4.42988 3.39973 4.75488C3.01639 5.07988 2.82473 5.48405 2.82473 5.96738C2.82473 6.51738 3.07473 6.95072 3.57473 7.26738C4.07473 7.58405 4.94139 7.91738 6.17473 8.26738C7.32473 8.60072 8.19556 9.12988 8.78723 9.85488C9.37889 10.5799 9.67473 11.4174 9.67473 12.3674C9.67473 13.5507 9.32473 14.4507 8.62473 15.0674C7.92473 15.684 7.05806 16.0674 6.02472 16.2174V17.3174C6.02472 17.6007 5.92889 17.8382 5.73723 18.0299C5.54556 18.2215 5.30806 18.3174 5.02472 18.3174Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
)

export default DollarSign

DollarSign.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

DollarSign.defaultProps = {
  color: '#FC4C02',
  size: 1.5,
}
