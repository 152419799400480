import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const ShoppingCart = ({ color }) => (
  <SvgIcon>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67109 4.37483L5.39788 12.5262C5.63524 13.6489 6.68032 14.4127 7.82326 14.2991L15.4909 13.5319C16.4358 13.4374 17.2188 12.7602 17.4494 11.8388L18.9658 5.77313C19.1436 5.06217 18.607 4.37483 17.8746 4.37483H3.67109ZM3.67109 4.37483L2.8004 1H1.00049M7.18767 16.7492C6.56638 16.7492 6.06273 17.2529 6.06273 17.8741C6.06273 18.4954 6.56638 18.9991 7.18767 18.9991C7.80896 18.9991 8.31262 18.4954 8.31262 17.8741C8.31262 17.2529 7.80896 16.7492 7.18767 16.7492ZM16.1872 16.7492C15.5659 16.7492 15.0623 17.2529 15.0623 17.8741C15.0623 18.4954 15.5659 18.9991 16.1872 18.9991C16.8085 18.9991 17.3122 18.4954 17.3122 17.8741C17.3122 17.2529 16.8085 16.7492 16.1872 16.7492Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default ShoppingCart

ShoppingCart.propTypes = {
  color: PropTypes.string,
}

ShoppingCart.defaultProps = {
  color: theme.palette.text.primary,
}
