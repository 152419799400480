import { useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Image from 'components/Image'
import Button from 'components/Button'
import { Arrow } from 'components/Icons'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useTheme } from '@mui/material'
import generateKey from 'utils/generateKey'
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import DATA from './tutorial.json'

const Tutorial = (props) => {
  const { slides, handleGoBack, handleContinue } = props
  const theme = useTheme()
  const [swiper, setSwiper] = useState(null)
  const [btnText, setBtnText] = useState(DATA.btnPrimaryTxt)

  const swipeBack = () => {
    if (swiper.slides.length - 2 !== swiper.activeIndex) {
      setBtnText(DATA.btnPrimaryTxt)
    }
    if (handleGoBack && !swiper.activeIndex) {
      handleGoBack()
    }
    swiper.slidePrev(1000)
  }

  const swipeNext = () => {
    if (swiper.slides.length - 2 === swiper.activeIndex) {
      setBtnText(DATA.btnSecondaryTxt)
    }
    if (handleContinue && swiper.slides.length - 1 === swiper.activeIndex) {
      handleContinue()
    }
    swiper.slideNext(1000)
  }

  const handleChangeSlides = ({ realIndex }) => {
    if (swiper.slides.length - 1 === realIndex) {
      setBtnText(DATA.btnSecondaryTxt)
    }
    if (swiper.slides.length - 1 !== realIndex) {
      setBtnText(DATA.btnPrimaryTxt)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 2,
        width: '100%',
      }}
    >
      <Stack sx={{ mb: 1, p: 0, width: '100%' }}>
        <Button onClick={swipeBack} sx={{ justifyContent: 'flex-start' }}>
          <Arrow size={1} />
        </Button>
      </Stack>
      <Box
        sx={{
          '& .swiper': { minHeight: '470px' },
          '& .swiper-pagination-bullet-active': {
            background: theme.palette.primary.main,
            borderRadius: 5,
            width: 16,
          },
        }}
      >
        <Swiper
          modules={[Pagination]}
          onSlideChange={handleChangeSlides}
          onSwiper={setSwiper}
          pagination
        >
          {slides.map((slide, idx) => (
            <SwiperSlide key={generateKey(idx)}>
              <Stack
                spacing={2}
                sx={{
                  '&.MuiStack-root > .MuiBox-root': {
                    margin: 'auto',
                  },
                  textAlign: 'center',
                }}
              >
                {slide.img && (
                  <Box
                    sx={{
                      '& img': { width: 328 },
                      alignItems: 'center',
                      display: 'flex',
                      height: 300,
                    }}
                  >
                    <Image alt={slide.title} src={slide.img} />
                  </Box>
                )}
                {slide.title && (
                  <Typography
                    fontWeight={theme.fontWeight.bold}
                    variant="body5"
                  >
                    {slide.title}
                  </Typography>
                )}
                {slide.description && (
                  <Typography
                    dangerouslySetInnerHTML={{ __html: slide.description }}
                    fontWeight={theme.fontWeight.regular}
                    variant="body2"
                  />
                )}
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box className="swiper-pagination" />
      </Box>
      <Button
        color="orange"
        onClick={swipeNext}
        sx={{
          fontWeight: theme.fontWeight.bold,
          height: 48,
        }}
      >
        {btnText}
      </Button>
    </Box>
  )
}

export default Tutorial

Tutorial.propTypes = {
  handleContinue: PropTypes.func,
  handleGoBack: PropTypes.func,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      img: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
}

Tutorial.defaultProps = {
  handleContinue: () => {},
  handleGoBack: () => {},
}
