import { useEffect, useState } from 'react'

// based from https://usehooks.com/useWindowSize/
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    width: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })

      console.log(
        `::handleResize:: -> w/h(${window.innerWidth}px, ${window.innerHeight}px)`
      )
    }
    window.addEventListener('resize', handleResize)
    setTimeout(handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

// based from https://usehooks.com/useDebounce/
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export { useWindowSize, useDebounce }
