import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Car = ({ color }) => (
  <SvgIcon>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 20 16"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6H17M3 6C1.89543 6 1 6.89543 1 8V12C1 12.5523 1.44772 13 2 13H3M3 6V3C3 1.89543 3.89543 1 5 1H12.8676C13.5701 1 14.2212 1.3686 14.5826 1.97101L17 6M17 6C18.1046 6 19 6.89543 19 8V12C19 12.5523 18.5523 13 18 13H17M3 13C3 14.1046 3.89543 15 5 15C6.10457 15 7 14.1046 7 13M3 13C3 11.8954 3.89543 11 5 11C6.10457 11 7 11.8954 7 13M17 13C17 14.1046 16.1046 15 15 15C13.8954 15 13 14.1046 13 13M17 13C17 11.8954 16.1046 11 15 11C13.8954 11 13 11.8954 13 13M7 13H13M10 1V6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Car

Car.propTypes = {
  color: PropTypes.string,
}

Car.defaultProps = {
  color: theme.palette.text.primary,
}
