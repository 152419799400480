import * as yup from 'yup'

import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useTheme } from '@mui/material/styles'

import Autocomplete from 'components/Autocomplete'
import Layout from 'components/Layout'
import LoadingDialog from 'components/LoadingDialog'
import Paper from 'components/Paper'
import StepWrapper from 'components/StepWrapper'
import SubmitError from 'components/SubmitError'
import { TrailIconDown, ErrorHelper } from 'components/Icons'
import { StackFinancialInformation } from 'components/Renders'

import {
  ENV,
  ROUTE_API_USER_FINANCIAL,
  ROUTE_HOME,
  ROUTE_PERSONAL_INFO,
} from 'constants/routes'
import DATA from 'constants/pages/financialInformation.json'
import DIALOGS from 'constants/dialogs.json'

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  FormHelperText,
} from '@mui/material'

import { postInfo } from 'helpers/fetchUtils'
import segment from 'helpers/segment'
import { makeStyles } from '@mui/styles'
import { getToken } from 'helpers/tokenHelpers'

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.surface.c200,
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.surface.c500,
      borderRadius: '39px',
      height: '149px',
    },
  },
}))

const FinancialInformation = () => {
  const theme = useTheme()
  const [mandatoryOccupation, setMandatoryOccupation] = useState(false)
  const [mandatorySourceOfIncome, setMandatorySourceOfIncome] = useState(false)
  const [mandatoryMonthlyBilling, setMandatoryMonthlyBilling] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [success, setSuccess] = useState(false)
  const [sourceOfIncomeFocused, setSourceOfIncomeFocused] = useState(false)
  const [occupationFocused, setOccupationFocused] = useState(false)
  const classes = useStyles()

  const error = mandatoryOccupation || mandatorySourceOfIncome
  const economicInfoErrorText =
    mandatoryOccupation && mandatorySourceOfIncome
      ? DATA.economic_info_error
      : DATA.single_economic_info_input_error

  const showDivider =
    mandatoryMonthlyBilling ||
    mandatoryOccupation ||
    mandatorySourceOfIncome ||
    occupationFocused ||
    sourceOfIncomeFocused

  const occupationFormControlStyles = {
    border:
      occupationFocused || mandatoryOccupation ? '1px solid' : '1px solid #fff',
    borderColor: mandatoryOccupation ? theme.palette.error.main : 'black',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    paddingBottom: '12px',
    paddingRight: '8px',
  }

  const sourceOfIncomeFormControlStyles = {
    border:
      sourceOfIncomeFocused || mandatorySourceOfIncome
        ? '1px solid'
        : '1px solid #fff',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderColor: mandatorySourceOfIncome ? theme.palette.error.main : 'black',
    paddingRight: '8px',
  }

  const subtitleStyles = {
    color: theme.palette.text.light,
    fontWeight: theme.fontWeight.semibold,
    letterSpacing: '0.96px',
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const FINANCIAL_INFORMATION_VALIDATION = yup.object().shape({
    [DATA.FORMIK_ID.occupation]: yup.string().required(DATA.required),
    [DATA.FORMIK_ID.source_of_income]: yup.string().required(DATA.required),
    [DATA.FORMIK_ID.monthly_billing]: yup.string().required(DATA.required),
  })

  const formik = useFormik({
    initialValues: {
      [DATA.FORMIK_ID.occupation]: '',
      [DATA.FORMIK_ID.source_of_income]: '',
      [DATA.FORMIK_ID.monthly_billing]: '',
    },
    onSubmit: (values) => {
      setLoading(true)

      const payload = {
        income_source: values[DATA.FORMIK_ID.source_of_income],
        occupation: values[DATA.FORMIK_ID.occupation],
        transactional_amount: values[DATA.FORMIK_ID.monthly_billing],
      }

      postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_USER_FINANCIAL}`, payload)
        .then(() => {
          setSuccess(true)
        })
        .catch(() => {
          setOpenSnackbar(true)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    validationSchema: FINANCIAL_INFORMATION_VALIDATION,
  })

  const nextPage = () => {
    navigate(ROUTE_PERSONAL_INFO)
  }

  const checkMandatory = () => {
    setMandatoryOccupation(formik.values[DATA.FORMIK_ID.occupation] === '')
    setMandatorySourceOfIncome(
      formik.values[DATA.FORMIK_ID.source_of_income] === ''
    )
    setMandatoryMonthlyBilling(
      formik.values[DATA.FORMIK_ID.monthly_billing] === ''
    )
  }

  const handleSubmit = (e) => {
    checkMandatory()
    formik.handleSubmit(e)
  }

  const handleSourceOfIncomeChange = (event, params) => {
    formik.setFieldValue(DATA.FORMIK_ID.source_of_income, params.value)
    setMandatorySourceOfIncome(false)
  }

  const handleSourceOfIncomeFocus = () => {
    setSourceOfIncomeFocused(true)
    setMandatorySourceOfIncome(false)
  }

  const handleSourceOfIncomeBlur = () => {
    setSourceOfIncomeFocused(false)
  }

  const handleOccupationBlur = () => {
    setOccupationFocused(false)
  }

  const handleOccupationFocus = () => {
    setMandatoryOccupation(false)
    setOccupationFocused(true)
  }

  const handleMonthlyBillingFocus = () => {
    setMandatoryMonthlyBilling(false)
  }

  const renderMenuItems = (options) =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))

  const renderError = (err, text) => (
    <FormHelperText error={err} sx={{ display: err ? null : 'none' }}>
      <Stack alignItems="center" direction="row">
        <ErrorHelper mb={14} ml={0} mr={1} />
        <Box>{text}</Box>
      </Stack>
    </FormHelperText>
  )

  const renderDivider = (show) => (
    <div
      style={{
        borderBottom: show ? 'none' : `1px solid ${theme.palette.surface.c400}`,
        margin: 'auto',
        width: '90%',
      }}
    />
  )

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={{ ...DATA.content }}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        onContinue={handleSubmit}
      >
        <LoadingDialog
          callback={nextPage}
          content={DIALOGS.saving}
          done={success}
          loading={loading}
        />
        <form onSubmit={formik.handleSubmit}>
          <Stack mb={2}>
            <Typography sx={subtitleStyles} variant="body0">
              {DATA.content.economicInformationSubtitle}
            </Typography>
          </Stack>
          <Paper
            borderRadius={theme.borderRadius[0]}
            sx={{
              padding: 0,
            }}
          >
            <StackFinancialInformation alignItems="space-between">
              <FormControl
                id="occupation_form_control"
                sx={occupationFormControlStyles}
                variant="standard"
              >
                <InputLabel>{DATA.occupation_label}</InputLabel>
                <Select
                  disableUnderline
                  IconComponent={TrailIconDown}
                  id={DATA.FORMIK_ID.occupation}
                  MenuProps={{
                    PaperProps: {
                      className: classes.scrollbar,
                      style: {
                        maxHeight: 200,
                        overflowY: 'scroll',
                        width: '100%',
                      },
                    },
                    style: {
                      top: '14px',
                    },
                    sx: {
                      '&& .Mui-selected': {
                        backgroundColor: theme.palette.surface.c200,
                      },
                      '&& .Mui-selected:hover': {
                        backgroundColor: theme.palette.surface.c200,
                      },
                    },
                  }}
                  name={DATA.FORMIK_ID.occupation}
                  onBlur={handleOccupationBlur}
                  onChange={formik.handleChange}
                  onFocus={handleOccupationFocus}
                  value={formik.values[DATA.FORMIK_ID.occupation]}
                >
                  {renderMenuItems(DATA.occupation_options)}
                </Select>
              </FormControl>
              {renderDivider(showDivider)}
              <FormControl
                id="source_of_income_form_control"
                sx={sourceOfIncomeFormControlStyles}
                variant="standard"
              >
                <Autocomplete
                  disableClearable
                  id={DATA.FORMIK_ID.source_of_income}
                  isOptionEqualToValue={(option, value) => option === value}
                  label={DATA.source_of_income_label}
                  onBlur={handleSourceOfIncomeBlur}
                  onChange={handleSourceOfIncomeChange}
                  onFocus={handleSourceOfIncomeFocus}
                  options={DATA.source_of_income_options}
                  placeholder={DATA.source_of_income_placeholder}
                  value={formik.values[DATA.FORMIK_ID.source_of_income]}
                />
              </FormControl>
            </StackFinancialInformation>
          </Paper>

          {renderError(error, economicInfoErrorText)}
          <Stack mb={2} mt={3}>
            <Typography sx={subtitleStyles} variant="body0">
              {DATA.content.monthlyBillingSubtitle}
            </Typography>
          </Stack>

          <Paper
            sx={{
              border: 'none',
              padding: 0,
            }}
          >
            <StackFinancialInformation alignItems="space-between">
              <FormControl variant="outlined">
                <InputLabel
                  sx={{
                    color: theme.palette.text.light,
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: theme.fontWeight.bold,
                    top: '5px',
                    zIndex: 0,
                  }}
                >
                  {DATA.monthly_billing_label}
                </InputLabel>
                <Select
                  error={mandatoryMonthlyBilling}
                  IconComponent={TrailIconDown}
                  MenuProps={{
                    PaperProps: {
                      className: classes.scrollbar,
                    },
                    sx: {
                      '&& .Mui-selected': {
                        backgroundColor: theme.palette.surface.c200,
                      },
                      '&& .Mui-selected:hover': {
                        backgroundColor: theme.palette.surface.c200,
                      },
                    },
                  }}
                  name={DATA.FORMIK_ID.monthly_billing}
                  onChange={formik.handleChange}
                  onFocus={handleMonthlyBillingFocus}
                  value={formik.values[DATA.FORMIK_ID.monthly_billing]}
                >
                  {renderMenuItems(DATA.monthly_billing_options)}
                </Select>
              </FormControl>
            </StackFinancialInformation>
          </Paper>
        </form>
        {renderError(mandatoryMonthlyBilling, DATA.monthly_billing_error)}
      </StepWrapper>
      <SubmitError onClose={() => setOpenSnackbar(false)} open={openSnackbar} />
    </Layout>
  )
}

export default FinancialInformation
