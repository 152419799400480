import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { useTheme } from '@mui/material'
import { randomString } from 'utils'

const StepperProgress = ({ steps, progress, height }) => {
  const theme = useTheme()

  return (
    <Box sx={{ margin: `0 ${theme.space[4]}px`, position: 'relative' }}>
      <LinearProgress
        color="primary"
        sx={{
          '&.MuiLinearProgress-root': {
            backgroundColor: theme.backgrounds[2],
          },
          height,
        }}
        value={progress}
        variant="determinate"
      />
      <Stack
        data-testid="stepper-progress-divider"
        direction="row"
        justifyContent="space-evenly"
        sx={{ left: 0, position: 'absolute', top: 0, width: '100%' }}
      >
        {Array.from({ length: steps - 1 }, () => (
          <Box
            key={randomString()}
            sx={{
              background: theme.backgrounds[0],
              height,
              width: 4,
            }}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default StepperProgress

StepperProgress.propTypes = {
  height: PropTypes.number,
  progress: PropTypes.number,
  steps: PropTypes.number.isRequired,
}

StepperProgress.defaultProps = {
  height: 2,
  progress: 0,
}
