const MuiList = (theme) => ({
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'compact' },
      style: {
        '& > .MuiListItem-root': {
          '& > .MuiListItemIcon-root': {
            marginRight: theme.space[3],
            minWidth: 0,
          },
          alignItems: 'stretch',
          borderBottom: 'none',
          padding: 6,
          paddingLeft: 0,
          position: 'relative',
        },
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
  ],
})

export default MuiList
