import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Gas = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 20 18"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14H12M15 1L17.4 2.8C18.4072 3.55542 19 4.74097 19 6V15.25C19 16.2165 18.2165 17 17.25 17C16.2835 17 15.5 16.2165 15.5 15.25V12.5C15.5 10.567 13.933 9 12 9M19 8L17.7812 7.18747C16.6684 6.4456 16 5.19668 16 3.85926V2M1 16V3C1 1.89543 1.89543 1 3 1H10C11.1046 1 12 1.89543 12 3V16C12 16.5523 11.5523 17 11 17H2C1.44771 17 1 16.5523 1 16ZM3.5 8.75V3.75C3.5 3.61193 3.61193 3.5 3.75 3.5H9.25C9.38807 3.5 9.5 3.61193 9.5 3.75V8.75C9.5 8.88807 9.38807 9 9.25 9H3.75C3.61193 9 3.5 8.88807 3.5 8.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Gas

Gas.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Gas.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
