/* eslint-disable import/no-extraneous-dependencies */
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { HomePageSlider, HomePageFirstActions } from 'components/Renders'

const HomePageApp = () => (
  <>
    <Box
      sx={{
        backgroundImage: 'url(/images/slide-1.jpg)',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
      }}
    >
      <HomePageSlider />
    </Box>

    <Stack
      id="sliderContainer"
      sx={{
        bottom: 0,
        position: 'fixed',
        width: '100%',
      }}
    >
      <HomePageFirstActions />
    </Stack>
  </>
)

export default HomePageApp
