import { AppBar as MuiAppbar } from '@mui/material'
import { styled } from '@mui/material/styles'

const AppBar = styled(MuiAppbar, {
  // Configure which props should be forwarded on DOM
  name: 'MuiAppBar',
  // We are specifying here how the styleOverrides are being applied based on props
  slot: 'Root',
})(({ theme }) => ({
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
  },
  backgroundColor: theme.backgrounds[1],
  boxShadow: 'none',
}))

export default AppBar
