import PropTypes from 'prop-types'
import { useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { ErrorIcon } from 'components/Icons'
import { formatPhoneNumber } from 'utils'
import FormText from '../Text'

const FormPhone = ({ id, error, label, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value || '')

  const handleInput = (e) => {
    const formated = formatPhoneNumber(e.target.value)
    const synthEvt = { ...e }

    synthEvt.target.value = formated?.replace(/[\s()]/g, '') || null

    onChange(synthEvt)
    setInputValue(formated)
  }

  return (
    <FormText
      endAdornment={
        <InputAdornment position="end">
          <IconButton edge="end">{!!error && <ErrorIcon />}</IconButton>
        </InputAdornment>
      }
      error={error}
      id={id}
      label={label}
      name={id}
      onChange={(e) => handleInput(e)}
      type="tel"
      value={inputValue}
    />
  )
}

export default FormPhone

FormPhone.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

FormPhone.defaultProps = {
  error: false,
  label: '',
  onChange: null,
  value: '',
}
