import { Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/material/styles'

const BusinessAddressAutocomplete = styled(MuiStack)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: 53,
  },
  '& .MuiInputBase-root input': {
    paddingBottom: 8,
  },
  '& .MuiTextField-root .MuiInputLabel-shrink': {
    left: 32,
    top: 16,
  },
  '& .MuiTextField-root label[data-shrink=false].Mui-focused': {
    fontSize: 10,
    top: -10,
  },
  '& label': {
    color: theme.palette.text.primary,
    fontWeight: theme.fontWeight.regular,
    left: 32,
  },
}))

export default BusinessAddressAutocomplete
