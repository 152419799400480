import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { WrapLogo } from 'components/Icons'
import DATA from 'constants/pages/home.json'

const HomePageHeader = () => {
  const theme = useTheme()

  return (
    <Stack alignItems="center" flexDirection="row" sx={{ width: '100%' }}>
      <WrapLogo size={2} />
      <Typography
        color="white.main"
        sx={{ paddingLeft: `${theme.space[4]}px` }}
        variant="h4"
      >
        {DATA.app.TITLE}
      </Typography>
    </Stack>
  )
}

export default HomePageHeader
