// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum'

export const stopDatadogSession = () => {
  if (window?.DD_RUM?.getInternalContext()) {
    window.DD_RUM.stopSession()
  }
}

const dataDogInit = () => {
  if (
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
    process.env.REACT_APP_DATADOG_APP_ID
  ) {
    if (!window?.DD_RUM?.getInternalContext()) {
      datadogRum.init({
        applicationId: `${process.env.REACT_APP_DATADOG_APP_ID}`,
        clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
        defaultPrivacyLevel: 'mask-user-input',
        env: `${process.env.REACT_APP_ENV}`,
        service: `${process.env.REACT_APP_ENV}-react.onboarding-web-client`,
        sessionReplaySampleRate: 2,
        sessionSampleRate: 100,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        site: 'datadoghq.com',
        storeContextsAcrossPages: true,
        trackFrustrations: true,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
      })

      // stop session on page reload
      window?.addEventListener('beforeunload', () => {
        stopDatadogSession()
      })
    }
  }
}

export default dataDogInit
