import KYC from 'constants/kyc'

const isKycAccepted = (status) => {
  const subSteps = status?.userData.subSteps
  const kycSubstep = subSteps.find((e) => e.stepId === 'kycCompleted')

  return kycSubstep.status === KYC.ACCEPTED
}

export default isKycAccepted
