import SvgIcon from '@mui/material/SvgIcon'

const Icon = ({ size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_7603_480803"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="25"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" x="0.5" />
      </mask>
      <g mask="url(#mask0_7603_480803)">
        <path
          d="M15.0811 12.1737C14.3651 12.1737 13.7585 11.9206 13.2613 11.4144C12.7641 10.9083 12.5155 10.2972 12.5155 9.58112C12.5155 8.86508 12.7641 8.25847 13.2613 7.7613C13.7585 7.26412 14.3651 7.01554 15.0811 7.01554C15.7972 7.01554 16.4083 7.26412 16.9144 7.7613C17.4206 8.25847 17.6737 8.86508 17.6737 9.58112C17.6737 10.2972 17.4206 10.9083 16.9144 11.4144C16.4083 11.9206 15.7972 12.1737 15.0811 12.1737ZM15.08 10.2161C15.2567 10.2161 15.4069 10.1543 15.5306 10.0306C15.6543 9.90692 15.7161 9.75673 15.7161 9.58003C15.7161 9.40332 15.6543 9.258 15.5306 9.14407C15.4069 9.03013 15.2567 8.97316 15.08 8.97316C14.9033 8.97316 14.758 9.03013 14.6441 9.14407C14.5301 9.258 14.4732 9.40332 14.4732 9.58003C14.4732 9.75673 14.5301 9.90692 14.6441 10.0306C14.758 10.1543 14.9033 10.2161 15.08 10.2161ZM20.9184 18C20.2054 18 19.5951 17.748 19.0876 17.244C18.58 16.74 18.3263 16.1314 18.3263 15.4184C18.3263 14.7054 18.5794 14.0977 19.0856 13.5953C19.5917 13.093 20.2028 12.8418 20.9189 12.8418C21.6349 12.8418 22.2441 13.0938 22.7465 13.5978C23.2488 14.1018 23.5 14.7104 23.5 15.4234C23.5 16.1364 23.248 16.7441 22.744 17.2465C22.24 17.7488 21.6314 18 20.9184 18ZM20.92 16.0424C21.0967 16.0424 21.242 15.9816 21.3559 15.86C21.4699 15.7385 21.5268 15.5909 21.5268 15.4172C21.5268 15.2436 21.4699 15.0972 21.3559 14.9781C21.242 14.859 21.0967 14.7994 20.92 14.7994C20.7433 14.7994 20.5931 14.8602 20.4694 14.9818C20.3457 15.1033 20.2839 15.2509 20.2839 15.4246C20.2839 15.5982 20.3457 15.7446 20.4694 15.8637C20.5931 15.9828 20.7433 16.0424 20.92 16.0424ZM13.8672 18L12.5 16.6328L22.1483 7L23.5 8.35169L13.8672 18Z"
          fill="#FC4C02"
        />
        <path
          d="M6 18C4.6494 18 3.56175 17.4795 2.73705 16.4384C1.91235 15.3974 1.5 14.0846 1.5 12.5C1.5 10.8848 1.91235 9.56431 2.73705 8.53859C3.56175 7.51286 4.6494 7 6 7C7.3506 7 8.43825 7.52052 9.26295 8.56158C10.0876 9.60263 10.5 10.9154 10.5 12.5C10.5 14.0846 10.0876 15.3974 9.26295 16.4384C8.43825 17.4795 7.3506 18 6 18ZM6 15.8424C6.73506 15.8424 7.28635 15.5064 7.65388 14.8344C8.02141 14.1624 8.20518 13.3842 8.20518 12.5C8.20518 11.6158 8.02141 10.8376 7.65388 10.1656C7.28635 9.49357 6.73506 9.15756 6 9.15756C5.26494 9.15756 4.71365 9.49357 4.34612 10.1656C3.97859 10.8376 3.79482 11.6158 3.79482 12.5C3.79482 13.3842 3.97859 14.1624 4.34612 14.8344C4.71365 15.5064 5.26494 15.8424 6 15.8424Z"
          fill="#FC4C02"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Icon
