import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const MoneyBag = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 54 33">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50018 9.00647L5.9848 11.9734C3.52689 14.7809 3.94338 21 8.50019 21H16.5002C21.0505 21 21.4736 14.7707 18.992 11.9698L16.5002 9.00647M8.50018 9.00647H16.5002M8.50018 9.00647C7.9479 9.00647 7.50018 8.55899 7.50018 8.007V7.00754C7.50018 6.45555 7.9479 6.00808 8.50018 6.00808H9.50018M16.5002 9.00647C17.0525 9.00647 17.5002 8.55899 17.5002 8.007V7.00754C17.5002 6.45555 17.0525 6.00808 16.5002 6.00808H15.5002M15.5002 6.00808C15.5002 6.00808 17.3933 4.84842 16.5002 3.5094C15.8384 2.51729 13.9614 3.26972 13.0332 3.72617C12.6996 3.89024 12.303 3.88651 11.9718 3.7176C11.0542 3.24959 9.19722 2.48212 8.50018 3.5094C7.55856 4.89714 9.50018 6.00808 9.50018 6.00808M15.5002 6.00808H9.50018M14.0002 13.5041C14.0002 13.5041 14.0002 12.5046 12.5002 12.5046M11.0002 16.5024C11.0002 16.5024 11.0002 17.5019 12.5002 17.5019M12.5002 12.5046C12.5002 12.5046 11.0002 12.5046 11.0002 14.0038C11.0002 15.5 14.0002 14.5008 14.0002 16C14.0002 17.4992 12.5002 17.5019 12.5002 17.5019M12.5002 12.5046V11.5051M12.5002 17.5019V18.5014"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default MoneyBag

MoneyBag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

MoneyBag.defaultProps = {
  color: (theme) => theme.palette.primary.main,
  size: 1.5,
}
