const MuiAlert = (theme) => ({
  styleOverrides: {
    root: {
      alignItems: 'center',
      borderRadius: theme.space[2],
      fontFamily: ['"Averta Std"', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: theme.fontWeight.semiBold,
      lineHeight: '20px',
      padding: `${theme.space[1]} ${theme.space[3]}`,
    },
  },
  variants: [
    {
      props: { severity: 'error' },
      style: {
        '& path': {
          stroke: theme.palette.error.main,
        },
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
      },
    },
    {
      props: { severity: 'warning' },
      style: {
        '& path': {
          stroke: theme.palette.warning.main,
        },
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.main,
      },
    },
    {
      props: { severity: 'success' },
      style: {
        '& path': {
          stroke: theme.palette.success.main,
        },
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
      },
    },
    {
      props: { severity: 'info' },
      style: {
        '& path': {
          stroke: theme.palette.info.main,
        },
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  ],
})

export default MuiAlert
