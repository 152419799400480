import PropTypes from 'prop-types'
import RadioGroup from 'components/RadioGroup'
import { RadioIcon, RadioIconChecked } from 'components/Icons'
import { useTheme } from '@mui/material'

const CardGroup = ({ id, items, onChange, value, maxItems }) => {
  const theme = useTheme()

  return (
    <RadioGroup
      containerStyles={{
        '& .MuiListItemButton-root': {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        '& .MuiListItemIcon-root:last-of-type': {
          position: 'absolute',
          right: 0,
          top: 5,
        },
        '& .MuiListItemText-root': {
          marginBottom: `${theme.space[4]}px`,
        },
        columnGap: 2,
      }}
      id={id}
      items={items}
      maxItems={maxItems}
      onChange={onChange}
      radioProps={{
        checkedIcon: <RadioIconChecked />,
        icon: <RadioIcon />,
      }}
      radioStyles={{}}
      value={value}
    />
  )
}

export default CardGroup

CardGroup.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
  maxItems: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

CardGroup.defaultProps = {
  maxItems: 100,
  onChange: null,
  value: null,
}
