import SvgIcon from '@mui/material/SvgIcon'

const TrailIconDown = (props) => (
  <SvgIcon viewBox="-3 -2 24 24" {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66602 8.33301L9.99935 11.6663L13.3327 8.33301"
        stroke="#5E5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default TrailIconDown
