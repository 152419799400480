import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Footnote from 'components/Footnote'
import { Box, useTheme } from '@mui/material'
import Image from 'components/Image'
import LoadingButton from 'components/LoadingButton'
import { randomString, isDevice } from 'utils'
import { useGlobalContext } from 'store/global'
import cleverTap from 'helpers/clevertap'

import DIALOGS from 'constants/dialogs.json'

const StepWrapper = ({
  content,
  contentCentered,
  controls,
  children,
  imageSize,
  onContinue,
  onContinueText,
  onLoadingText,
  loading,
  eventCleverTap,
  footnote,
  titleStyles,
  sx,
}) => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [controlSize, setControlSize] = useState(0)
  const hasControls = !!onContinue || !!controls || !!footnote
  const controlsRef = useRef(null)

  const handleContinue = (e) => {
    if (eventCleverTap) {
      cleverTap.event(eventCleverTap, {
        ActionType: 'click',
        OS: device.type,
        Target: 'continue',
      })
    }

    onContinue(e)
  }

  const renderImage = () => {
    if (content.image) {
      return (
        <Image
          height={imageSize?.height || 96}
          src={content.image}
          width={imageSize?.width || 96}
        />
      )
    }

    return null
  }

  const renderTitle = () => {
    if (content.title) {
      return (
        <Typography
          align={content.image ? 'center' : 'left'}
          component="h1"
          dangerouslySetInnerHTML={{ __html: content.title }}
          fontWeight={theme.fontWeight.bold}
          sx={{ ...titleStyles }}
          variant="hl"
        />
      )
    }

    return null
  }

  const renderSubtitle = () => {
    if (content.subtitle) {
      return (
        <Typography
          align={content.image ? 'center' : 'left'}
          dangerouslySetInnerHTML={{ __html: content.subtitle }}
          sx={{ mt: 1 }}
          variant="body2"
        />
      )
    }

    return null
  }

  const renderDisclaimer = () => {
    if (content.subtitle) {
      return (
        <Typography
          align={content.image ? 'center' : 'left'}
          dangerouslySetInnerHTML={{ __html: content.disclaimer }}
          sx={{ mt: 1 }}
          variant="hxs"
        />
      )
    }

    return null
  }

  const renderControls = () => (
    <Stack spacing={4}>
      {onContinue && (
        <LoadingButton
          color="orange"
          loading={loading}
          onClick={handleContinue}
          size="full-width"
          sx={{
            '&.Mui-disabled': {
              '&.MuiLoadingButton-loading': {
                backgroundColor: loading && theme.backgrounds[10],
              },
            },
            height: '48px',
          }}
          variant="primary-form"
        >
          {!loading
            ? onContinueText || DIALOGS.continueBtn
            : onLoadingText || DIALOGS.procesingBtn}
        </LoadingButton>
      )}
      {Array.isArray(controls) && (
        <Stack spacing={2}>
          {controls
            .filter((item) => item !== null)
            .map((control) => (
              <Box key={randomString()}>{control}</Box>
            ))}
        </Stack>
      )}
    </Stack>
  )

  const renderFootnote = () => {
    if (footnote) {
      if (React.isValidElement(footnote)) {
        return footnote
      }

      return <Footnote>{footnote}</Footnote>
    }

    return null
  }

  const getControlsSize = () => {
    const element = controlsRef.current

    if (element) {
      const { width, height } = element.getBoundingClientRect()

      return { height, width }
    }

    return { height: 0, width: 0 }
  }

  useEffect(() => {
    if (hasControls) {
      const { height } = getControlsSize()

      setControlSize(`${height - theme.space[0] * 2}px`)
    }
  }, [hasControls])

  return (
    <Stack
      alignItems="stretch"
      justifyContent={contentCentered ? 'center' : 'space-between'}
      sx={{
        flexGrow: 1,
        padding: `${theme.space[4]}px`,
        pt: 0,
        width: '100%',
        ...sx,
      }}
    >
      <Stack
        data-testid="stepwrapper-content"
        sx={{ mb: contentCentered ? 0 : 'auto', pb: controlSize }}
      >
        <Stack
          alignItems={content.image ? 'center' : 'flex-start'}
          sx={{
            mb: 2.5,
          }}
        >
          <Box sx={{ ...content.imageSX }}>{renderImage()}</Box>
          {renderTitle()}
          {renderSubtitle()}
          {renderDisclaimer()}
        </Stack>
        {children}
      </Stack>
      {hasControls ? (
        <Stack
          ref={controlsRef}
          data-testid="stepwrapper-controls"
          // spacing={4}
          sx={{
            background: theme.palette.white.main,
            bottom: 0,
            left: 0,
            padding: 2,
            position: isDevice ? 'fixed' : 'initial',
            width: '100%',
            zIndex: 1,
          }}
        >
          {(controls || onContinue) && renderControls()}
          {footnote && renderFootnote()}
        </Stack>
      ) : null}
    </Stack>
  )
}

export default StepWrapper

StepWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  content: PropTypes.shape(),
  contentCentered: PropTypes.bool,
  controls: PropTypes.arrayOf(PropTypes.element),
  eventCleverTap: PropTypes.string,
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  imageSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  loading: PropTypes.bool,
  onContinue: PropTypes.func,
  onContinueText: PropTypes.string,
  onLoadingText: PropTypes.string,
  sx: PropTypes.shape({}),
  titleStyles: PropTypes.shape(),
}

StepWrapper.defaultProps = {
  children: null,
  content: {},
  contentCentered: false,
  controls: null,
  eventCleverTap: null,
  footnote: null,
  imageSize: null,
  loading: false,
  onContinue: null,
  onContinueText: null,
  onLoadingText: null,
  sx: null,
  titleStyles: null,
}
