import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  '@keyframes visited': {
    '0%': {
      transform: 'scaleX(0)',
    },
    '100%': {
      transform: 'scaleX(1)',
    },
  },
  bulletContainer: {
    '& .swiper-pagination-bullet': {
      '&.visited': {
        background: 'rgba(255, 255, 255, 1)',
      },
      background: 'rgba(255, 255, 255, 0.4)',
      borderRadius: '9999px',
      height: '3px',
      margin: '0 3px',
      opacity: 1,
      width: '30%',
      willChange: 'background',
    },
    '& .swiper-pagination-bullet-active': {
      '&::after': {
        animation: '$visited 8s linear',
        background: theme.palette.white.main,
        content: '""',
        display: 'block',
        height: '3px',
        transformOrigin: 'left',
        width: '100%',
        willChange: 'transform',
      },
      width: '30%',
    },
    marginTop: `${theme.space[4]}px`,
  },
  stackImages: {
    '& > div': {
      marginRight: `${theme.space[6]}px`,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: `${theme.space[4]}px`,
    marginTop: `${theme.space[4]}px`,
  },
  swiperImage: {
    height: '100%',
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  swiperRoot: {
    '& .swiper-slide': {
      alignItems: 'flex-start',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: `${theme.space[4]}px`,
      width: 'auto',
    },
    '& .swiper-wrapper': {
      boxSizing: 'border-box',
    },
    '&.swiper': {
      height: '100%',
      width: '100%',
    },
    '&::after': {
      backgroundColor: theme.palette.black.main,
      content: '""',
      height: '100%',
      left: 0,
      opacity: 0.7,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    },
  },
  textSlider: {
    bottom: 130,
    position: 'absolute',
    width: '100%',
  },
  textSliderContent: {
    padding: `${theme.space[4]}px`,
  },
}))

export default useStyles
