import { useEffect } from 'react'
import { getToken } from 'helpers/tokenHelpers'
import { useNavigate } from 'react-router-dom'
import { ROUTE_HOME, ROUTE_PROFILE_SETUP } from 'constants/routes'

const NotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)

      return
    }

    navigate(ROUTE_PROFILE_SETUP)
  }, [])

  return null
}

export default NotFound
