const actions = {
  RESET: 'CLEAR_FORM',
  UPDATE_CLABE: 'UPDATE_CLABE',
  UPDATE_CURP_AND_RFC: 'UPDATE_CURP_AND_RFC',
  UPDATE_DATE_PLACE_OF_BIRTH: 'UPDATE_DATE_PLACE_OF_BIRTH',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_GENDER: 'UPDATE_GENDER',
  UPDATE_KYC_ACCEPTED: 'UPDATE_KYC_ACCEPTED',
  UPDATE_KYC_STATUS: 'UPDATE_KYC_STATUS',
  UPDATE_MID: 'UPDATE_MID',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_NEIGHBORHOOD: 'UPDATE_ADDRESS_NEIGHBORHOOD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_PHONE: 'UPDATE_PHONE',
  UPDATE_PHONE_MARKETING: 'UPDATE_PHONE_MARKETING',
}

export default actions
