import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import OtpInput from 'react-otp-input'
import { randomString } from 'utils'

import DATA from './constData.json'

const OTPForm = ({ id, value, onChange, error }) => {
  const theme = useTheme()

  const inputStyle = {
    '&': {
      background: theme.palette.error.main,
    },
    border: `1px solid ${
      error ? theme.palette.error.main : theme.backgrounds[2]
    }`,
    borderRadius: theme.space[2],
    color: error ? theme.palette.error.main : theme.palette.text.main,
    fontSize: 28,
    fontWeight: theme.fontWeight.semibold,
    lineHeight: 40 / 28,
    padding: theme.space[2],
    width: '85%',
  }

  return (
    <FormControl fullWidth id={id}>
      <OtpInput
        containerStyle={{
          justifyContent: 'space-between',
          padding: `${theme.space[6]}px 0 0`,
        }}
        data-testid={id}
        focusStyle={{
          border: `1px solid ${
            error ? theme.palette.error.main : theme.palette.black.main
          }`,
          outline: 'none',
        }}
        inputStyle={inputStyle}
        isInputNum
        numInputs={6}
        onChange={onChange}
        placeholder={DATA.placeholder}
        shouldAutoFocus
        value={value}
      />
      {error && (
        <FormHelperText error id={`input-error-otp-${id}`} sx={{ ml: 0 }}>
          {DATA.errorLabel}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default OTPForm

OTPForm.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

OTPForm.defaultProps = {
  error: false,
  id: randomString(),
  value: '',
}
