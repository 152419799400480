import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  layout: {
    backgroundColor: theme.backgrounds[1],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    maxWidth: '100vw',
    overflowWrap: 'break-word',
    padding: `${theme.space[4]}px 0px`,
    position: 'relative',
    width: '100%',
  },
  layoutDesktop: {
    '& section': {
      borderRadius: `${theme.borderRadius[0]}px`,
      minHeight: 640,
      padding: '40px',
    },
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: 560,
    width: '100%',
  },
  positionFixed: {
    position: 'fixed',
  },
  positionInitial: {
    position: 'initial',
  },
}))

export default useStyles
