const MuiFormGroup = (theme) => ({
  styleOverrides: {
    root: {
      '& .Mui-error .MuiCheckbox-root svg': {
        fill: theme.palette.error.main,
      },
    },
  },
})

export default MuiFormGroup
