import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Box, Toolbar, Typography, useTheme } from '@mui/material'
import AppBar from 'components/AppBar'
import { Arrow, Plus, WrapLogo } from 'components/Icons'
import Button from 'components/Button'
import StepperProgress from 'components/StepperProgress'
import React from 'react'

const Header = ({ data, children, sx, skipCallback, styles }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const handleBack = () => {
    const isHistoryAvailable =
      typeof window !== 'undefined' &&
      window.history &&
      window.history.length > 1

    if (isHistoryAvailable) {
      window.history.back()
    } else {
      navigate(data.backUrl)
    }
  }

  const renderTitle = () => {
    if (data.title) {
      return React.isValidElement(data.title) ? (
        data.title
      ) : (
        <Typography
          align="center"
          fontWeight={theme.fontWeight.semibold}
          variant="body1"
        >
          {data.title}
        </Typography>
      )
    }

    return null
  }

  const calculateWidths = (column) => {
    switch (column) {
      case 0:
        if (data.backUrl && data.showSkip) return 25
        if (data.backUrl || data.showSkip) return 25

        return 0
      case 1:
        if (data.backUrl && data.showSkip) return 50
        if (data.backUrl || data.showSkip) return 75

        return 100
      default:
        return 0
    }
  }

  return data ? (
    <AppBar className={clsx(styles, 'site-header')} sx={{ ...sx }}>
      <Toolbar>
        {data.backUrl && (
          <Box
            className="box1"
            sx={{ display: 'flex', flexBasis: `${calculateWidths(0)}%` }}
          >
            <Button
              onClick={data.backUrl && handleBack}
              sx={{
                left: -theme.space[3],
                minWidth: 0,
                position: 'relative',
              }}
            >
              {data.icon ? (
                <Plus color={theme.palette.text.primary} rotate={45} />
              ) : (
                <Arrow size={1} />
              )}
            </Button>
          </Box>
        )}
        {(data.icon || data.title) && (
          <Box
            className="box2"
            sx={{
              display: 'flex',
              flexBasis: `${calculateWidths(1)}%`,
              justifyContent: 'center',
              ml: !data.backUrl && data.showSkip ? '20%' : '0',
            }}
          >
            {data.icon ? <WrapLogo size={2} /> : renderTitle()}
          </Box>
        )}
        <Box
          className="box3"
          sx={{
            display: 'flex',
            flexBasis: `${calculateWidths(0)}%`,
            justifyContent: 'flex-end',
          }}
        >
          {data.showSkip && (
            <Button
              onClick={skipCallback}
              sx={{
                fontSize: 16,
                fontWeight: theme.fontWeight.semibold,
                lineHeight: '24px',
                textDecoration: 'none',
              }}
              type="link"
            >
              Omitir
            </Button>
          )}
        </Box>
      </Toolbar>
      {children}
      {data?.progress && <StepperProgress progress={data.progress} steps={3} />}
    </AppBar>
  ) : null
}

export default Header

Header.propTypes = {
  children: PropTypes.element,
  data: PropTypes.shape({
    backUrl: PropTypes.string,
    icon: PropTypes.bool,
    progress: PropTypes.number,
    redirectText: PropTypes.string,
    redirectUrl: PropTypes.string,
    showRedirect: PropTypes.bool,
    showSkip: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  skipCallback: PropTypes.func,
  styles: PropTypes.string,
  sx: PropTypes.shape(),
}

Header.defaultProps = {
  children: null,
  data: null,
  skipCallback: null,
  styles: '',
  sx: null,
}
