import { useTheme } from '@mui/material'
import Button from 'components/Button'
import { useGlobalContext } from 'store/global'
import DATA from 'constants/pages/home.json'
import Stack from '@mui/material/Stack'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ACCOUNT_CREATION } from 'constants/routes'
import { startNativeBridge } from 'helpers/callNativeBridge'

const HomePageFirstActions = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { device } = useGlobalContext()

  const handleClick = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS.WELCOME, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.GET_STARTED,
    })

    segment.track(DATA.SEGMENT.EVENTS.SIGNUP_SELECTED)

    navigate(ROUTE_ACCOUNT_CREATION)
  }

  const handleRedirect = (event) => {
    segment.track(DATA.SEGMENT.EVENTS.GO_TO_LOGIN)
    startNativeBridge(event, { to: 'login' })
  }

  return (
    <Stack padding={2} spacing={2}>
      <Button color="orange" onClick={handleClick} size="large">
        {DATA.app.BTN_PRIMARY_TEXT}
      </Button>
      <Button
        href="#"
        onClick={handleRedirect}
        size="large"
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.surface.c900,
          },
          backgroundColor: theme.palette.surface.c900,
          color: theme.palette.white.main,
        }}
        type="link"
      >
        {DATA.app.LOGIN_TEXT}
      </Button>
    </Stack>
  )
}

HomePageFirstActions.propTypes = {}
export default HomePageFirstActions
