import { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Stack, useTheme } from '@mui/material'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import LoadingButton from 'components/LoadingButton'
import { useGlobalContext } from 'store/global'
import { ROUTE_ACCOUNT_ACTIVATION, ROUTE_HOME } from 'constants/routes'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import DATA from 'constants/pages/tac.json'
import DIALOGS from 'constants/dialogs.json'
import { getToken } from 'helpers/tokenHelpers'
import Tac from 'components/Tac'
import { useFlags } from 'launchdarkly-react-client-sdk'

const TacPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const inputsId = [
    DATA.FORMIKS_ID.TAC_PRIVACY_INPUT_ID,
    DATA.FORMIKS_ID.CONTRACT_WALLET_INPUT_ID,
    DATA.FORMIKS_ID.TAC_PRIVACY_WALLET_INPUT_ID,
  ]
  const [loading, setLoading] = useState(false)
  const { device } = useGlobalContext()
  const { onboardingSemiMandatory } = useFlags()
  const bottomRef = useRef(null)

  const TAC_VALIDATION = yup.object().shape({
    [inputsId[0]]: yup
      .string()
      .test('test-not-empty', '', (val) => val === 'false'),
    [inputsId[1]]: yup
      .string()
      .test('test-not-empty', '', (val) => val === 'false'),
    [inputsId[2]]: yup
      .string()
      .test('test-not-empty', '', (val) => val === 'false'),
  })

  const formik = useFormik({
    initialValues: {
      [inputsId[0]]: 'true',
      [inputsId[1]]: 'true',
      [inputsId[2]]: 'true',
    },
    onSubmit: () => {
      setLoading(true)
      segment.track(DATA.SEGMENT.EVENTS.ACCEPT_TAC)
      setTimeout(() => {
        setLoading(false)
        navigate(ROUTE_ACCOUNT_ACTIVATION)
      }, 3000)
    },
    validationSchema: TAC_VALIDATION,
  })

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    segment.page(DATA.SEGMENT.PAGE)
    const initialInputs = Object.fromEntries(inputsId.map((id) => [id, false]))

    formik.setTouched(initialInputs)
  }, [])

  const handleChange = (e) => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      CheckBoxValue: e.target.checked
        ? DATA.CLEVERTAP.CHECKBOX.SELECTED[0]
        : DATA.CLEVERTAP.CHECKBOX.SELECTED[1],
      CheckboxName: e.target.labels[0].innerText,
      OS: device.type,
      Target: DATA.CLEVERTAP.CHECKBOX.TARGET,
    })
    formik.setFieldValue(e.target.name, e.target.checked)
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={
          !onboardingSemiMandatory ? DATA.content : DATA.contentSemiMandatory
        }
        controls={[
          <Stack>
            <LoadingButton
              color="orange"
              loading={loading}
              onClick={(e) => {
                bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                formik.handleSubmit(e)
              }}
              size="full-width"
              sx={{
                '&.Mui-disabled': {
                  '&.MuiLoadingButton-loading': {
                    backgroundColor: loading && theme.backgrounds[10],
                  },
                },
                height: '48px',
                width: '100%',
              }}
              variant="primary-form"
            >
              {!loading ? DATA.ctaBtn : DIALOGS.procesingBtn}
            </LoadingButton>
          </Stack>,
        ]}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        footnote={DATA.footnote}
      >
        <Stack
          ref={bottomRef}
          sx={{
            paddingBottom: theme.space[3],
          }}
        >
          <form data-testid="tac-form" onSubmit={formik.handleSubmit}>
            <Tac
              isInvalid={formik.touched[inputsId[0]] && !formik.isValid}
              onChange={handleChange}
            />
          </form>
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default TacPage
