import React, { useRef, useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Stack,
  Typography,
  useTheme,
  Link,
} from '@mui/material'
import PropTypes from 'prop-types'
import { Plus } from 'components/Icons'
import DATA from 'constants/pages/home.json'
import { isDevice } from 'utils'

const Transition = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
))

const SlideUpModal = (props) => {
  const theme = useTheme()
  const {
    open,
    title,
    children,
    onClose,
    fullscreen,
    controls,
    sx,
    swipable,
    showTerms,
  } = props
  const [isSwiping, setIsSwiping] = useState(false)
  const [startY, setStartY] = useState(0)
  const swipeRef = useRef(null)
  const [swipeDistance, setSwipeDistance] = useState(0)
  const contentRef = useRef(null)
  const linkStyles = {
    display: 'inline',
    fontFamily: 'Averta Std',
    fontSize: 12,
    fontWeight: 400,
    height: 'auto',
    textDecoration: 'none',
    verticalAlign: 'baseline',
  }

  const handleSwipeStart = (e) => {
    setIsSwiping(true)
    setStartY(e.touches[0].clientY)
  }

  const handleSwipeMove = (e) => {
    if (!isSwiping) return
    const currentY = e.touches[0].clientY

    setSwipeDistance(currentY - startY)

    if (swipeDistance > 0) {
      contentRef.current.style.top = `${swipeDistance}px`
    }
  }

  const handleSwipeEnd = () => {
    if (swipeDistance >= 100) {
      setIsSwiping(false)
      onClose()
    } else {
      contentRef.current.style.top = 0
    }
  }

  const renderTitle = () =>
    React.isValidElement(title) ? (
      title
    ) : (
      <Typography component="p" variant="h1">
        {title}
      </Typography>
    )

  return (
    <Dialog
      BackdropProps={{
        children: React.isValidElement(controls) && controls,
      }}
      data-testid="slideup-modal"
      onClose={onClose}
      open={open}
      PaperProps={{
        ref: contentRef,
        sx: {
          alignSelf: 'flex-end',
          borderRadius: fullscreen
            ? 0
            : `${theme.space[2]}px ${theme.space[2]}px 0 0`,
          height: fullscreen ? '100%' : null,
          margin: 0,
          maxHeight: fullscreen ? 'none' : null,
          maxWidth: !isDevice ? 560 : '100%',
          width: '100%',
        },
      }}
      sx={sx}
      TransitionComponent={Transition}
    >
      <Box sx={{ paddingTop: 5, position: 'relative' }}>
        {title && (
          <DialogTitle
            ref={swipeRef}
            onTouchEnd={swipable && handleSwipeEnd}
            onTouchMove={swipable && handleSwipeMove}
            onTouchStart={swipable && handleSwipeStart}
            sx={{ padding: 2, pt: 0 }}
          >
            {swipable ? (
              <Stack alignItems="center" sx={{ mt: -3 }}>
                <Divider
                  sx={{
                    background: theme.backgrounds[6],
                    borderRadius: 2,
                    height: 4,
                    width: 40,
                  }}
                />
                {renderTitle()}
              </Stack>
            ) : (
              renderTitle()
            )}
          </DialogTitle>
        )}
        {controls && (
          <Box
            className="slideup-btn-close"
            onClick={onClose}
            onKeyPress={onClose}
            role="button"
            sx={{
              position: 'absolute',
              right: `${theme.space[2]}px`,
              top: `${theme.space[3]}px`,
            }}
            tabIndex="0"
          >
            {!React.isValidElement(controls) && (
              <Plus
                color={theme.palette.text.primary}
                role="presentation"
                rotate={45}
              />
            )}
          </Box>
        )}
      </Box>
      <DialogContent sx={{ padding: 2, pt: !controls ? 2 : 0 }}>
        {children}
        {showTerms && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Typography align="center" variant="body0">
              {DATA.COMPLIANCE.MAINTEXT}
              <Link
                href={DATA.COMPLIANCE.LINKS.TERMS}
                sx={linkStyles}
                target="_blank"
              >
                {DATA.COMPLIANCE.TERMS}
              </Link>
              {DATA.COMPLIANCE.CONNECTORTEXT}
              <Link
                href={DATA.COMPLIANCE.LINKS.PRIVACY}
                sx={linkStyles}
                target="_blank"
              >
                {DATA.COMPLIANCE.PRIVACY}
              </Link>
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SlideUpModal

SlideUpModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  controls: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  fullscreen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showTerms: PropTypes.bool,
  swipable: PropTypes.bool,
  sx: PropTypes.shape(),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

SlideUpModal.defaultProps = {
  controls: true,
  fullscreen: false,
  onClose: null,
  open: false,
  showTerms: false,
  swipable: false,
  sx: null,
  title: null,
}
