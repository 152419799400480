const segment = {
  identify: (userId, traits = {}) => {
    window.analytics.identify(userId, traits)
  },
  page: (pageName, pageObject = { url: window.location.href }) => {
    window.analytics.page(pageName, pageObject)
  },
  track: (eventName, eventObject = {}) => {
    window.analytics.track(eventName, eventObject)
  },
}

export default segment
