import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Book = ({ color }) => (
  <SvgIcon viewBox="0 0 24 24">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 15H7M20 15V3H7M20 15V18M4 18C4 19.6569 5.34315 21 7 21H20V18M4 18C4 16.3431 5.34315 15 7 15M4 18V6C4 4.34315 5.34315 3 7 3M7 15V3M20 18H7M11 11H15M11 7H15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Book

Book.propTypes = {
  color: PropTypes.string,
}

Book.defaultProps = {
  color: theme.palette.text.primary,
}
