import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import SlideUpModal from 'components/SlideUpModal'
import { Chevron, ErrorIcon, Plus, Search, ErrorHelper } from 'components/Icons'
import RadioGroup from 'components/RadioGroup'
import { randomString, sanitizeString } from 'utils'
import { styled } from '@mui/material/styles'

import DATA from './constData.json'

const CustomButton = styled(Button)(({ theme }) => ({
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: theme.space[4],
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .MuiInputLabel-root': {
    left: 0,
    position: 'absolute',
    top: `-${theme.space[2]}px`,
  },
  '&.filled': {
    padding: `${theme.space[5]}px 14px ${theme.space[3]}px`,
  },
}))

const Select = (props) => {
  const {
    autoClose,
    items,
    id,
    value,
    onChange,
    error,
    errorHelperIcon,
    title,
    text,
    label,
    disabled,
    sx,
  } = props
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const [showSearch, setShowSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredItems, setFilteredItems] = useState(items)

  const filter = (str) =>
    setFilteredItems(
      items.filter((item) =>
        sanitizeString(item.label.toLowerCase()).includes(sanitizeString(str))
      )
    )

  const handleFilter = (str) => {
    setSearchQuery(str)
    filter(str)
  }

  const computeClasses = () => {
    const classes = []

    if (value) {
      classes.push('filled')
    }

    if (error) {
      classes.push('error')
    }

    return classes.join(' ')
  }

  const handleChange = (e) => {
    onChange(e)

    if (autoClose) {
      setTimeout(() => setOpen(false), DATA.autoCloseTimeout)
    }
  }

  useEffect(() => {
    setShowSearch(items?.length > DATA.searchWhenMoreThan)
  }, [items])

  useEffect(() => {
    if (items?.length && !filteredItems) {
      handleFilter('')
    }
  }, [disabled])

  return (
    <>
      <FormControl fullWidth>
        <CustomButton
          className={computeClasses()}
          disabled={disabled}
          endIcon={
            !errorHelperIcon && error ? <ErrorIcon /> : <Chevron rotate={90} />
          }
          onClick={() => setOpen(true)}
          sx={{
            backgroundColor: theme.palette.white.main,
            border: `1px solid ${theme.backgrounds[2]}`,
            borderRadius: `${theme.space[2]}px`,
            fontWeight: theme.fontWeight.regular,
            justifyContent: 'flex-start',
            padding: `${theme.space[4]}px 14px`,
            position: 'relative',
            textTransform: 'none',
            width: '100%',
            ...sx,
          }}
        >
          {text ? (
            <>
              <InputLabel htmlFor={id}>
                <Typography variant="body0">{label}</Typography>
              </InputLabel>
              <Typography align="left" sx={{ pr: 2 }} variant="body1">
                {text}
              </Typography>
            </>
          ) : (
            <Typography
              component="label"
              sx={{
                color: !value ? theme.palette.text.primary : null,
              }}
              variant="body1"
            >
              {label}
            </Typography>
          )}
        </CustomButton>
        {error && (
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            {errorHelperIcon && <ErrorHelper size={0.75} />}
            <FormHelperText error id={`select-error-${id}`} sx={{ ml: 0 }}>
              {error}
            </FormHelperText>
          </Stack>
        )}
      </FormControl>

      <SlideUpModal onClose={() => setOpen(false)} open={open} title={title}>
        <Stack>
          {showSearch && (
            <TextField
              id={`input-search-${id}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handleFilter('')}
                    position="start"
                  >
                    <Plus
                      color={theme.palette.text.primary}
                      rotate={45}
                      size={1}
                    />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search onClick={() => setSearchQuery()} size={1} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFilter(e.target.value)}
              placeholder={DATA.searchLabel}
              value={searchQuery}
            />
          )}
          {filteredItems?.length ? (
            <RadioGroup
              id={id}
              items={filteredItems}
              onChange={handleChange}
              value={value}
            />
          ) : (
            <Stack alignItems="center" sx={{ mb: 4 }}>
              <Box sx={{ mb: 2, mt: 4 }}>
                <ErrorIcon color={theme.palette.text.primary} />
              </Box>
              <Typography
                align="center"
                variant="body1"
              >{`${DATA.searchNotFound} “${searchQuery}”`}</Typography>
              <Typography align="center" variant="body0">
                {DATA.searchNotFoundExtra}
              </Typography>
            </Stack>
          )}
        </Stack>
      </SlideUpModal>
    </>
  )
}

export default Select

Select.propTypes = {
  autoClose: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorHelperIcon: PropTypes.bool,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
}

Select.defaultProps = {
  autoClose: true,
  disabled: false,
  error: false,
  errorHelperIcon: false,
  id: randomString(),
  items: null,
  sx: null,
  text: null,
  title: null,
  value: '',
}
