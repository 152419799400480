import { MAX_MATCHES, FORBIDDEN } from 'utils/password-requisites'
import cleverTap from 'helpers/clevertap'
import DATA from 'constants/pages/account.json'

const isForbidden = (pwd) =>
  FORBIDDEN.find((word) => pwd?.toLowerCase().search(word) >= 0)

const checkValidateThree = (word) => /(.)\1\1/.test(word)

const isNumberCode = (code) => code >= 48 && code <= 57
const isCharCode = (code) =>
  (code >= 97 && code <= 122) || (code >= 65 && code <= 90)
const toCharCodeArray = (word) => Array.from(word)?.map((e) => e.charCodeAt(0))

const hasInARowTogetherAsc = (charCodeArray, maxMatches) => {
  let matches = 1
  let prev = charCodeArray.shift()
  let result = true

  charCodeArray.forEach((code) => {
    if (
      (isNumberCode(code) || isCharCode(code)) &&
      prev + 1 === code &&
      matches < maxMatches
    ) {
      matches += 1
    } else {
      matches = 1
    }
    if (matches === maxMatches) {
      // eslint-disable-next-line no-console
      console.log(`Max of ${maxMatches} matches found`)
      result = false
    }
    prev = code
  })

  return result
}

const hasInARowTogetherDesc = (charCodeArray, maxMatches) => {
  let matches = 1
  let prev = charCodeArray.shift()
  let result = true

  charCodeArray.forEach((code) => {
    if (
      (isNumberCode(code) || isCharCode(code)) &&
      prev - 1 === code &&
      matches < maxMatches
    ) {
      matches += 1
    } else {
      matches = 1
    }
    if (matches === maxMatches) {
      // eslint-disable-next-line no-console
      console.log(`Max of ${maxMatches} matches found`)
      result = false
    }
    prev = code
  })

  return result
}

const validateConsecutive = (word) => {
  const charCodeArray = word?.length > 0 ? toCharCodeArray(word) : []

  return (
    hasInARowTogetherAsc(charCodeArray, MAX_MATCHES) &&
    hasInARowTogetherDesc(charCodeArray, MAX_MATCHES)
  )
}

const validateUserName = (email, password) => {
  const words = email.split('@')[0].split('.')

  return !words.find((w) => password?.toLowerCase().search(w) >= 0)
}

export const sendCTError = (device) => {
  cleverTap.event(DATA.CLEVERTAP.EVENTS.SIGNUP, {
    ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
    ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE_PASSWORD[0],
    OS: device.type,
  })
}

export const checkForbidden = (value, device) => {
  const returnValue = isForbidden(value) === undefined

  if (!returnValue) {
    sendCTError(device)
  }

  return returnValue
}

export const checkValidThree = (value, device) => {
  const returnValue = !checkValidateThree(value)

  if (!returnValue) {
    sendCTError(device)
  }

  return returnValue
}

export const checkConsecutive = (value, device) => {
  const returnValue = validateConsecutive(value)

  if (!returnValue) {
    sendCTError(device)
  }

  return returnValue
}

export const checkUsername = (email, value, device) => {
  const returnValue = validateUserName(email, value)

  if (!returnValue) {
    sendCTError(device)
  }

  return returnValue
}

export {
  isForbidden,
  validateConsecutive,
  checkValidateThree,
  validateUserName,
}
