import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Health = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 5.5V8C8.5 8.27614 8.27614 8.5 8 8.5H5.5C5.22386 8.5 5 8.72386 5 9V11C5 11.2761 5.22386 11.5 5.5 11.5H8C8.27614 11.5 8.5 11.7239 8.5 12V14.5C8.5 14.7761 8.72386 15 9 15H11C11.2761 15 11.5 14.7761 11.5 14.5V12C11.5 11.7239 11.7239 11.5 12 11.5H14.5C14.7761 11.5 15 11.2761 15 11V9C15 8.72386 14.7761 8.5 14.5 8.5H12C11.7239 8.5 11.5 8.27614 11.5 8V5.5C11.5 5.22386 11.2761 5 11 5H9C8.72386 5 8.5 5.22386 8.5 5.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Health

Health.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Health.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
