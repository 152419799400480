import { useState } from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, useTheme, IconButton } from '@mui/material'
import { Bank, ErrorIcon } from 'components/Icons'
import FormText from '../Text'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  bankStyles: {
    '& input': {
      height: 20,
      marginTop: 18,
      padding: `${theme.space[2]}px 0px`,
    },
    '& label.Mui-focused': {
      top: 18,
    },
    '& label.MuiInputLabel-shrink': {
      fontWeight: theme.fontWeight.regular,
      top: 18,
    },
    height: 56,
  },
  inputStyles: {
    '& input': {
      height: 20,
      marginTop: 18,
      padding: `${theme.space[2]}px ${theme.space[4]}px`,
    },
    '& label.Mui-focused': {
      top: 18,
    },
    '& label.MuiInputLabel-shrink': {
      fontWeight: theme.fontWeight.regular,
      top: 18,
    },
    height: 56,
  },
}))

const ClabeForm = ({ id, error, label, value, onChange, type, disabled }) => {
  const isBankField = id === 'bankName'
  const isBankAccountNumberField = id === 'bankAccountNumber'
  const theme = useTheme()
  const [focused, setFocused] = useState(false)
  const classes = useStyles()

  const handleChange = (e) => {
    if (isBankAccountNumberField) {
      e.target.value = e.target.value.replace(/[^0-9 ]/g, '')

      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 22)
      }
    }
    onChange(e)
  }

  return (
    <FormText
      className={isBankField ? classes.bankStyles : classes.inputStyles}
      disabled={disabled}
      error={!isBankField && error}
      id={id}
      InputLabelProps={{
        shrink: focused || value,
        style: { marginLeft: isBankField && 36 },
      }}
      InputProps={{
        endAdornment: !isBankField && error && (
          <InputAdornment position="end">
            <IconButton edge="end">{!!error && <ErrorIcon />}</IconButton>
          </InputAdornment>
        ),
        startAdornment: isBankField && (
          <InputAdornment position="start" sx={{ marginRight: '12px' }}>
            <Bank />
          </InputAdornment>
        ),
      }}
      label={label}
      name={id}
      onBlur={() => setFocused(false)}
      onChange={(e) => handleChange(e)}
      onFocus={() => setFocused(true)}
      sx={
        isBankField && {
          '& .Mui-disabled': {
            WebkitTextFillColor: `${theme.palette.surface.c700} !important`,
            textOverflow: 'ellipsis',
          },

          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.surface.c200,
            borderRadius: '8px !important',
          },

          '& fieldset': {
            border: 'none',
          },
        }
      }
      type={type}
      value={value}
    />
  )
}

ClabeForm.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
}

ClabeForm.defaultProps = {
  disabled: false,
  error: false,
  label: '',
  onChange: null,
  type: 'text',
  value: '',
}

export default ClabeForm
