import SvgIcon from '@mui/material/SvgIcon'

const RadioIcon = () => (
  <SvgIcon viewBox="-3 -2 24 24">
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15073_218170)">
        <rect
          fill="white"
          height="18"
          rx="9"
          stroke="#BABBC3"
          strokeWidth="2"
          width="18"
          x="1"
          y="1"
        />
      </g>
      <defs>
        <clipPath id="clip0_15073_218170">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default RadioIcon
