import { useEffect } from 'react'
import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  Thick,
  ShopStore,
  Lock,
  MoneyBag,
  // SendAndReceive,
  // Bolt,
  // ExposureZero,
  // SecurePayments,
} from 'components/Icons'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import { ROUTE_SYNC, ROUTE_OPTIONAL_KYC, ROUTE_HOME } from 'constants/routes'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { useUserFormContext, actions } from 'components/Form/store/user'
import { startNativeBridge } from 'helpers/callNativeBridge'
import DATA from 'constants/pages/verifyIdentity.json'
import { getVersion } from 'helpers/versionHelpers'
import { getToken } from 'helpers/tokenHelpers'
import { useFlags } from 'launchdarkly-react-client-sdk'

const VerifyIdentityPage = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { userDispatch } = useUserFormContext()
  const navigate = useNavigate()
  const version = parseFloat(getVersion(), 10)
  const isWalletPath = version >= 1.2
  const { onboardingSemiMandatory } = useFlags()
  const content12 = onboardingSemiMandatory
    ? DATA.content_1_2.semiMandatory
    : DATA.content_1_2.optionalKYC
  const content = isWalletPath ? content12 : DATA.content
  const list12 = onboardingSemiMandatory
    ? DATA.list_1_2.semiMandatory
    : DATA.list_1_2.optionalKYC
  const list = isWalletPath ? list12 : DATA.list
  const buttonContinueText = isWalletPath
    ? DATA.buttonContinueText_1_2
    : DATA.buttonContinueText

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE[0],
    })
    segment.page(
      onboardingSemiMandatory ? DATA.SEGMENT.PAGE_FORCED : DATA.SEGMENT.PAGE
    )
  }, [])

  const onContinue = (e) => {
    userDispatch({ kycInProcess: true, type: actions.UPDATE_KYC_STATUS })

    startNativeBridge(e)

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.launch,
    })

    segment.track(DATA.SEGMENT.EVENTS.GO_TO_KYC)

    if (isWalletPath) {
      setTimeout(() => {
        navigate(ROUTE_SYNC)
      }, 3000)
    } else {
      navigate(ROUTE_SYNC)
    }
  }

  const handleKYCManually = () => {
    segment.track(DATA.SEGMENT.EVENTS.GO_TO_SKIP_KYC)
    navigate(ROUTE_OPTIONAL_KYC)
  }

  const renderIcon = (i) => {
    switch (i) {
      case 0:
        // return <Bolt />
        return <Lock color={theme.palette.primary.main} />
      case 1:
        return <MoneyBag color={theme.palette.primary.main} />
      case 2:
        return <ShopStore color={theme.palette.primary.main} />
      // case 3:
      //   return <SecurePayments />
      default:
        return null
    }
  }

  return (
    <Layout
      header={onboardingSemiMandatory ? DATA.headerSemiMandatory : DATA.header}
      skipCallback={handleKYCManually}
    >
      <StepWrapper
        content={content}
        controls={[
          <Button
            color="orange"
            onClick={onContinue}
            size="full-width"
            sx={{
              height: '48px',
              width: '100%',
            }}
            variant="primary-form"
          >
            {buttonContinueText}
          </Button>,
        ]}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        imageSize={{ height: 96, width: 96 }}
        sx={{ mt: 2 }}
        titleStyles={{ marginX: '16px', mt: 3 }}
      >
        <Stack>
          {isWalletPath ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ mb: 4, mt: -1 }}
            >
              <Chip
                label={
                  onboardingSemiMandatory
                    ? ''
                    : DATA.content_1_2.optionalKYC.free_label
                }
                sx={{
                  background: theme.palette.success.main,
                  color: theme.palette.success.light,
                  fontSize: '12px',
                  fontWeight: theme.fontWeight.semibold,
                  height: 'auto',
                }}
              />
            </Stack>
          ) : null}
          {list.title && (
            <Typography fontWeight={theme.fontWeight.semibold} variant="body1">
              {list.title}
            </Typography>
          )}
          <List
            aria-label="Requisites"
            sx={{ padding: 1.5, pb: 2, pt: 0 }}
            variant="compact"
          >
            {list.items.map((item, idx) => (
              <ListItem
                key={item}
                sx={{
                  borderBottom:
                    idx !== list.items.length - 1 &&
                    '1px solid #F2F4F7 !important',
                  pb: '16px !important',
                  pt: '16px !important',
                }}
              >
                <ListItemIcon
                  sx={{
                    alignItems: 'center',
                    background:
                      !isWalletPath && theme.palette.success.checkmark,
                    borderRadius: '100px',
                    display: 'flex',
                    height: '1rem',
                    justifyContent: 'center',
                    padding: 2,
                    width: '1rem',
                  }}
                >
                  {isWalletPath ? (
                    renderIcon(idx)
                  ) : (
                    <Thick size={1.5} sx={{ mt: '-1px' }} />
                  )}
                </ListItemIcon>
                <Typography
                  dangerouslySetInnerHTML={{ __html: item }}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    fontWeight: theme.fontWeight.semibold,
                  }}
                  variant="body2"
                />
              </ListItem>
            ))}
          </List>
          {onboardingSemiMandatory && (
            <Chip
              icon={<Lock color={theme.palette.success.main} size={0.9} />}
              label={DATA.content_1_2.semiMandatory.chip}
              sx={{
                background: theme.palette.success.light,
                color: theme.palette.success.main,
                fontSize: '12px',
                fontWeight: theme.fontWeight.semibold,
                height: 16,
                margin: 'auto',
                width: 240,
              }}
            />
          )}
          {isWalletPath ? (
            <Typography align="center" variant="body0">
              {onboardingSemiMandatory
                ? ''
                : DATA.content_1_2.optionalKYC.disclaimer}
            </Typography>
          ) : null}
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default VerifyIdentityPage
