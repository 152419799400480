import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const MobileX = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 54 33">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_23484_10749"
        maskUnits="userSpaceOnUse"
        width="24"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_23484_10749)">
        <path
          d="M14.4 16L13 14.6L15.6 12L13 9.4L14.4 8L17 10.6L19.6 8L21 9.4L18.4 12L21 14.6L19.6 16L17 13.4L14.4 16ZM6 23C5.45 23 4.97917 22.8042 4.5875 22.4125C4.19583 22.0208 4 21.55 4 21V3C4 2.45 4.19583 1.97917 4.5875 1.5875C4.97917 1.19583 5.45 1 6 1H16C16.55 1 17.0208 1.19583 17.4125 1.5875C17.8042 1.97917 18 2.45 18 3V7H16V6H6V18H16V17H18V21C18 21.55 17.8042 22.0208 17.4125 22.4125C17.0208 22.8042 16.55 23 16 23H6ZM6 20V21H16V20H6ZM6 4H16V3H6V4Z"
          fill={color}
        />
      </g>
    </svg>
  </SvgIcon>
)

export default MobileX

MobileX.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

MobileX.defaultProps = {
  color: (theme) => theme.palette.text.primary,
  size: 1.5,
}
