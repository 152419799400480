export const MIN_LENGTH = 8
export const MAX_MATCHES = 3

export const RULES = [
  {
    match: /.{8,}$/,
    title: `${MIN_LENGTH} caracteres o más`,
  },
  {
    match: /[a-z]/,
    title: '1 minúscula',
  },
  {
    match: /[A-Z]/,
    title: '1 mayúscula',
  },
  {
    match: /\d/,
    title: '1 número',
  },
]

export const FORBIDDEN = ['clippay', 'clip']
