import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const AddProduct = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="20"
      viewBox="0 0 18 20"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00003 19.001C5.34319 19.001 4.08821 19.001 2.99995 19.001C1.89538 19.001 1.00003 18.1055 1.00003 17.001L1 3.00098C0.999997 1.89641 1.89543 1.00098 3 1.00098L4.5 1.00098M13 8.00097C13 7.30326 13 4.81217 13 2.99991C13.0001 1.89533 12.1046 1.00098 11 1.00098L9.5 1.00098M4.5 1.00098V2.50098C4.5 3.05326 4.94771 3.50098 5.5 3.50098H8.5C9.05228 3.50098 9.5 3.05326 9.5 2.50098V1.00098M4.5 1.00098L9.5 1.00098M12 15.501H14M11.5 12.751C11.5 12.751 9 12.751 9 15.501C9 18.251 11.5 18.251 11.5 18.251M14.5 12.751C14.5 12.751 17 12.751 17 15.501C17 18.251 14.5 18.251 14.5 18.251"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default AddProduct

AddProduct.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

AddProduct.defaultProps = {
  color: theme.palette.primary.main,
  size: 1.5,
}
