import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Trophy = ({ size, color }) => (
  <SvgIcon
    className="trophy"
    sx={{ fontSize: `${size}rem` }}
    viewBox="0 0 24 24"
  >
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16V20M12 16C12 16 16 15 16 11M12 16C12 16 8 15 8 11M10 20H14M16 6V4H8V6M16 6H19V8C19 11 16 11 16 11M16 6V11M8 6H5V8C5 11 8 11 8 11M8 6V11"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Trophy

Trophy.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Trophy.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
