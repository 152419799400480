import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Health = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
      <path
        clipRule="evenodd"
        d="M6 21H4C3.448 21 3 20.552 3 20V14C3 13.448 3.448 13 4 13H6C6.552 13 7 13.448 7 14V20C7 20.552 6.552 21 6 21Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13 16.9991H15.333C15.766 16.9991 16.187 16.8591 16.533 16.5991L18.949 14.7871C19.552 14.3351 20.396 14.3951 20.929 14.9281V14.9281C21.521 15.5201 21.521 16.4791 20.929 17.0701L18.856 19.1431C18.298 19.7011 17.586 20.0821 16.812 20.2371L13.882 20.8231C13.301 20.9391 12.702 20.9251 12.127 20.7811L9.477 20.1191C9.16 20.0391 8.835 19.9991 8.508 19.9991H7"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13 17H14.485C15.322 17 16 16.322 16 15.485V15.182C16 14.487 15.527 13.881 14.853 13.713L12.561 13.14C12.188 13.047 11.806 13 11.422 13V13C10.495 13 9.588 13.274 8.817 13.789L7 15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        clipRule="evenodd"
        d="M9.667 10H18.334C19.254 10 20 9.254 20 8.333V3.667C20 2.746 19.254 2 18.333 2H9.666C8.746 2 8 2.746 8 3.667V8.334C8 9.254 8.746 10 9.667 10Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M14 4.1V3.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.04"
      />
      <path
        d="M14 7.69922V8.29922"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.04"
      />
      <path
        d="M12.838 7.26966C12.9932 7.52406 13.38 7.70006 13.7008 7.70006H14.0008H14.3584C14.824 7.70006 15.2008 7.32246 15.2008 6.85766C15.2008 6.47126 14.9376 6.13446 14.5632 6.04006L13.4384 5.75766C13.064 5.66326 12.8008 5.32646 12.8008 4.94006C12.8008 4.47446 13.1784 4.09766 13.6432 4.09766H14.0008H14.3008C14.6208 4.09766 14.8856 4.27286 15.0408 4.52646"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.04"
      />
    </svg>
  </SvgIcon>
)

export default Health

Health.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Health.defaultProps = {
  color: theme.palette.primary.main,
  size: 1.5,
}
