import React, { useEffect, useRef, useState } from 'react'

export const CountDown = ({
  format = '00:00:00',
  onComplete,
  timeout = 150000,
}) => {
  const [time, setTime] = useState(timeout)
  const intervalRef = useRef()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(intervalRef.current)
          onComplete()
          return 0
        }
        return prevTime - 1000
      })
    }, 1000)

    return () => clearInterval(intervalRef.current)
  }, [])

  useEffect(() => {
    setTime(timeout)
  }, [timeout])

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    if (format === '00:00:00') {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:${String(seconds).padStart(2, '0')}`
    }
    if (format === '00:00') {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`
    }
    if (format === '00') {
      return `${String(seconds).padStart(2, '0')}`
    }
    // Default to full format if invalid format is provided
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}:${String(seconds).padStart(2, '0')}`
  }

  return <>{formatTime(time)}</>
}

export default CountDown
