import PropTypes from 'prop-types'
import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

const RenderRadio = (props) => {
  const {
    checked,
    handleChange,
    id,
    index,
    item,
    radioProps,
    labelHeight,
    labelRef,
    sx,
  } = props
  const theme = useTheme()

  return (
    <>
      <ListItemText
        ref={labelRef}
        primary={
          <Typography
            sx={{ fontWeight: theme.fontWeight.semibold }}
            variant="body0"
          >
            {item.label}
          </Typography>
        }
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: `${labelHeight}px`,
        }}
      />
      <ListItemIcon sx={{ minWidth: 0 }}>
        <Radio
          checked={checked}
          inputProps={{ 'aria-label': item.label }}
          name={id}
          onChange={() => handleChange({ ...item, index })}
          sx={{ ...sx }}
          value={item.value}
          {...radioProps}
        />
      </ListItemIcon>
    </>
  )
}

export default RenderRadio

RenderRadio.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  labelHeight: PropTypes.number,
  labelRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
  radioProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
}

RenderRadio.defaultProps = {
  checked: false,
  id: null,
  labelHeight: null,
  labelRef: null,
  radioProps: null,
  sx: null,
}
