import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const TickSuccess = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="20"
      viewBox="-2 -4 18 18"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666016 5.66716L3.99935 9.00049L11.3327 1.00049"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25097"
      />
    </svg>
  </SvgIcon>
)

export default TickSuccess

TickSuccess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

TickSuccess.defaultProps = {
  color: '#2D9F70',
  size: 1.5,
}
