import PropTypes from 'prop-types'
import { memo } from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import DATA from './constData.json'

const TYPES = DATA.types

const renderer = ({ hours, minutes, seconds, type }) => {
  switch (type) {
    case TYPES[0]:
      return (
        <>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </>
      )
    case TYPES[1]:
      return (
        <>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </>
      )
    case TYPES[2]:
      return <>{zeroPad(seconds)}</>
    case TYPES[3]:
      return seconds
    default:
      return (
        <>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </>
      )
  }
}

const CountDownMomoized = ({ timeout, onComplete, format }) => (
  <Countdown
    date={Date.now() + timeout}
    onComplete={onComplete}
    renderer={({ minutes, seconds, hours }) => {
      const type = typeof format === 'number' ? TYPES[format] : format

      return renderer({ hours, minutes, seconds, type })
    }}
  />
)

const CountDown = memo(CountDownMomoized)

export default CountDown

CountDownMomoized.propTypes = {
  format: PropTypes.oneOf(['00:00:00', '00:00', '00', '0', 0, 1, 2, 3]),
  onComplete: PropTypes.func,
  timeout: PropTypes.number.isRequired,
}

CountDownMomoized.defaultProps = {
  format: TYPES[0],
  onComplete: null,
}
