import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Associations = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89716 19H5.83184C6.82343 19 7.80211 19.2056 8.74385 19.5243C9.45805 19.7659 10.2715 20 10.7429 20C11.8315 20 13.5942 19.5678 14.6742 19.2717C15.2938 19.1018 15.8516 18.7565 16.3051 18.2911C17.1884 17.3844 18.026 16.4543 18.5372 15.9296C19.8262 14.6067 18.1661 12.7007 16.691 13.7821L13.6658 16H10.7429L12.1186 15.9296C12.9853 15.8853 13.6658 15.1512 13.6658 14.2606C13.6658 13.5144 13.1844 12.8584 12.4796 12.6758C11.3526 12.3837 9.69961 12 8.79432 12C7.33289 12 4.89716 14 4.89716 14M4.89716 19V14M4.89716 19C4.89716 19.5523 4.46096 20 3.92287 20H1.97429C1.4362 20 1 19.5523 1 19V13C1 12.4477 1.4362 12 1.97429 12H3.92287C4.46096 12 4.89716 12.4477 4.89716 13V14M12.353 3.56994C11.9616 2.36836 10.8322 1.5 9.5 1.5C7.84315 1.5 6.5 2.84315 6.5 4.5C6.5 6.15685 7.84315 7.5 9.5 7.5C9.72212 7.5 9.9386 7.47586 10.147 7.43006M12.353 3.56994C12.5614 3.52414 12.7779 3.5 13 3.5C14.6569 3.5 16 4.84315 16 6.5C16 8.15685 14.6569 9.5 13 9.5C11.6678 9.5 10.5384 8.63164 10.147 7.43006M12.353 3.56994C11.0072 3.86576 10 5.06526 10 6.5C10 6.82465 10.0516 7.13725 10.147 7.43006"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Associations

Associations.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Associations.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
