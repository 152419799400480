import { useTheme } from '@mui/styles'
import Layout from 'components/Layout'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import {
  ENV,
  ROUTE_API_ACTIVATE,
  ROUTE_API_STATUS,
  ROUTE_INSTALLMENTS,
  ROUTE_HOME,
  ROUTE_API_USER,
  ROUTE_FIRST_SALE,
  ROUTE_CLABE,
  ROUTE_FIRST_TRANSACTION,
} from 'constants/routes'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getToken } from 'helpers/tokenHelpers'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchInfo, postInfo } from 'helpers/fetchUtils'
import Button from 'components/Button'
import StepWrapper from 'components/StepWrapper'
import DATA from 'constants/pages/accountActivation.json'
import { useGlobalContext } from 'store/global'
import isKycAccepted from 'helpers/kycHelper'
import { useFlags } from 'launchdarkly-react-client-sdk'

const baseURL = `${ENV.REACT_APP_API_URL}${ROUTE_API_USER}`

const AccountActivationPage = () => {
  const theme = useTheme()
  const source = `${ENV.REACT_APP_API_URL}${ROUTE_API_STATUS}`
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { device } = useGlobalContext()
  const { onboardingEnableFirstTransaction } = useFlags()

  const kycIsCompleted = (onboardingData) => isKycAccepted(onboardingData)

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }

    fetchInfo(source)
      .then(({ data }) => {
        if (loading) {
          const payload = {
            has_accepted_clip: true,
          }

          const isKycCompleted = kycIsCompleted(data)

          if (data?.version === '1.2') {
            payload.has_accepted_digital_account = isKycCompleted
          }

          let route = ROUTE_FIRST_SALE

          postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_ACTIVATE}`, payload)
            .then(() => {
              if (onboardingEnableFirstTransaction) {
                navigate(ROUTE_FIRST_TRANSACTION)

                return
              }

              fetchInfo(baseURL)
                .then(({ data: info }) => {
                  const isIndustryInGroup = DATA.activity_values.some(
                    ({ value }) => value === info.business.industry_id
                  )

                  route = ROUTE_CLABE

                  if (isIndustryInGroup) {
                    route = ROUTE_INSTALLMENTS
                  } else if (data?.version === '1.2' && isKycCompleted) {
                    route = ROUTE_FIRST_SALE
                  }
                })
                .finally(() => {
                  navigate(route)
                })
            })
            .catch(() => {
              cleverTap.event(DATA.CLEVERTAP.EVENTS, {
                ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
                ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE,
                OS: device.type,
              })
              segment.track(DATA.SEGMENT.EVENTS.ERROR_ACTIVATION)
              setLoading(false)
            })
        }
      })
      .catch(() => {
        cleverTap.event(DATA.CLEVERTAP.EVENTS, {
          ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
          ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE,
          OS: device.type,
        })
        segment.track(DATA.SEGMENT.EVENTS.ERROR_STATUS)
        setLoading(false)
      })
  }, [loading])

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        contentCentered
        controls={[
          <Stack>
            <Button
              color="orange"
              onClick={() => {
                setLoading(true)
              }}
              size="full-width"
              sx={{
                height: '48px',
              }}
              variant="primary-form"
            >
              {DATA.retry}
            </Button>
          </Stack>,
        ]}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
      />
      {loading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            background: theme.backgrounds[1],
            height: '100%',
            left: 0,
            padding: 4,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: '2000',
          }}
        >
          <CircularProgress color="primary" size="32px" />

          <Typography
            align="center"
            fontWeight={theme.fontWeight.bold}
            variant="body4"
          >
            {DATA.title}
          </Typography>

          <Typography color="text.primary" variant="body2">
            {DATA.patience}
          </Typography>
        </Stack>
      ) : null}
    </Layout>
  )
}

export default AccountActivationPage
