import SvgIcon from '@mui/material/SvgIcon'

const Icon = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_7603_480798"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="25"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" x="0.5" />
      </mask>
      <g mask="url(#mask0_7603_480798)">
        <path
          d="M11.05 18.2003L16.225 12.0003H12.225L12.95 6.32533L8.32497 13.0003H11.8L11.05 18.2003ZM9.49997 15.0003H6.39997C5.99997 15.0003 5.70414 14.8212 5.51247 14.4628C5.3208 14.1045 5.34164 13.7587 5.57497 13.4253L13.05 2.67533C13.2166 2.442 13.4333 2.2795 13.7 2.18783C13.9666 2.09616 14.2416 2.10033 14.525 2.20033C14.8083 2.30033 15.0166 2.47533 15.15 2.72533C15.2833 2.97533 15.3333 3.242 15.3 3.52533L14.5 10.0003H18.375C18.8083 10.0003 19.1125 10.192 19.2875 10.5753C19.4625 10.9587 19.4083 11.317 19.125 11.6503L10.9 21.5003C10.7166 21.717 10.4916 21.8587 10.225 21.9253C9.9583 21.992 9.69997 21.967 9.44997 21.8503C9.19997 21.7337 9.00414 21.5545 8.86247 21.3128C8.7208 21.0712 8.66664 20.8087 8.69997 20.5253L9.49997 15.0003Z"
          fill="#FC4C02"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Icon
