import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
  },
  stepper: {
    '& .MuiList-root': {
      listStyleType: 'disc',
      marginLeft: `${theme.space[4]}px`,
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& .MuiListItem-root': {
      fontSize: 12,
      lineHeight: '18px',
      padding: `${theme.space[1]}px`,
    },
    '& .MuiStep-root': {
      '&.last': {
        alignItems: 'flex-end',
        borderLeft: 'none',
      },
      borderLeftColor: '#bdbdbd',
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
    },
    '& .MuiStepConnector-root': {
      '& .MuiStepConnector-line': {
        minHeight: 16,
      },
      marginLeft: 0,
    },
    '& .MuiStepContent-root': {
      '& .MuiButton-root': {
        marginTop: `${theme.space[3]}px`,
      },
      borderLeft: 'none',
    },
    '& .MuiStepLabel-root': {
      alignItems: 'flex-start',
      marginLeft: `-${theme.space[5]}px`,
      paddingBottom: 0,
      paddingTop: 0,
    },
    marginLeft: `${theme.space[5]}px`,
  },
}))

export default useStyles
