import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Thick = ({ color, size, sx }) => (
  <SvgIcon sx={{ fontSize: `${size}rem`, ...sx }}>
    <svg
      fill="none"
      height="19"
      viewBox="0 0 16 19"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66666 11.1662L5.99999 14.4995L13.3333 6.49951"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Thick

Thick.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.shape(),
}

Thick.defaultProps = {
  color: theme.palette.white.main,
  size: 1.5,
  sx: null,
}
