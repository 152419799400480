import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const ShareLink = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 20 20">
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3161 14.66C13.8087 13.6769 14.8255 13.002 16 13.002C17.6569 13.002 19 14.3451 19 16.002C19 17.6588 17.6569 19.002 16 19.002C14.3431 19.002 13 17.6588 13 16.002C13 15.5196 13.1138 15.0638 13.3161 14.66ZM13.3161 14.66L6.68387 11.3439M13.3161 5.34389C13.8087 6.32704 14.8255 7.00195 16 7.00195C17.6569 7.00195 19 5.65881 19 4.00195C19 2.3451 17.6569 1.00195 16 1.00195C14.3431 1.00195 13 2.3451 13 4.00195C13 4.48432 13.1138 4.9401 13.3161 5.34389ZM13.3161 5.34389L6.68387 8.66002M6.68387 8.66002C6.19134 7.67687 5.17449 7.00195 4 7.00195C2.34315 7.00195 1 8.3451 1 10.002C1 11.6588 2.34315 13.002 4 13.002C5.17449 13.002 6.19134 12.327 6.68387 11.3439M6.68387 8.66002C6.88616 9.06381 7 9.51958 7 10.002C7 10.4843 6.88616 10.9401 6.68387 11.3439"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default ShareLink

ShareLink.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

ShareLink.defaultProps = {
  color: theme.palette.white.main,
  size: 1.5,
}
