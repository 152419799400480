import { useEffect } from 'react'
import { Route, useNavigate } from 'react-router-dom'
import SlideRoutes from 'react-slide-routes'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from 'components/GlobalStyles'
import theme from 'theme'
import { UserFormProvider } from 'components/Form/store/user'
import { BusinessFormProvider } from 'components/Form/store/business'
import { GlobalProvider } from 'store/global'
import useCheckStep from 'hooks/useSteps'

// import routes
import {
  ROUTE_HOME,
  ROUTE_ACCOUNT_CREATION,
  ROUTE_ACCOUNT_ACTIVATION,
  ROUTE_PASSWORD_CONFIRM,
  ROUTE_PASSWORD_SETUP,
  ROUTE_PHONE,
  ROUTE_VALIDATION,
  ROUTE_VERIFY,
  ROUTE_BUSINESS_INFO,
  ROUTE_BUSINESS_ADDRESS,
  ROUTE_PROFILE_SETUP,
  ROUTE_BUSINESS_ACTIVITY,
  ROUTE_BUSINESS_ADDRESS_CHOOSE,
  ROUTE_FIRST_SALE,
  ROUTE_LOGIN,
  ROUTE_TAC,
  ROUTE_KYC_ERROR,
  ROUTE_SYNC,
  ROUTE_OPTIONAL_KYC,
  ROUTE_PERSONAL_INFO,
  ROUTE_FINANCIAL_INFO,
  ROUTE_INSTALLMENTS,
  ROUTE_CLABE,
  ROUTE_FIRST_TRANSACTION,
  ROUTE_FIRST_TRANSACTION_READER,
  ROUTE_FIRST_TRANSACTION_TUTORIAL,
  ROUTE_FIRST_TRANSACTION_SUCCESS,
  ROUTE_ASSISTED,
  ROUTE_ASSISTED_EDIT_UI,
  ROUTE_ASSISTED_EDIT_BI,
  ROUTE_VALIDATION_PHONE,
  ROUTE_WALLET_PROPOSITION,
} from 'constants/routes'

// page components import
import Home from 'pages/home'
import PasswordFormPage from 'pages/passwordForm'
import PasswordConfirmationPage from 'pages/passwordConfirmation'
import VerifyIdentityPage from 'pages/verifyIdentity'
import PhoneFormPage from 'pages/phoneForm'
import ValidationFormPage from 'pages/validationForm'
import ValidationFormPhonePage from 'pages/validationFormPhone'
import BusinessInfoPage from 'pages/businessInfo'
import BusinessAddressPage from 'pages/businessAddress'
import ProfileSetupPage from 'pages/profileSetup'
import BusinessActivityPage from 'pages/businessActivity'
import BusinessAddressChoosePage from 'pages/businessAddressChoose'
import LoginPage from 'pages/login'
import FirstSalePage from 'pages/firstSale'
import TacPage from 'pages/tac'
import KYCError from 'pages/kycError'
import SyncPage from 'pages/sync'
import OptionalKyc from 'pages/optionalKyc'
import NotFound from 'pages/notFound'
import Installments from 'pages/installments'
import FirstTransaction from 'pages/firstTransaction'
import FirstTransactionReader from 'pages/firstTransactionReader'
import FirstTransactionTutorial from 'pages/firstTransactionTutorial'
import FirstTransactionSuccess from 'pages/firstTransactionSuccess'
import SITE from 'constants/site.json'
import FinancialInformation from 'pages/financialInformation'
import PersonalInformation from 'pages/personalInformation'
import AccountPage from 'pages/account'
import AccountActivationPage from 'pages/accountActivation'
import LoadingApp from 'components/LoadingApp'
import DebugConsole from 'components/DebugConsole'
import { getToken } from './helpers/tokenHelpers'
import ClabeFormPage from 'pages/clabeForm'
import AssistedPage from 'pages/assisted'
import AssistedEditPiPage from 'pages/assistedEditPI'
import AssistedEditBiPage from 'pages/assistedEditBI'
import WalletValueProposition from 'pages/walletValueProposition'

const withPermissionCheck = (Component, routePath, fallbackPath) => () => {
  const navigate = useNavigate()
  const { isValidStep, loading: isValidatingRoute } = useCheckStep(routePath)

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }

    if (!isValidatingRoute) {
      if (!isValidStep) {
        navigate(fallbackPath)
      }
    }
  }, [isValidatingRoute])

  if (isValidatingRoute) {
    // Render a loading component while the permission check is in progress
    return <LoadingApp />
  }

  // Render the component if the user has permission
  return isValidStep ? <Component /> : null
}

const PhoneFormRoute = withPermissionCheck(
  PhoneFormPage,
  ROUTE_PHONE,
  ROUTE_PROFILE_SETUP
)
const SyncPageRoute = withPermissionCheck(
  SyncPage,
  ROUTE_SYNC,
  ROUTE_PROFILE_SETUP
)
const BusinessInfoPageRoute = withPermissionCheck(
  BusinessInfoPage,
  ROUTE_BUSINESS_INFO,
  ROUTE_PROFILE_SETUP
)
const BusinessActivityPageRoute = withPermissionCheck(
  BusinessActivityPage,
  ROUTE_BUSINESS_ACTIVITY,
  ROUTE_PROFILE_SETUP
)
const BusinessAddressChoosePageRoute = withPermissionCheck(
  BusinessAddressChoosePage,
  ROUTE_BUSINESS_ADDRESS_CHOOSE,
  ROUTE_PROFILE_SETUP
)
const BusinessAddressPageRoute = withPermissionCheck(
  BusinessAddressPage,
  ROUTE_BUSINESS_ADDRESS,
  ROUTE_PROFILE_SETUP
)
const VerifyIdentityPageRoute = withPermissionCheck(
  VerifyIdentityPage,
  ROUTE_VERIFY,
  ROUTE_PROFILE_SETUP
)
// const InstallmentsRoute = withPermissionCheck(
//   Installments,
//   ROUTE_INSTALLMENTS,
//   ROUTE_HOME
// )
const FirstSalePageRoute = withPermissionCheck(
  FirstSalePage,
  ROUTE_FIRST_SALE,
  ROUTE_PROFILE_SETUP
)
const KYCErrorRoute = withPermissionCheck(
  KYCError,
  ROUTE_KYC_ERROR,
  ROUTE_PROFILE_SETUP
)
const OptionalKycRoute = withPermissionCheck(
  OptionalKyc,
  ROUTE_OPTIONAL_KYC,
  ROUTE_PROFILE_SETUP
)
const FinancialInformationRoute = withPermissionCheck(
  FinancialInformation,
  ROUTE_FINANCIAL_INFO,
  ROUTE_PROFILE_SETUP
)
// const PersonalInformationRoute = withPermissionCheck(
//   PersonalInformation,
//   ROUTE_PERSONAL_INFO,
//   ROUTE_PROFILE_SETUP
// )
const TacPageRoute = withPermissionCheck(
  TacPage,
  ROUTE_TAC,
  ROUTE_PROFILE_SETUP
)
const AccountActivationPageRoute = withPermissionCheck(
  AccountActivationPage,
  ROUTE_ACCOUNT_ACTIVATION,
  ROUTE_PROFILE_SETUP
)
const ProfileSetupPageRoute = withPermissionCheck(
  ProfileSetupPage,
  ROUTE_PROFILE_SETUP,
  ROUTE_PROFILE_SETUP
)

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <GlobalStyles />
      <GlobalProvider>
        <UserFormProvider>
          <BusinessFormProvider>
            <ThemeProvider theme={theme}>
              <DebugConsole />
              <CssBaseline />
              <SlideRoutes duration={SITE.PAGE_TRANSITION_SPEED}>
                <Route element={<Home />} path={ROUTE_HOME} />
                <Route
                  element={<ProfileSetupPageRoute />}
                  path={ROUTE_PROFILE_SETUP}
                />
                <Route
                  element={<PasswordFormPage />}
                  path={ROUTE_PASSWORD_SETUP}
                />
                <Route
                  element={<AccountPage />}
                  path={ROUTE_ACCOUNT_CREATION}
                />
                <Route
                  element={<PasswordConfirmationPage />}
                  path={ROUTE_PASSWORD_CONFIRM}
                />
                <Route
                  element={<ValidationFormPage />}
                  path={ROUTE_VALIDATION}
                />
                <Route element={<PhoneFormRoute />} path={ROUTE_PHONE} />
                <Route
                  element={<ValidationFormPhonePage />}
                  path={ROUTE_VALIDATION_PHONE}
                />
                <Route element={<SyncPageRoute />} path={ROUTE_SYNC} />
                <Route
                  element={<BusinessActivityPageRoute />}
                  path={ROUTE_BUSINESS_ACTIVITY}
                />
                <Route
                  element={<BusinessInfoPageRoute />}
                  path={ROUTE_BUSINESS_INFO}
                />
                <Route
                  element={<BusinessAddressChoosePageRoute />}
                  path={ROUTE_BUSINESS_ADDRESS_CHOOSE}
                />
                <Route
                  element={<BusinessAddressPageRoute />}
                  path={ROUTE_BUSINESS_ADDRESS}
                />
                <Route
                  element={<VerifyIdentityPageRoute />}
                  path={ROUTE_VERIFY}
                />
                <Route element={<LoginPage />} path={ROUTE_LOGIN} />
                <Route element={<KYCErrorRoute />} path={ROUTE_KYC_ERROR} />
                <Route
                  element={<OptionalKycRoute />}
                  path={ROUTE_OPTIONAL_KYC}
                />
                <Route
                  element={<FinancialInformationRoute />}
                  path={ROUTE_FINANCIAL_INFO}
                />
                <Route
                  element={<PersonalInformation />}
                  path={ROUTE_PERSONAL_INFO}
                />
                <Route
                  element={<WalletValueProposition />}
                  path={ROUTE_WALLET_PROPOSITION}
                />
                <Route element={<TacPageRoute />} path={ROUTE_TAC} />
                <Route element={<AssistedPage />} path={ROUTE_ASSISTED} />
                <Route
                  element={<AccountActivationPageRoute />}
                  path={ROUTE_ACCOUNT_ACTIVATION}
                />
                <Route element={<Installments />} path={ROUTE_INSTALLMENTS} />
                <Route element={<ClabeFormPage />} path={ROUTE_CLABE} />
                <Route
                  element={<FirstTransaction />}
                  path={ROUTE_FIRST_TRANSACTION}
                />
                <Route
                  element={<FirstTransactionReader />}
                  path={ROUTE_FIRST_TRANSACTION_READER}
                />
                <Route
                  element={<FirstTransactionTutorial />}
                  path={ROUTE_FIRST_TRANSACTION_TUTORIAL}
                />
                <Route
                  element={<FirstTransactionSuccess />}
                  path={ROUTE_FIRST_TRANSACTION_SUCCESS}
                />
                <Route
                  element={<FirstSalePageRoute />}
                  path={ROUTE_FIRST_SALE}
                />

                <Route
                  element={<AssistedEditPiPage />}
                  path={ROUTE_ASSISTED_EDIT_UI}
                />
                <Route
                  element={<AssistedEditBiPage />}
                  path={ROUTE_ASSISTED_EDIT_BI}
                />

                {/* NotFound must be the last route */}
                <Route element={<NotFound />} path="*" />
              </SlideRoutes>
            </ThemeProvider>
          </BusinessFormProvider>
        </UserFormProvider>
      </GlobalProvider>
    </StyledEngineProvider>
  )
}

export default App
