import SvgIcon from '@mui/material/SvgIcon'

const Icon = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_7603_480813"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="25"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" x="0.5" />
      </mask>
      <g mask="url(#mask0_7603_480813)">
        <path
          d="M6.5 22C5.95 22 5.47917 21.8042 5.0875 21.4125C4.69583 21.0208 4.5 20.55 4.5 20V10C4.5 9.45 4.69583 8.97917 5.0875 8.5875C5.47917 8.19583 5.95 8 6.5 8H7.5V6C7.5 4.61667 7.9875 3.4375 8.9625 2.4625C9.9375 1.4875 11.1167 1 12.5 1C13.8833 1 15.0625 1.4875 16.0375 2.4625C17.0125 3.4375 17.5 4.61667 17.5 6V8H18.5C19.05 8 19.5208 8.19583 19.9125 8.5875C20.3042 8.97917 20.5 9.45 20.5 10V20C20.5 20.55 20.3042 21.0208 19.9125 21.4125C19.5208 21.8042 19.05 22 18.5 22H6.5ZM6.5 20H18.5V10H6.5V20ZM12.5 17C13.05 17 13.5208 16.8042 13.9125 16.4125C14.3042 16.0208 14.5 15.55 14.5 15C14.5 14.45 14.3042 13.9792 13.9125 13.5875C13.5208 13.1958 13.05 13 12.5 13C11.95 13 11.4792 13.1958 11.0875 13.5875C10.6958 13.9792 10.5 14.45 10.5 15C10.5 15.55 10.6958 16.0208 11.0875 16.4125C11.4792 16.8042 11.95 17 12.5 17ZM9.5 8H15.5V6C15.5 5.16667 15.2083 4.45833 14.625 3.875C14.0417 3.29167 13.3333 3 12.5 3C11.6667 3 10.9583 3.29167 10.375 3.875C9.79167 4.45833 9.5 5.16667 9.5 6V8Z"
          fill="#FC4C02"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Icon
