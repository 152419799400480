import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'
import generateKey from 'utils/generateKey'
import Image from 'components/Image'
import DATA from 'constants/pages/home.json'
import 'swiper/swiper.min.css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { useState, memo } from 'react'

import Header from 'components/Header'
import HomePageHeader from 'components/Renders/HomePageHeaderContent'
import Box from '@mui/material/Box'
import useStyles from './styles'

const commonSettings = {
  autoplay: {
    delay: 9000,
    disableOnInteraction: false,
  },
  direction: 'horizontal',
  loop: true,
}

const settings = {
  ...commonSettings,
  allowTouchMove: false,
  effect: 'fade',
  height: '100vh',
  modules: [EffectFade, Pagination],
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    renderBullet(index, className) {
      return `<span class="${className}" id="bar-${index}"></span>`
    },
  },
  speed: 750,
}

const settingsTextSlider = {
  ...commonSettings,
  allowTouchMove: true,
  modules: [Autoplay],
  speed: 500,
}

const RenderSwiperProgressBar = memo(({ classes }) => (
  <Header
    data={{ title: <HomePageHeader /> }}
    sx={{ background: 'transparent' }}
  >
    <Box className={classes.bulletContainer}>
      <div className="swiper-pagination" />
    </Box>
  </Header>
))

const HomePageSlider = () => {
  const theme = useTheme()
  const classes = useStyles()
  const [swiper, setSwiper] = useState(null)

  const handleVisitedSlides = ({ realIndex }) => {
    const previousElment = document.getElementById(`bar-${realIndex - 1}`)
    const bars = Array.from({ length: 3 }, (_, i) =>
      document.getElementById(`bar-${i}`)
    )
    const [bar0, bar1, bar2] = bars
    const progressBarsAreRendered = bar0 && bar1 && bar2
    const VISITED = 'visited'
    const ACTIVE = 'swiper-pagination-bullet-active'

    if (previousElment) {
      previousElment.classList.add(VISITED)
    }

    if (progressBarsAreRendered) {
      if (realIndex - 1 < 0) {
        bars.forEach((bar) => bar.classList.remove(VISITED, ACTIVE))
      }

      if (realIndex === 0) {
        bar1.classList.remove(VISITED)
      }

      if (realIndex === 1) {
        bar0.classList.add(VISITED)
        bar1.classList.remove(VISITED)
        bar2.classList.remove(ACTIVE)
      }

      if (realIndex === 2) {
        bar0.classList.add(VISITED)
        bar1.classList.add(VISITED)
      }
    }
  }

  const handleTextSliderChange = ({ realIndex }) => {
    if (swiper.destroyed) return
    swiper.slideTo(realIndex, 1000)
  }

  return (
    <>
      <Swiper {...settings} className={classes.swiperRoot} onSwiper={setSwiper}>
        {DATA.app.SLIDER.map((slider, idx) => (
          <SwiperSlide key={generateKey(idx)}>
            <img
              alt={slider.background}
              className={classes.swiperImage}
              src={`/images/${slider.background}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        {...settingsTextSlider}
        className={classes.textSlider}
        onSlideChange={handleVisitedSlides}
        onSlideChangeTransitionEnd={handleTextSliderChange}
      >
        {DATA.app.SLIDER.map((slider, idx) => (
          <SwiperSlide key={generateKey(idx)}>
            <div className={classes.textSliderContent}>
              <Typography color="white.main" sx={{ mb: 0 }} variant="hl">
                {slider.caption}
              </Typography>
              <br />
              <Typography
                color="white.main"
                sx={{ fontWeight: theme.fontWeight.regular }}
                variant="hl"
              >
                {slider.text}
              </Typography>

              <Stack className={classes.stackImages}>
                {slider.images.map((img) => (
                  <Image
                    key={img.src}
                    alt="icon"
                    height={img.height}
                    src={`/images/${img.src}`}
                    width={img.width}
                  />
                ))}
              </Stack>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <RenderSwiperProgressBar classes={classes} />
    </>
  )
}

export default HomePageSlider
