import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const TickSuccess = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default TickSuccess

TickSuccess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

TickSuccess.defaultProps = {
  color: '#D32121',
  size: 1.5,
}
