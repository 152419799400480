import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Computer = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 24">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13V15C3 16.1046 3.89543 17 5 17H10M3 13V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V13M3 13H21M21 13V15C21 16.1046 20.1046 17 19 17H14M10 17V18.1716C10 18.702 9.78929 19.2107 9.41421 19.5858L8 21H16L14.5858 19.5858C14.2107 19.2107 14 18.702 14 18.1716V17M10 17H14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Computer

Computer.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Computer.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
