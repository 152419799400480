import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Layout from 'components/Layout'
import Image from 'components/Image'
import Paper from 'components/Paper'
import Button from 'components/Button'
import WrapIcon from 'components/WrapIcon'
import StepWrapper from 'components/StepWrapper'
import { FTNonLector, FTGetLector, Chevron } from 'components/Icons'
import Typography from '@mui/material/Typography'
import DATA from 'constants/pages/firstTransaction.json'
import { ROUTE_FIRST_TRANSACTION_READER, ROUTE_HOME } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { getToken } from 'helpers/tokenHelpers'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import {
  startNativeBridge,
  firstPaymentLinkCreation,
} from 'helpers/callNativeBridge'
import segment from 'helpers/segment'

const FirstTransaction = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { device } = useGlobalContext()

  useEffect(() => {
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    } else {
      cleverTap.event(DATA.CLEVERTAP.EVENTS, {
        ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
        OS: device.type,
        Page: DATA.CLEVERTAP.PAGE,
      })
    }
  })

  const handleNativeBridge = (evt, obj) => {
    startNativeBridge(evt, obj)
  }

  const handleNativeBridgeFTNL = () => {
    firstPaymentLinkCreation()
    segment.track(DATA.SEGMENT.EVENTS.NOT_LECTOR)
  }

  const handleOmit = (e) => {
    handleNativeBridge(e, { to: 'terminal' })
    segment.track(DATA.SEGMENT.EVENTS.OMIT)
  }

  return (
    <Layout header={DATA.header}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          mb: 0,
          p: '0 0 0 16px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Stack>
          <Typography variant="body1">{DATA.top.title}</Typography>
          <Typography color="surface.c700" variant="body1">
            {DATA.top.description}
          </Typography>
        </Stack>
        <Button
          onClick={(e) => handleOmit(e)}
          sx={{ fontWeight: theme.fontWeight.bold }}
        >
          {DATA.top.omit}
        </Button>
      </Stack>
      <StepWrapper>
        <Paper sx={{ p: '48px 16px 24px' }}>
          <Stack sx={{ textAlign: 'center' }}>
            <Image alt={DATA.main.title} src={DATA.main.image} />
            <Typography align="left" sx={{ mt: 2 }} variant="hl">
              {DATA.main.title}
            </Typography>
            <Typography
              align="left"
              dangerouslySetInnerHTML={{ __html: DATA.main.disclaimer }}
              fontWeight={theme.fontWeight.regular}
              sx={{ mb: 2.5, mt: 1 }}
              variant="body1"
            />
            <Button
              color="orange"
              onClick={() => {
                navigate(ROUTE_FIRST_TRANSACTION_READER)
                segment.track(DATA.SEGMENT.EVENTS.ACTIVATE)
              }}
              sx={{ fontWeight: theme.fontWeight.bold, height: 48 }}
            >
              {DATA.main.button}
            </Button>
          </Stack>
        </Paper>
        <Typography
          align="left"
          color="surface.c900"
          fontWeight={theme.fontWeight.semibold}
          sx={{ mb: 4, mt: 3 }}
          variant="body2"
        >
          {DATA.bottom.title}
        </Typography>
        <Paper sx={{ mb: 2, position: 'relative', px: 1.5, py: 2.5 }}>
          <Chip
            label={DATA.bottom.notLector.chip}
            sx={{
              background: theme.palette.success.light,
              color: theme.palette.success.main,
              fontWeight: theme.fontWeight.bold,
              left: 16,
              position: 'absolute',
              top: -16,
            }}
          />
          <Button
            onClick={handleNativeBridgeFTNL}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0,
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                width: '100%',
              }}
            >
              <WrapIcon sx={{ '&.MuiBox-root': { m: 1.5 } }}>
                <FTNonLector />
              </WrapIcon>
              <Stack>
                <Typography
                  align="left"
                  color="surface.c800"
                  fontWeight={theme.fontWeight.semibold}
                  variant="body2"
                >
                  {DATA.bottom.notLector.title}
                </Typography>
                <Typography align="left" color="surface.c700" variant="body2">
                  {DATA.bottom.notLector.description}
                </Typography>
              </Stack>
            </Stack>
            <Chevron color={theme.palette.primary.main} size={1.8} />
          </Button>
        </Paper>
        <Paper sx={{ px: 1.5, py: 2.5 }}>
          <Link
            href={DATA.bottom.getLector.link}
            onClick={() => segment.track(DATA.SEGMENT.EVENTS.GET_LECTOR)}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0,
              textDecoration: 'none',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center', width: '100%' }}
            >
              <WrapIcon sx={{ '&.MuiBox-root': { m: 1.5 } }}>
                <FTGetLector size={1.6} />
              </WrapIcon>
              <Stack>
                <Typography
                  align="left"
                  color="surface.c800"
                  fontWeight={theme.fontWeight.semibold}
                  variant="body2"
                >
                  {DATA.bottom.getLector.title}
                </Typography>
                <Typography align="left" color="surface.c700" variant="body2">
                  {DATA.bottom.getLector.description}
                </Typography>
              </Stack>
            </Stack>
            <Chevron color={theme.palette.primary.main} size={1.8} />
          </Link>
        </Paper>
      </StepWrapper>
    </Layout>
  )
}

export default FirstTransaction
