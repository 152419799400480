import { useState } from 'react'
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Popper,
} from '@mui/material'
import { TrailIconDown, TrailIconUp } from 'components/Icons'
import PropTypes from 'prop-types'
import theme from 'theme'
import useStyles from './styles'

const PopperComponent = (props) => (
  <Popper
    {...props}
    placement="bottom"
    sx={{
      left: '4px !important',
      marginBottom: '18px !important',
      marginTop: '15px !important',
      width: '100% !important',
    }}
  />
)

const Autocomplete = ({
  onChange,
  value,
  label,
  options,
  placeholder,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <MuiAutocomplete
      {...props}
      disablePortal
      isOptionEqualToValue={(option, val) => option.value === val.value}
      ListboxProps={{
        className: classes.scrollbar,
        style: { maxHeight: 200, overflow: 'auto' },
      }}
      onChange={onChange}
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      options={options}
      PopperComponent={PopperComponent}
      popupIcon={open ? <TrailIconUp /> : <TrailIconDown />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          sx={{
            caretColor: theme.palette.primary.main,
          }}
          value={value}
          variant="standard"
        />
      )}
    />
  )
}

Autocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
}

Autocomplete.defaultProps = {
  placeholder: '',
}

export default Autocomplete
