import actions from './actions'

export const initialState = {
  business_address: null,
  business_income: null,
  business_info: null,
  business_type: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_BUSINESS_ADDRESS: {
      return {
        ...state,
        business_address: action.business_address,
      }
    }
    case actions.UPDATE_BUSINESS_INCOME: {
      return {
        ...state,
        business_income: action.business_income,
      }
    }
    case actions.UPDATE_BUSINESS_INFO: {
      return {
        ...state,
        business_info: action.business_info,
      }
    }
    case actions.UPDATE_BUSINESS_TYPE: {
      return {
        ...state,
        business_type: action.business_type,
      }
    }
    case actions.RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
