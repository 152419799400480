import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import { useNavigate } from 'react-router-dom'
import DATA from 'constants/pages/businessAddress.json'
import FormText from 'components/Form/Text'
import Paper from 'components/Paper'
import Select from 'components/Form/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { useBusinessFormContext, Actions } from 'components/Form/store/business'
import { useUserFormContext } from 'components/Form/store/user'
import useColonies from 'hooks/useColonies'
import { StackBusinessAddress } from 'components/Renders'
import { updateInfo } from 'helpers/fetchUtils'
import { getVersion } from 'helpers/versionHelpers'
import segment from 'helpers/segment'
import {
  ENV,
  ROUTE_API_BUSINESS_ADDRESS,
  ROUTE_VERIFY,
  ROUTE_HOME,
  ROUTE_ACCOUNT_ACTIVATION,
  ROUTE_OPTIONAL_KYC,
} from 'constants/routes'
import SubmitError from 'components/SubmitError'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { getToken } from 'helpers/tokenHelpers'
import { isWebView } from 'utils'

const BusinessAddressPage = () => {
  const { device } = useGlobalContext()
  const [searchColony, setSearchColony] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openSB, setOpenSB] = useState(false)
  const street = DATA.FORMIKS_ID.STREET
  const outdoor = DATA.FORMIKS_ID.OUTDOOR
  const interior = DATA.FORMIKS_ID.INTERIOR
  const zip = DATA.FORMIKS_ID.ZIP
  const municipality = DATA.FORMIKS_ID.MUNICIPALITY
  const state = DATA.FORMIKS_ID.STATE
  const colony = DATA.FORMIKS_ID.COLONY
  const theme = useTheme()
  const { businessState, businessDispatch } = useBusinessFormContext()
  const { userState } = useUserFormContext()
  const { result: colonies, error } = useColonies(searchColony) || {
    colonies: [],
  }
  const [errorZip, setErrorZip] = useState(false)
  const [mandatory, setMandatory] = useState(false)
  const navigate = useNavigate()
  const baseUrl = `${ENV.REACT_APP_API_URL}${ROUTE_API_BUSINESS_ADDRESS}`
  const version = parseFloat(getVersion(), 10)

  const checkValidZip = (val) => val?.length === 5 && !errorZip

  const BUSINESS_ADRESS_VALIDATION = yup.object().shape({
    [colony]: yup.string().required(DATA.errorSelect),
    [outdoor]: yup.string().required(DATA.required),
    [street]: yup.string().required(DATA.required),
    [zip]: yup
      .string()
      .required(DATA.errorZip)
      .test('test-validity', DATA.errorZip, (value) => checkValidZip(value)),
  })

  const nextPage = () => {
    const routes = {
      1: ROUTE_ACCOUNT_ACTIVATION,
      1.1: ROUTE_VERIFY,
      1.2: ROUTE_VERIFY,
    }

    const desktopRoutes = {
      1: ROUTE_ACCOUNT_ACTIVATION,
      1.1: ROUTE_OPTIONAL_KYC,
      1.2: ROUTE_OPTIONAL_KYC,
    }

    navigate(
      !isWebView()
        ? desktopRoutes[version]
        : routes[version] || ROUTE_ACCOUNT_ACTIVATION
    )
  }

  const formik = useFormik({
    initialValues: {
      [colony]: businessState?.business_address?.colony || '',
      [municipality]: businessState?.business_address?.municipality || '',
      [outdoor]: businessState?.business_address?.number_in || '',
      [state]: businessState?.business_address?.state || '',
      [street]: businessState?.business_address?.street || '',
      [zip]: businessState?.business_address?.zipcode || '',
    },
    onSubmit: (values) => {
      setLoading(true)
      const payload = {
        colony: values[colony],
        external_number: values[outdoor].toString(), // number_out
        internal_number: values[interior] || '', // number_in
        municipality: values[municipality],
        postal_code: values[zip].toString(),
        state: values[state],
        street: values[street],
      }

      if (businessState.business_address?.source) {
        payload.source = businessState.business_address?.source
      }

      businessDispatch({
        business_address: { ...payload },
        type: Actions.UPDATE_BUSINESS_ADDRESS,
      })
      updateInfo(baseUrl, {
        ...DATA.apiSource,
        ...payload,
      })
        .then(() => {
          segment.track(DATA.SEGMENT.EVENTS.CONFIRM_ADDRESS_SUCCESS)
          segment.identify(userState.mid, {
            colonia: values[colony],
            country: 'MX',
            municipio: values[municipality],
            postalCode: values[zip],
            state: values[state],
          })
          setLoading(false)
          nextPage()
        })
        .catch((err) => {
          setLoading(false)
          cleverTap.event(DATA.CLEVERTAP.EVENTS, {
            ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
            ErrorMessage: err?.code,
            OS: device.type,
          })
          setOpenSB(true)
        })
        .finally(() => setLoading(false))
    },
    validationSchema: BUSINESS_ADRESS_VALIDATION,
  })

  const groupSetFieldValues = (muniValue, stateValue) => {
    formik.setFieldValue(municipality, muniValue)
    formik.setFieldValue(state, stateValue)
  }

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }

    if (businessState?.business_address?.zipcode) {
      setSearchColony(businessState?.business_address?.zipcode)
    }
    if (colonies?.length === 1) {
      formik.setFieldValue(colony, colonies[0].label)
    }

    if (colonies?.length > 0) {
      groupSetFieldValues(colonies[0].municipality, colonies[0].state)
    }
    setErrorZip(error)
  }, [colonies, error])

  const handleChangeZIP = (e) => {
    const { value } = e.target

    setSearchColony(null)
    setErrorZip(false)

    formik.handleChange(e)
    formik.setFieldValue(colony, '')
    formik.setFieldTouched(colony, false, false)
    formik.setFieldTouched(zip, false, false)

    if (value.length === 5) {
      setSearchColony(value)
    } else {
      businessDispatch({
        business_address: {
          municipality: '',
          number_in: businessState?.business_address?.number_in,
          state: '',
          street: businessState?.business_address?.street,
          zipcode: '',
        },
        type: Actions.UPDATE_BUSINESS_ADDRESS,
      })
      groupSetFieldValues('', '')
    }
  }

  const handleKeyPress = (e) => {
    const { value } = e.target

    if (value.length === 5) {
      e.preventDefault()
    }
  }

  const checkMandatory = () =>
    formik.values[colony] === '' &&
    formik.values[outdoor] === '' &&
    formik.values[street] === '' &&
    formik.values[zip] === ''

  const hasEmptyFields = () =>
    formik.values[colony].length === 0 ||
    formik.values[outdoor].length === 0 ||
    formik.values[street].length === 0 ||
    formik.values[zip].length === 0

  const handleSubmit = (e) => {
    setMandatory(checkMandatory())

    if (
      hasEmptyFields() ||
      formik.errors[outdoor] ||
      formik.errors[street] ||
      formik.errors[zip]
    ) {
      segment.track(DATA.SEGMENT.EVENTS.CONFIRM_ADDRESS_NOT_SUCCESS)
    }

    formik.handleSubmit(e)
  }

  const handleChange = (e) => {
    formik.setFieldValue(colony, e.value)
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.COLONY_OP_SEL,
    })
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        loading={loading}
        onContinue={handleSubmit}
      >
        {mandatory && (
          <Typography color="error.main" sx={{ textAlign: 'right' }}>
            {DATA.formRequired}
          </Typography>
        )}

        <form onSubmit={formik.handleSubmit}>
          <Paper
            sx={{
              borderColor: mandatory
                ? theme.palette.error.main
                : theme.backgrounds[2],
              padding: 0,
            }}
          >
            <StackBusinessAddress
              sx={{
                '& .MuiFormControl-root': {
                  m: 'auto',
                  width: 'calc(100% - 16px)',
                },
                '& .MuiFormControl-root .MuiButtonBase-root': {
                  margin: '8px auto auto',
                  paddingLeft: 0,
                  width: 'calc(100% - 13px)',
                },
                '& .MuiInput-root': {
                  pl: 0,
                },
              }}
            >
              <FormText
                autoFocus
                error={formik.touched[street] && formik.errors.street}
                errorHelperIcon
                id={street}
                InputProps={{
                  endAdornment: <>&nbsp;</>,
                }}
                label={DATA.form.street}
                onChange={formik.handleChange}
                value={formik.values.street}
                variant="standard"
              />
              <Stack
                direction="row"
                spacing={2}
                sx={{ m: 'auto', width: 'calc(100% - 16px)' }}
              >
                <FormText
                  error={formik.touched[outdoor] && formik.errors.outdoor}
                  errorHelperIcon
                  id={outdoor}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.form.outdoor_number}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.outdoor}
                  variant="standard"
                />
                <FormText
                  helperText={DATA.optional}
                  id={interior}
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.form.interior_number}
                  onChange={formik.handleChange}
                  value={formik.values.interior}
                  variant="standard"
                />
              </Stack>
              <FormText
                error={formik.touched[zip] && formik.errors.zip}
                errorHelperIcon
                id={zip}
                InputProps={{
                  endAdornment: <>&nbsp;</>,
                }}
                label={DATA.form.zip}
                onChange={(e) => handleChangeZIP(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={formik.values.zip}
                variant="standard"
              />
              {searchColony && colonies?.length ? (
                <Select
                  error={formik.touched[colony] && formik.errors.colony}
                  errorHelperIcon
                  items={colonies}
                  label={DATA.form.colony}
                  onChange={handleChange}
                  sx={{
                    '& label.MuiInputLabel-root': {
                      left: '-14px',
                      pl: 0,
                      top: '-14px',
                      zIndex: 'auto',
                    },
                    '& label.MuiInputLabel-root .MuiTypography-root': {
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: theme.fontWeight.semibold,
                    },
                    border: 'none',
                    borderBottom:
                      formik.touched[colony] && formik.errors.colony
                        ? `1px solid ${theme.palette.error.main}`
                        : `1px solid ${theme.backgrounds[2]}`,
                    borderRadius: 'initial',
                    mt: 1,
                  }}
                  text={formik.values.colony || undefined}
                  value={formik.values[colony]}
                />
              ) : (
                <FormText
                  disabled
                  error={formik.touched[colony] && formik.errors.colony}
                  errorHelperIcon
                  id="colony"
                  InputProps={{
                    endAdornment: <>&nbsp;</>,
                  }}
                  label={DATA.form.colony}
                  variant="standard"
                />
              )}
              <FormText
                disabled
                errorHelperIcon
                id={municipality}
                InputProps={{
                  endAdornment: <>&nbsp;</>,
                }}
                label={DATA.form.municipality}
                onChange={formik.handleChange}
                value={formik.values.municipality}
                variant="standard"
              />
              <FormText
                className="BorderNone"
                disabled
                errorHelperIcon
                id={state}
                InputProps={{
                  endAdornment: <>&nbsp;</>,
                }}
                label={DATA.form.state}
                onChange={formik.handleChange}
                value={formik.values.state}
                variant="standard"
              />
            </StackBusinessAddress>
          </Paper>
        </form>
        <SubmitError onClose={() => setOpenSB(false)} open={openSB} />
      </StepWrapper>
    </Layout>
  )
}

export default BusinessAddressPage
