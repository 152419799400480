import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const WrapIcon = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.secondary.light,
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
}))

export default WrapIcon
