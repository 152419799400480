import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Entertainment = ({ color }) => (
  <SvgIcon>
    <svg
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00003 15.0954C7.00003 15.0954 9.86501 19.5 13 19.5C14.5338 19.5 16.0029 18.4458 17.1035 17.3689C18.4871 16.0151 19 14.065 19 12.1293V6.81581C19 6.01493 18.5219 5.28957 17.7594 5.04444C16.5143 4.64411 14.562 4.1609 13 4.42874M4 5.42874H5.5M8.5 5.42874H10M14.5 9.42874H16M4.5 9.42874C5.96087 10.5974 8.03913 10.5974 9.5 9.42874M15.5 14.4287C14.0391 13.26 11.9609 13.26 10.5 14.4287M1 2.02037V6.61507C1 9.46488 2.21867 12.1787 4.34865 14.072C5.86071 15.416 8.13929 15.416 9.65135 14.072C11.7813 12.1787 13 9.46488 13 6.61507V2.02037C13 1.71666 12.7822 1.45668 12.4831 1.40352C8.85615 0.758722 5.14385 0.758722 1.51686 1.40352C1.21784 1.45668 1 1.71666 1 2.02037Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Entertainment

Entertainment.propTypes = {
  color: PropTypes.string,
}

Entertainment.defaultProps = {
  color: theme.palette.text.primary,
}
