import { Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/material/styles'

const StackBusinessAddress = styled(MuiStack)(({ theme }) => ({
  '& .BorderNone .MuiInputBase-root.MuiInput-root:after,& .BorderNone .MuiInputBase-root.MuiInput-root:before':
    {
      border: 'none',
    },
  '& .MuiButton-root.filled, & .MuiButtonBase-root': {
    paddingLeft: `${theme.space[4]}px`,
  },
  '& .MuiFormLabel-root': {
    paddingLeft: `${theme.space[4]}px`,
  },
  '& .MuiInput-root': {
    paddingLeft: '13px',
  },
  '& .MuiInputBase-input[value], & .MuiInputBase-input:focus': {
    padding: `${theme.space[4]}px 0 4px 0`,
  },
  '& .MuiInputBase-root.MuiInput-root.Mui-error:before,& .MuiInputBase-root.MuiInput-root.Mui-error:after':
    {
      borderColor: theme.palette.error.main,
    },
  '& .MuiInputBase-root.MuiInput-root:before,& .MuiInputBase-root.MuiInput-root:after':
    {
      borderBottom: `1px solid ${theme.backgrounds[2]}`,
    },
  '& label+.MuiInputBase-root': {
    marginTop: '6px',
  },
  '& label,& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.text.primary,
  },
}))

export default StackBusinessAddress
