import { Paper as MuiPaper } from '@mui/material'
import { styled } from '@mui/material/styles'

const Paper = styled(MuiPaper)(({ theme, borderRadius = theme.space[4] }) => ({
  border: `1px solid ${theme.backgrounds[2]}`,
  borderRadius,
  boxShadow: 'none',
  padding: theme.space[2],
}))

export default Paper
