import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  IconButton,
  useTheme,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { randomString } from 'utils'
import RenderRadio from './renderRadio'

const RadioGroup = (props) => {
  const theme = useTheme()
  const {
    id,
    items,
    onChange,
    highlight,
    error,
    value,
    radioStyles,
    radioProps,
    maxItems,
  } = props
  const [selectedValue, setSelectedValue] = useState(value)
  const [labelHeight, setLabelHeight] = useState(value)
  const randomId = randomString(5)
  const labelRefs = [...Array(items?.length)]

  const handleChange = (item) => {
    if (selectedValue !== item.value) {
      setSelectedValue(item.value)

      if (onChange) {
        onChange(item)
      }
    }
  }

  const renderIcon = (item) => {
    if (item.icon) {
      return (
        <ListItemIcon sx={{ minWidth: 0, ml: -1, pr: 2 }}>
          <IconButton
            edge="end"
            sx={{
              '& path': {
                stroke:
                  selectedValue === item.value && highlight
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
              },
            }}
          >
            {React.createElement(item.icon)}
          </IconButton>
        </ListItemIcon>
      )
    }

    return null
  }

  useEffect(() => {
    if (labelRefs) {
      setLabelHeight(
        Math.max(
          ...labelRefs.map((item, index) => (index >= maxItems ? 0 : 24))
        )
      )
    }
  }, [items])

  return (
    <FormControl fullWidth>
      <List sx={{ width: '100%' }}>
        {items?.length &&
          items.map((item, index) => {
            if (index >= maxItems) return null

            return (
              <ListItem
                key={item.value + randomString(4)}
                className={selectedValue === item.value ? 'active' : null}
                sx={{
                  height: 'auto',
                  padding: `${theme.space[0]}px ${theme.space[2]}px`,
                }}
              >
                <ListItemButton
                  dense
                  focusRipple
                  onClick={() => handleChange({ ...item, index })}
                  role={undefined}
                  sx={{
                    border: `1px solid ${
                      selectedValue === item.value && highlight
                        ? theme.palette.primary.main
                        : theme.backgrounds[2]
                    }`,
                    borderRadius: `${theme.space[2]}px`,
                    margin: `${theme.space[1]}px 0`,
                  }}
                >
                  {renderIcon(item)}
                  {RenderRadio({
                    checked: selectedValue === item.value,
                    handleChange,
                    id,
                    index,
                    item,
                    labelHeight,
                    labelRef: labelRefs[index],
                    radioProps,
                    sx: radioStyles,
                  })}
                </ListItemButton>
              </ListItem>
            )
          })}
      </List>
      <FormHelperText
        error
        id={`radio-buttons-error-${id || randomId}`}
        sx={{ ml: 0, mt: -1 }}
      >
        {error}
      </FormHelperText>
    </FormControl>
  )
}

export default RadioGroup

RadioGroup.propTypes = {
  containerStyles: PropTypes.shape({}),
  error: PropTypes.string,
  highlight: PropTypes.bool,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
  maxItems: PropTypes.number,
  onChange: PropTypes.func,
  radioProps: PropTypes.shape({}),
  radioStyles: PropTypes.shape({}),
  value: PropTypes.string,
}

RadioGroup.defaultProps = {
  containerStyles: null,
  error: null,
  highlight: true,
  maxItems: 100,
  onChange: null,
  radioProps: null,
  radioStyles: null,
  value: null,
}
