import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getVersion } from 'helpers/versionHelpers'
import { getOSName } from 'utils'

const DebugConsole = () => {
  const [isVisible, setIsVisible] = useState(false)

  const {
    onboardingEnableFirstTransaction,
    onboardingVersion,
    onboardingInstallments,
  } = useFlags()

  const version = getVersion()

  const operativeSystem = getOSName()

  if (
    process.env.REACT_APP_ENV !== 'dev' &&
    process.env.REACT_APP_ENV !== 'stage'
  ) {
    return null
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <button
        onClick={toggleVisibility}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: 'transparent',
          position: 'fixed',
          right: 0,
          zIndex: 9999,
        }}
        type="button"
      >
        Toggle Console
      </button>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          bottom: 0,
          color: 'white',
          display: isVisible ? 'block' : 'none',
          height: '90vh',
          left: 0,
          margin: 'auto',
          overflow: 'auto',
          padding: '1rem',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 9999,
        }}
      >
        <pre>
          {JSON.stringify(
            {
              onboardingEnableFirstTransaction,
              onboardingInstallments,
              onboardingVersion,
              operativeSystem,
              version,
            },
            null,
            2
          )}
        </pre>
      </div>
    </>
  )
}

export default DebugConsole
