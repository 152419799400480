import { useEffect, useState } from 'react'
import { fetchInfo } from 'helpers/fetchUtils'

const useFetch = (url) => {
  const [responseData, setResponseData] = useState(null)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data } = await fetchInfo(url)

      setResponseData(data)
    } catch (err) {
      setError(!!err)
      setErrorMsg(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { error, errorMsg, loading, responseData }
}

export default useFetch
