import React, { useEffect, useState } from 'react'
import { Box, Dialog, Grow, Stack, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import Image from 'components/Image'

import SPLASH from 'constants/splash.json'

const Transition = React.forwardRef((props, ref) => (
  <Grow ref={ref} unmountOnExit {...props} />
))

const Splash = ({ section, children, timeout }) => {
  const theme = useTheme()
  const [showSplash, setShowSlash] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowSlash(false)
    }, timeout)
  }, [])

  const renderDefault = () => {
    if (!SPLASH.items[section]) {
      // eslint-disable-next-line no-console
      console.error(
        `Splash error:: No entry with id: \`${section}\` found in splash.json file`
      )

      return null
    }

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          background: theme.backgrounds[1],
          height: '100%',
          left: 0,
          padding: 4,
          position: 'fixed',
          top: 0,
          width: '100%',
          zindex: '1000',
        }}
      >
        <Image
          alt={SPLASH.items[section].altImg || SPLASH.altImg}
          src={SPLASH.items[section].icon}
        />
        <Typography align="center" component="h2" variant="h1">
          {SPLASH.items[section].title}
        </Typography>
        <Typography align="center" variant="body2">
          {SPLASH.items[section].subtitle}
        </Typography>
      </Stack>
    )
  }

  return (
    <>
      {showSplash && (
        <Box
          sx={{
            background: theme.palette.white.main,
            height: '100%',
            left: 0,
            padding: 4,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: '100',
          }}
        />
      )}
      <Dialog fullScreen open={showSplash} TransitionComponent={Transition}>
        {children || renderDefault()}
      </Dialog>
    </>
  )
}

export default Splash

Splash.propTypes = {
  children: PropTypes.element,
  section: PropTypes.string,
  timeout: PropTypes.number,
}

Splash.defaultProps = {
  children: null,
  section: null,
  timeout: SPLASH.timeout,
}
