import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Lottie from 'react-lottie'
import Layout from 'components/Layout'
import animationData from './splashData.json'

const HomePageSplash = ({ onComplete }) => {
  const defaultOptions = {
    animationData,
    autoplay: true,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Layout>
      <Box
        sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <Lottie
          eventListeners={[
            {
              callback: onComplete,
              eventName: 'complete',
            },
          ]}
          height={300}
          options={defaultOptions}
          width={300}
        />
      </Box>
    </Layout>
  )
}

HomePageSplash.propTypes = {
  onComplete: PropTypes.func,
}

HomePageSplash.defaultProps = {
  onComplete: null,
}

export default HomePageSplash
