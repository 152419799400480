import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const SendAndReceive = ({ size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94438 9.22212H12.4999M12.4999 9.22212L9.72212 6.44438M12.4999 9.22212L9.72212 11.9999M18.0553 14.7776H12.4999M12.4999 14.7776L15.2776 11.9999M12.4999 14.7776L15.2776 17.5553M19.5709 4.92909C23.4764 8.83453 23.4764 15.1655 19.5709 19.0709C15.6655 22.9764 9.33453 22.9764 5.42909 19.0709C1.52364 15.1655 1.52364 8.83453 5.42909 4.92909C9.33453 1.02364 15.6655 1.02364 19.5709 4.92909Z"
        stroke="#FC4C02"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
)

export default SendAndReceive

SendAndReceive.propTypes = {
  size: PropTypes.number,
}

SendAndReceive.defaultProps = {
  size: 1.5,
}
