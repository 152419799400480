import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import AppWrapper from 'components/AppWrapper'
import cleverTap from 'helpers/clevertap'
import App from './App'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { getOSName } from 'utils'

const main = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_KEY,
    context: {
      anonymous: true,
      kind: 'user',
      webviewOS: getOSName(),
    },
  })

  const root = ReactDOM.createRoot(document.getElementById('root'))

  cleverTap.initialize()

  root.render(
    <LDProvider>
      <BrowserRouter>
        <AppWrapper>
          <App />
        </AppWrapper>
      </BrowserRouter>
    </LDProvider>
  )
}

main()
