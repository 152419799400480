import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const Image = ({ alt, src, height, width }) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (localStorage.getItem(src)) {
      setImageUrl(src)
    } else {
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()

          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const dataURL = reader.result

            localStorage.setItem(src, dataURL)
            setImageUrl(dataURL)
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Image::error: ${error}`)
        })
    }
  }, [src])

  return (
    <Box
      sx={{
        display: 'inline',
        minHeight: `${height !== null ? height : 0}px`,
        minWidth: `${width !== null ? width : 0}px`,
      }}
    >
      <img alt={alt} height={height} src={imageUrl} width={width} />
    </Box>
  )
}

export default Image

Image.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
}

Image.defaultProps = {
  alt: '',
  height: null,
  width: null,
}
