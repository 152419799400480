import Layout from 'components/Layout'
import Image from 'components/Image'
import Paper from 'components/Paper'
import Button from 'components/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DATA from 'constants/pages/firstTransactionSuccess.json'
import { Thick, Plus } from 'components/Icons'
import { useTheme } from '@mui/material'
import { startNativeBridge } from 'helpers/callNativeBridge'

const FirstTransactionSuccess = () => {
  const theme = useTheme()

  const handleNativeBridge = (evt, obj) => {
    startNativeBridge(evt, obj)
  }

  return (
    <Layout header={DATA.header}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '56px',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Button
          onClick={(e) => handleNativeBridge(e, { to: 'terminal' })}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            minWidth: 'auto',
          }}
        >
          <Plus color={theme.palette.text.primary} rotate={45} />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          width: 'calc(100% - 32px)',
        }}
      >
        <Stack
          sx={{ justifyContent: 'center', textAlign: 'center', width: '100%' }}
        >
          <Image alt={DATA.title} src={DATA.image} />
          <Typography sx={{ mb: 1, mt: 2 }} variant="hl">
            {DATA.title}
          </Typography>
          <Typography
            fontWeight={theme.fontWeight.regular}
            sx={{ mb: 2 }}
            variant="body2"
          >
            {DATA.subtitle}
          </Typography>
          <Paper sx={{ pt: 2, px: 2 }}>
            {DATA.bullets.map((item) => (
              <Stack
                key={item}
                flexDirection="row"
                sx={{ alignItems: 'center', mb: 1 }}
              >
                <Thick color={theme.palette.success.checkmark} size={1.2} />
                <Typography
                  fontWeight={theme.fontWeight.regular}
                  sx={{ ml: 1 }}
                  variant="body1"
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Paper>
        </Stack>
      </Box>
      <Button
        color="orange"
        onClick={(e) => handleNativeBridge(e, { to: 'terminal' })}
        sx={{
          fontWeight: theme.fontWeight.bold,
          height: 48,
          width: 'calc(100% - 32px)',
        }}
      >
        {DATA.button}
      </Button>
    </Layout>
  )
}

export default FirstTransactionSuccess
