import PropTypes from 'prop-types'
import { Stack, useTheme } from '@mui/material'

const Footnote = ({ children, sx }) => {
  const theme = useTheme()

  return (
    <Stack
      alignItems="center"
      data-testid="footnote"
      justifyContent="center"
      sx={{
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body0.fontSize,
        mt: '16px !important',
        padding: `${theme.space[1]}px 0`,
        textAlign: 'center',
        ...sx,
      }}
    >
      {children}
    </Stack>
  )
}

export default Footnote

Footnote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  sx: PropTypes.shape({}),
}

Footnote.defaultProps = {
  sx: null,
}
