import { Stack as MuiStack } from '@mui/material'

import { styled } from '@mui/material/styles'

const StackFinancialInformation = styled(MuiStack)(({ theme }) => ({
  '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
    color: theme.palette.surface.c900,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.fontWeight.bold,
    paddingLeft: '12px',
  },

  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined':
    {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.fontWeight.regular,
      top: '16px',
      zIndex: 0,
    },

  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard':
    {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.fontWeight.regular,
      left: '12px',
      top: '5px',
    },

  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard':
    {
      color: theme.palette.text.light,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.fontWeight.semibold,
      left: '12px',
      top: '0px',
    },

  '& .MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
    {
      marginBottom: 12,
    },

  '& .MuiInputBase-root.MuiInput-root:before, & .MuiInputBase-root.MuiInput-root:after, & .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':
    {
      borderBottom: 'none',
    },

  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
    {
      color: theme.palette.surface.c900,
      fontWeight: theme.fontWeight.bold,
      paddingTop: '25px',
    },

  '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input': {
    color: theme.palette.black.main,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.fontWeight.semibold,
    padding: '12px 0px 0px 12px',
  },
}))

export default StackFinancialInformation
