import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const AddProduct = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 25">
    <svg
      fill="none"
      height="22"
      viewBox="0 0 20 22"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.00098L9 2.99702C7.89543 2.99702 7 3.89422 7 5.00098V6.00098M10 1.00098H16M10 1.00098L10.7721 3.3172C10.9082 3.72555 11.2903 4.00098 11.7208 4.00098H14.2792C14.7097 4.00098 15.0918 3.72555 15.2279 3.3172L16 1.00098M16 1.00098L17 2.99702C18.1046 2.99702 19 3.89422 19 5.00098V18.997C19 20.1038 18.1046 21.001 17 21.001H14M13 6.65688H16.5V18.501H14M4 16.501H4.02M8.02 16.501H8M4 18.501H4.02M8.02 18.501H8M9 12.501V12.501C9 13.3294 8.32843 14.001 7.5 14.001H4.5C3.67157 14.001 3 13.3294 3 12.501V12.501C3 11.6725 3.67157 11.001 4.5 11.001H7.5C8.32843 11.001 9 11.6725 9 12.501ZM3 21.001H9C10.1046 21.001 11 20.1055 11 19.001V10.501C11 9.39641 10.1046 8.50098 9 8.50098H3C1.89543 8.50098 1 9.39641 1 10.501V19.001C1 20.1055 1.89543 21.001 3 21.001Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default AddProduct

AddProduct.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

AddProduct.defaultProps = {
  color: theme.palette.primary.main,
  size: 1.5,
}
