/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'
import Layout from 'components/Layout'
import Button from 'components/Button'
import Image from 'components/Image'
import { Bluetooth, ShareLink, HandMoney, Chevron } from 'components/Icons'
import {
  startNativeBridge,
  handleNativeShare,
  recordAppsFlyerEvent,
} from 'helpers/callNativeBridge'
import disableHistoryBack from 'helpers/disableHistoryBack'
import {
  ENV,
  GET_PUBLIC_PROFILE,
  GET_MERCHANT_INFO,
  ROUTE_HOME,
} from 'constants/routes'
import DATA from 'constants/pages/firstSale.json'
import { fetchInfo } from 'helpers/fetchUtils'
import { getVersion } from 'helpers/versionHelpers'
import Splash from 'components/Splash'
import Paper from 'components/Paper'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { useUserFormContext } from 'components/Form/store/user'
import { getToken } from 'helpers/tokenHelpers'
import { useNavigate } from 'react-router-dom'
import { stopDatadogSession } from 'helpers/dataDog'
import { isWebView } from 'utils'

const FirstSale = () => {
  const theme = useTheme()
  const { device } = useGlobalContext()
  const [greeting, setGreeting] = useState(true)
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userState } = useUserFormContext()
  const publicProfileUrl = `${ENV.REACT_APP_API_URL}${GET_PUBLIC_PROFILE}?public_profile_request_type=MERCHANT_PUBLIC_PROFILE_GET_BY_MERCHANT_ID`
  const merchantInfoUrl = `${ENV.REACT_APP_API_URL_2}${GET_MERCHANT_INFO}`
  const urlToShare = `${DATA.firstSale.store.link}${DATA.firstSale.store.arroba}${profile?.alias}`
  const { kycAccepted: kycCompleted, clabe } = userState
  const version = parseFloat(getVersion(), 10)
  const showClabeBtn = !clabe && (version < 1.2 || !kycCompleted)
  const navigate = useNavigate()

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.VIEW,
      OS: device.type,
      Page: DATA.CLEVERTAP.PAGE,
    })

    segment.page(DATA.SEGMENT.PAGE)
    segment.track(DATA.SEGMENT.EVENTS.FIRST_SALE_PAGE_VIEW)

    setTimeout(() => {
      setGreeting(false)
      setLoading(true)
    }, DATA.firstSale.interval)

    disableHistoryBack()

    const fetchData = async () => {
      try {
        const { data } = await fetchInfo(merchantInfoUrl)

        const eventName = 'pmkt_clip-app_finish_onboarding_new'
        const eventParams = {
          merchant_id: data?.merchant.id,
        }

        recordAppsFlyerEvent(eventName, JSON.stringify(eventParams))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (!greeting && !profile) {
        fetchInfo(publicProfileUrl)
          .then(({ data }) => {
            setProfile(data?.items[0])
          })
          .catch((err) => {
            setError(!!err)
          })
          .finally(() => setLoading(false))
      }
    }, DATA.firstSale.interval)

    return () => clearInterval(timer)
  }, [greeting, profile])

  const handleClevertap = (tgt) => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: tgt,
    })
  }

  const handleSegment = (eventName) => {
    segment.track(eventName)
  }

  const handleNativeBridge = (evt, obj, target, segmentEvent) => {
    startNativeBridge(evt, obj)
    if (segmentEvent) handleSegment(segmentEvent)
    handleClevertap(target)
    stopDatadogSession()
  }

  const handleShare = (target) => {
    handleNativeShare(
      profile?.public_name,
      profile?.public_description,
      `${DATA.firstSale.store.protocol}${urlToShare}`
    )
    handleSegment(DATA.SEGMENT.EVENTS.GO_TO_SHARE_PAYMENT_LINK)
    handleClevertap(target)
    stopDatadogSession()
  }

  const handleLector = (target) => {
    handleSegment(DATA.SEGMENT.EVENTS.GO_TO_NO_READER_AVAILABLE)
    handleClevertap(target)
    stopDatadogSession()
  }

  const renderShareButton = () =>
    !error ? (
      <Button
        onClick={() => handleShare(DATA.CLEVERTAP.TARGET.SHARE_LINK)}
        startIcon={<ShareLink />}
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        <Typography color="white.main" component="h2" variant="h3">
          {DATA.firstSale.store.button}
        </Typography>
      </Button>
    ) : null

  const FirstSalePage = () => (
    <Stack
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack spacing={2}>
        <Typography component="h1" variant="hxl">
          {DATA.firstSale.title}
        </Typography>
        {showClabeBtn && (
          <Button
            onClick={(e) =>
              handleNativeBridge(
                e,
                { to: 'bank-info' },
                DATA.CLEVERTAP.TARGET.CONFIGURE_CLABE,
                DATA.SEGMENT.EVENTS.GO_TO_ADD_CLABE_ACCOUNT
              )
            }
            sx={{
              alignItems: 'center',
              background: theme.backgrounds[0],
              border: `1px solid ${theme.backgrounds[2]}`,
              borderRadius: `${theme.borderRadius[0]}px`,
              padding: '8px 16px',
            }}
          >
            <HandMoney />
            <Typography
              color="text.primary"
              sx={{ marginLeft: '16px', textAlign: 'left' }}
              variant="body1"
            >
              {DATA.firstSale.clabe}
            </Typography>
            <Chevron />
          </Button>
        )}

        <Stack
          spacing={2}
          sx={{
            border: `1px solid ${theme.backgrounds[2]}`,
            borderRadius: `${theme.borderRadius[0]}px`,
            p: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Typography color="black.main" component="h2" variant="h3">
                {DATA.firstSale.lector.title}
              </Typography>
              <Typography color="black.main" variant="body2">
                {DATA.firstSale.lector.subtitle}
              </Typography>
            </Stack>
            <Avatar
              src="images/lector.svg"
              sx={{
                borderRadius: '12px',
                height: 96,
                width: 96,
              }}
              variant="rounded"
            />
          </Stack>
          <Button
            color="orange"
            onClick={(e) =>
              handleNativeBridge(
                e,
                { to: 'terminal' },
                DATA.CLEVERTAP.TARGET.CONNECT_READER,
                DATA.SEGMENT.EVENTS.GO_TO_CONNECT_READER
              )
            }
            startIcon={<Bluetooth />}
          >
            <Typography color="white.main" component="h2" variant="h3">
              {DATA.firstSale.lector.button}
            </Typography>
          </Button>
          <Divider sx={{ border: `1px solid ${theme.palette.divider}` }} />
          <Button
            href={DATA.firstSale.lector.lector_url}
            onClick={() => handleLector(DATA.CLEVERTAP.TARGET.BUY_READER_LINK)}
            target="_blank"
            type="link"
          >
            <Stack sx={{ textAlign: 'left' }}>
              <Typography color="black.main" component="h2" variant="h3">
                {DATA.firstSale.lector.question}
              </Typography>
              <Typography color="black.main" variant="body2">
                {DATA.firstSale.lector.answer}
              </Typography>
            </Stack>
            <Chevron />
          </Button>
        </Stack>
        <Stack
          spacing={2}
          sx={{
            border: `1px solid ${theme.backgrounds[2]}`,
            borderRadius: `${theme.borderRadius[0]}px`,
            p: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Typography color="black.main" component="h2" variant="h3">
                {DATA.firstSale.store.title}
              </Typography>
              <Typography color="black.main" variant="body2">
                {DATA.firstSale.store.subtitle}
              </Typography>
            </Stack>
            <Avatar
              src="images/store.svg"
              sx={{
                borderRadius: '12px',
                height: 96,
                width: 96,
              }}
              variant="rounded"
            />
          </Stack>
          {loading ? <CircularProgress size={18} /> : renderShareButton()}
        </Stack>
      </Stack>
      <Button
        onClick={(e) =>
          handleNativeBridge(
            e,
            { to: 'terminal' },
            DATA.CLEVERTAP.TARGET.GO_TO_HOME,
            DATA.SEGMENT.EVENTS.GO_TO_CHARGE
          )
        }
        sx={{ backgroundColor: theme.backgrounds[0], mt: 3 }}
      >
        <Typography color="text.primary" variant="h3">
          {DATA.firstSale.button}
        </Typography>
      </Button>
    </Stack>
  )

  const FirstSalePageDesktop = () => (
    <>
      <Stack direction="row">
        <Image
          alt={DATA.firstSaleDesktop.subtitle}
          src={DATA.firstSaleDesktop.icon}
        />
        <Typography component="h2" sx={{ ml: 4 }} variant="h1">
          {DATA.firstSaleDesktop.title}
        </Typography>
      </Stack>
      <Typography variant="body3">{DATA.firstSaleDesktop.subtitle}</Typography>
      <Paper sx={{ mt: 3, p: 2, textAlign: 'center', width: '100%' }}>
        <Typography
          fontWeight={theme.fontWeight.semibold}
          sx={{ mb: 1 }}
          variant="body2"
        >
          {DATA.firstSaleDesktop.qr.title}
        </Typography>
        <Typography
          fontWeight={theme.fontWeight.regular}
          sx={{ display: 'block', m: '8px auto', width: 300 }}
          variant="body1"
        >
          {DATA.firstSaleDesktop.qr.subtitle}
        </Typography>
        <Image
          alt={DATA.firstSaleDesktop.qr.title}
          src="/images/qr-firstsale.png"
          width={188}
        />
      </Paper>
      <Paper sx={{ mt: 3, p: 2, width: '100%' }}>
        <Stack direction="row" spacing={1}>
          <Avatar
            src="images/business.svg"
            sx={{
              borderRadius: '12px',
              height: 96,
              width: 96,
            }}
            variant="rounded"
          />
          <Link
            href={DATA.firstSaleDesktop.firstSale.link}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              textDecoration: 'none',
              width: '100%',
            }}
            target="_blank"
          >
            <Stack>
              <Typography
                fontWeight={theme.fontWeight.semibold}
                sx={{ mb: 1 }}
                variant="body2"
              >
                {DATA.firstSaleDesktop.firstSale.title}
              </Typography>
              <Typography fontWeight={theme.fontWeight.regular} variant="body1">
                {DATA.firstSaleDesktop.firstSale.subtitle}
              </Typography>
            </Stack>
            <Chevron color={theme.palette.primary.main} />
          </Link>
        </Stack>
      </Paper>
      <Paper sx={{ mt: 3, p: 2, width: '100%' }}>
        <Stack direction="row" spacing={1}>
          <Avatar
            src="images/lectores.svg"
            sx={{
              borderRadius: '12px',
              height: 96,
              width: 96,
            }}
            variant="rounded"
          />
          <Link
            href={DATA.firstSaleDesktop.readers.link}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              textDecoration: 'none',
              width: '100%',
            }}
            target="_blank"
          >
            <Stack>
              <Typography
                fontWeight={theme.fontWeight.semibold}
                sx={{ mb: 1 }}
                variant="body2"
              >
                {DATA.firstSaleDesktop.readers.title}
              </Typography>
              <Typography fontWeight={theme.fontWeight.regular} variant="body1">
                {DATA.firstSaleDesktop.readers.subtitle}
              </Typography>
            </Stack>
            <Chevron color={theme.palette.primary.main} />
          </Link>
        </Stack>
      </Paper>
    </>
  )

  return (
    <Layout>
      {isWebView() ? (
        <Stack sx={{ flexGrow: 1, padding: `${theme.space[5]}px` }}>
          <FirstSalePage />
          <Splash section="FirstSalePage" />
        </Stack>
      ) : (
        <FirstSalePageDesktop />
      )}
    </Layout>
  )
}

export default FirstSale
