import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const CreateLink = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg fill="none" height="24" viewBox="0 0 25 24" width="25">
      <path
        d="M9.50003 21C8.84319 21 7.58821 21 6.49995 21C5.39538 21 4.50003 20.1046 4.50003 19L4.5 5C4.5 3.89543 5.39543 3 6.5 3L8 3M16.5 10C16.5 9.30229 16.5 6.81119 16.5 4.99893C16.5001 3.89436 15.6046 3 14.5 3L13 3M8 3V4.5C8 5.05228 8.44771 5.5 9 5.5H12C12.5523 5.5 13 5.05228 13 4.5V3M8 3L13 3M15.5 17.5H17.5M15 14.75C15 14.75 12.5 14.75 12.5 17.5C12.5 20.25 15 20.25 15 20.25M18 14.75C18 14.75 20.5 14.75 20.5 17.5C20.5 20.25 18 20.25 18 20.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default CreateLink

CreateLink.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

CreateLink.defaultProps = {
  color: theme.palette.white.main,
  size: 1.5,
}
