import avertaRegular from 'fonts/hinted-AvertaStd-Regular.woff2'
import avertaBold from 'fonts/hinted-AvertaStd-Bold.woff2'
import avertaSemibold from 'fonts/hinted-AvertaStd-Semibold.woff2'

const MuiCssBaseline = () => ({
  styleOverrides: `
    @font-face {
      font-family: 'Averta Std';
      font-style: normal;
      font-weight: 400;
      src: url(${avertaRegular}) format('woff2');
    }
    @font-face {
      font-family: 'Averta Std';
      font-style: normal;
      font-weight: 800;
      src: url(${avertaBold}) format('woff2');
    }
    @font-face {
      font-family: 'Averta Std';
      font-style: normal;
      font-weight: 800;
      src: url(${avertaSemibold}) format('woff2');
    }
  `,
})

export default MuiCssBaseline
