import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { ErrorIcon } from 'components/Icons'
import SITE from 'constants/site.json'

const SubmitError = ({ msg, open, onClose, autoHide, linkComponent }) => {
  const props = {}

  if (autoHide) {
    props.autoHideDuration = SITE.AUTO_HIDE_ERROR
  }

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={onClose}
      open={open}
      sx={{ mb: 10, ml: 1, mr: 1 }}
      {...props}
    >
      <Alert
        icon={<ErrorIcon />}
        onClose={onClose}
        severity="error"
        slotProps={{
          closeButton: {
            sx: {
              display: autoHide ? 'none' : 'block',
            },
          },
        }}
        sx={{ fontSize: 12, fontWeight: 600, width: '100%' }}
      >
        {msg || SITE.ERROR_STATUS_SUBMIT}
        {linkComponent}
      </Alert>
    </Snackbar>
  )
}

SubmitError.propTypes = {
  autoHide: PropTypes.bool,
  linkComponent: PropTypes.element,
  msg: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
SubmitError.defaultProps = {
  autoHide: true,
  linkComponent: null,
  msg: null,
}
export default SubmitError
