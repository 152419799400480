import { useEffect, useState } from 'react'
import * as yup from 'yup'
import Layout from 'components/Layout'
import Stack from '@mui/material/Stack'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Checkbox, FormControlLabel } from '@mui/material'
import StepWrapper from 'components/StepWrapper'
import FormText from 'components/Form/Text'
import { postInfo } from 'helpers/fetchUtils'
import { getVersion } from 'helpers/versionHelpers'
import segment from 'helpers/segment'
import { useUserFormContext, actions } from 'components/Form/store/user'
import SubmitError from 'components/SubmitError'
import {
  ENV,
  ROUTE_API_USER_IDENTITY,
  ROUTE_ACCOUNT_ACTIVATION,
  ROUTE_HOME,
  ROUTE_BUSINESS_ACTIVITY,
} from 'constants/routes'
import DATA from 'constants/pages/optionalKyc.json'
import { getToken } from 'helpers/tokenHelpers'

const OptionalKyc = () => {
  const [loading, setLoading] = useState(false)
  const [disabledSurname2, setDisabledSurname2] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { userDispatch, userState } = useUserFormContext()
  const navigate = useNavigate()
  const version = parseFloat(getVersion(), 10)

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const nameValidation = yup
    .string()
    .trim()
    .required(DATA.required)
    .matches(/^[A-Za-zÀ-ÿ.\s\u00f1\u00d1\u0027]+$/, DATA.invalidCharacter)

  const KYC_NAMES_VALIDATION = yup.object().shape({
    [DATA.FORMIK_ID.names]: nameValidation,
    [DATA.FORMIK_ID.surname1]: nameValidation,
    [DATA.FORMIK_ID.surname2]: !disabledSurname2 && nameValidation,
  })

  const nextPage = () => {
    const routes = {
      1: ROUTE_BUSINESS_ACTIVITY,
      1.1: ROUTE_ACCOUNT_ACTIVATION,
      1.2: ROUTE_ACCOUNT_ACTIVATION,
    }

    navigate(routes[version] || ROUTE_BUSINESS_ACTIVITY)
  }

  const formik = useFormik({
    initialValues: {
      [DATA.FORMIK_ID.names]: '',
      [DATA.FORMIK_ID.surname1]: '',
      [DATA.FORMIK_ID.surname2]: '',
    },
    onSubmit: (values) => {
      setLoading(true)
      const payload = {
        first_name: values[DATA.FORMIK_ID.names],
        last_name: values[DATA.FORMIK_ID.surname1],
      }

      if (!disabledSurname2) {
        payload.second_last_name = values[DATA.FORMIK_ID.surname2]
      }

      postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_USER_IDENTITY}`, payload)
        .then(({ data }) => {
          console.log('DEBUG::success ', data)
          segment.track(DATA.SEGMENT.EVENTS.ADD_MERCHANT_NAME)
          segment.identify(userState.mid, {
            first_name: values[DATA.FORMIK_ID.names],
            last_name: values[DATA.FORMIK_ID.surname1],
            second_last_name: values[DATA.FORMIK_ID.surname2],
          })
          userDispatch({ ...payload, type: actions.UPDATE_NAME })
          setLoading(false)
          nextPage()
        })
        .catch((err) => {
          console.log('DEBUG::error ', err)
          setOpenSnackbar(true)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    validationSchema: KYC_NAMES_VALIDATION,
  })

  const handleCheckbox = () => setDisabledSurname2(!disabledSurname2)

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={{ ...DATA.name.content }}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        loading={loading}
        onContinue={formik.handleSubmit}
      >
        <Stack alignItems="space-between">
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <FormText
                error={
                  formik.touched[DATA.FORMIK_ID.names] &&
                  formik.errors[DATA.FORMIK_ID.names]
                }
                id={DATA.FORMIK_ID.names}
                label={DATA.name.nameLabel}
                onChange={formik.handleChange}
                placeholder={DATA.label}
                value={formik.values[DATA.FORMIK_ID.names]}
              />
              <FormText
                error={
                  formik.touched[DATA.FORMIK_ID.surname1] &&
                  formik.errors[DATA.FORMIK_ID.surname1]
                }
                id={DATA.FORMIK_ID.surname1}
                label={DATA.name.surnameLabel1}
                onChange={formik.handleChange}
                placeholder={DATA.label}
                value={formik.values[DATA.FORMIK_ID.surname1]}
              />
              <FormText
                disabled={disabledSurname2}
                error={
                  !disabledSurname2 &&
                  formik.touched[DATA.FORMIK_ID.surname2] &&
                  formik.errors[DATA.FORMIK_ID.surname2]
                }
                id={DATA.FORMIK_ID.surname2}
                label={DATA.name.surnameLabel2}
                onChange={formik.handleChange}
                placeholder={DATA.label}
                value={formik.values[DATA.FORMIK_ID.surname2]}
              />
              <FormControlLabel
                checked={disabledSurname2}
                control={<Checkbox data-testid="disabled-surname2" />}
                label={DATA.checkboxText}
                onChange={handleCheckbox}
                sx={{ ml: 0 }}
              />
            </Stack>
          </form>
        </Stack>
      </StepWrapper>
      <SubmitError onClose={() => setOpenSnackbar(false)} open={openSnackbar} />
    </Layout>
  )
}

export default OptionalKyc
