import actions from './actions'

export const initialState = {
  CURP: null,
  RFC: null,
  birthdate: null,
  city: null,
  email: null,
  first_name: null,
  gender: null,
  kycAccepted: false,
  kycInProcess: false,
  last_name: null,
  nationality: null,
  password: null,
  phone: null,
  second_last_name: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_EMAIL: {
      return {
        ...state,
        email: action.email,
      }
    }
    case actions.UPDATE_PASSWORD: {
      return {
        ...state,
        password: action.password,
      }
    }
    case actions.UPDATE_PHONE: {
      return {
        ...state,
        phone: action.phone,
      }
    }
    case actions.UPDATE_PHONE_MARKETING: {
      return {
        ...state,
        phoneMarketing: action.phoneMarketing,
      }
    }
    case actions.RESET: {
      return initialState
    }
    case actions.UPDATE_KYC_ACCEPTED: {
      return {
        ...state,
        kycAccepted: action.kycAccepted,
      }
    }
    case actions.UPDATE_KYC_STATUS: {
      return {
        ...state,
        kycInProcess: action.kycInProcess,
      }
    }
    case actions.UPDATE_NAME: {
      return {
        ...state,
        first_name: action.first_name,
        last_name: action.last_name,
        second_last_name: action.second_last_name,
      }
    }
    case actions.UPDATE_CLABE: {
      return {
        ...state,
        clabe: action.clabe,
      }
    }
    case actions.UPDATE_MID: {
      return {
        ...state,
        mid: action.mid,
      }
    }
    default:
      return state
  }
}

export default reducer
