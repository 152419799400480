import Cookies from 'js-cookie'
import { ENV } from 'constants/routes'

const ENV_REACT_APP_ENV =
  process.env.NODE_ENV === 'test' ? 'dev' : ENV.REACT_APP_ENV

export const getDomainName = (hostName) =>
  hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )

export const getCookieDomain = () => {
  const hostname = window ? window.location.hostname : ''
  const domain = getDomainName(hostname)

  return domain
}

export const createCookieName = (env, baseName) => {
  if (env === 'prod') return baseName

  return `${ENV_REACT_APP_ENV}_${baseName}`
}

export const TOKEN_NAME = 'access_token'

export const saveToken = (token, name = TOKEN_NAME) => {
  const options = { domain: getCookieDomain(), expires: 60 }
  const cookieName = createCookieName(ENV_REACT_APP_ENV, name)

  return Cookies.set(cookieName, token, options)
}

export const getToken = (name = TOKEN_NAME) => {
  const cookie = Cookies.get(createCookieName(ENV_REACT_APP_ENV, name))

  if (cookie) return cookie

  return null
}

export const clearCookie = (name) => {
  const options = { domain: getCookieDomain(), expires: 60 }
  const cookieName = createCookieName(ENV_REACT_APP_ENV, name)

  Cookies.remove(cookieName, options)
}
