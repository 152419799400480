import SvgIcon from '@mui/material/SvgIcon'

const TrailIconUp = (props) => (
  <SvgIcon
    {...props}
    sx={{
      color: '#5E5F73',
      transform: 'rotate(180deg)',
    }}
    viewBox="-3 -2 24 24"
  >
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3327 11.666L9.99935 8.33268L6.66602 11.666"
        stroke="#5E5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default TrailIconUp
