import { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from 'components/Button'
import Image from 'components/Image'
import { useTheme } from '@mui/material'
import {
  DollarSign,
  CreditCard,
  ZeroPercent,
  HandMoney,
  CalendarClock,
  MobileX,
} from 'components/Icons'
import { ROUTE_TAC, ROUTE_CLABE } from 'constants/routes'
import DATA from 'constants/pages/walletValueProposition.json'
import { useNavigate } from 'react-router-dom'
import SlideUpModal from 'components/SlideUpModal'
import segment from 'helpers/segment'

const WalletValueProposition = () => {
  const theme = useTheme()
  const [showSplash, setShowSplash] = useState(true)
  const [progress, setProgress] = useState(0)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    segment.page(DATA.SEGMENT.PAGE)
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setShowSplash(false)
          clearInterval(timer)

          return 0
        }
        const diff = Math.random() * 10

        return Math.min(oldProgress + diff, 100)
      })
    }, 200)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const renderIcon = (i) => {
    switch (i) {
      case 0:
        return <CreditCard color={theme.palette.primary.main} />
      case 1:
        return <DollarSign color={theme.palette.primary.main} />
      case 2:
        return <HandMoney color={theme.palette.primary.main} />
      default:
        return <ZeroPercent color={theme.palette.primary.main} />
    }
  }

  const renderIconSlide = (i) => {
    switch (i) {
      case 0:
        return <CalendarClock color={theme.palette.text.primary} />
      case 1:
        return <MobileX color={theme.palette.text.primary} />
      case 2:
        return <HandMoney color={theme.palette.text.primary} />
      default:
        return <ZeroPercent color={theme.palette.primary.main} />
    }
  }

  const splash = () => (
    <Box sx={{ margin: 'auto', textAlign: 'center', width: '80%' }}>
      <Stack
        sx={{
          height: '90vh',
          justifyContent: 'center',
        }}
      >
        <Image alt={DATA.splash.altImg} src={DATA.splash.image} />
        <Typography fontWeight={theme.fontWeight.bold} variant="body4">
          {DATA.splash.title}
        </Typography>
        <Typography color="surface.c700" fontWeight={theme.fontWeight.regular}>
          {DATA.splash.subtitle}
        </Typography>
      </Stack>
      <Box
        sx={{
          height: '10vh',
        }}
      >
        <LinearProgress
          color="success"
          value={progress}
          variant="determinate"
        />
      </Box>
    </Box>
  )

  const handleWalletOptionSelected = (event) => {
    segment.track(event)

    navigate(ROUTE_TAC)
  }

  const handleClabeOptionSelected = (event) => {
    segment.track(event)

    navigate(ROUTE_CLABE)
  }

  const openBottomSheet = () => {
    setOpen(true)
    segment.page(DATA.SEGMENT.PAGE_BOTTOM)
    segment.track(DATA.SEGMENT.EVENTS.CLABE_OPTION_SELECTED)
  }

  const page = () => (
    <Box sx={{ margin: 'auto', padding: 2, textAlign: 'center' }}>
      <Stack
        sx={{
          height: '80vh',
          justifyContent: 'center',
        }}
      >
        <Image alt={DATA.page.altImg} src={DATA.page.image} />
        <Typography fontWeight={theme.fontWeight.bold} variant="body4">
          {DATA.page.title}
        </Typography>
        <List
          aria-label="Requisites"
          sx={{ padding: 1.5, pb: 2, pt: 0 }}
          variant="compact"
        >
          {DATA.page.items.map((item, idx) => (
            <ListItem
              key={item}
              sx={{
                borderBottom:
                  idx !== DATA.page.items.length - 1 &&
                  '1px solid #F2F4F7 !important',
                pb: '16px !important',
                pt: '16px !important',
              }}
            >
              <ListItemIcon
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '1rem',
                  justifyContent: 'center',
                  padding: 2,
                  width: '1rem',
                }}
              >
                {renderIcon(idx)}
              </ListItemIcon>
              <Typography
                dangerouslySetInnerHTML={{ __html: item }}
                sx={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  fontWeight: theme.fontWeight.regular,
                }}
                variant="body2"
              />
            </ListItem>
          ))}
        </List>
        <Typography variant="body0">{DATA.page.disclaimer}</Typography>
      </Stack>
      <Stack spacing={2} sx={{ '& button': { height: 48 }, mt: 3 }}>
        <Button
          color="orange"
          onClick={() =>
            handleWalletOptionSelected(
              DATA.SEGMENT.EVENTS.WALLET_OPTION_SELECTED
            )
          }
          size="full-width"
        >
          {DATA.page.primaryButton}
        </Button>
        <Button
          color="white"
          onClick={openBottomSheet}
          size="full-width"
          sx={{ minWidth: '100%' }}
        >
          {DATA.page.secondaryButton}
        </Button>
      </Stack>
    </Box>
  )

  return (
    <Box sx={{ background: theme.palette.white.main, width: '100%' }}>
      {showSplash ? splash() : page()}
      <SlideUpModal
        onClose={() => setOpen(false)}
        open={open}
        sx={{
          '& .MuiDialog-container .MuiPaper-root': {
            paddingTop: 1.5,
          },
          '& .MuiDialog-container h2': { position: 'absolute', top: 10 },
        }}
        title={DATA.slideUp.title}
      >
        <List
          aria-label="Requisites"
          sx={{ padding: 1.5, pb: 2, pt: 0 }}
          variant="compact"
        >
          {DATA.slideUp.items.map((item, idx) => (
            <ListItem
              key={item}
              sx={{
                borderBottom:
                  idx !== DATA.slideUp.items.length - 1 &&
                  '1px solid #F2F4F7 !important',
                pb: '16px !important',
                pt: '16px !important',
              }}
            >
              <ListItemIcon
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '1rem',
                  justifyContent: 'center',
                  padding: 2,
                  width: '1rem',
                }}
              >
                {renderIconSlide(idx)}
              </ListItemIcon>
              <Typography
                dangerouslySetInnerHTML={{ __html: item }}
                sx={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  fontWeight: theme.fontWeight.semibold,
                }}
                variant="body2"
              />
            </ListItem>
          ))}
        </List>
        <Stack spacing={2} sx={{ '& button': { height: 48 }, mt: 3 }}>
          <Button
            color="orange"
            onClick={() =>
              handleWalletOptionSelected(
                DATA.SEGMENT.EVENTS.WALLET_OPTION_SELECTED_BOTTOM
              )
            }
            size="full-width"
          >
            {DATA.slideUp.primaryButton}
          </Button>
          <Button
            color="white"
            onClick={() =>
              handleClabeOptionSelected(
                DATA.SEGMENT.EVENTS.CLABE_OPTION_SELECTED_BOTTOM
              )
            }
            size="full-width"
            sx={{ minWidth: '100%' }}
          >
            {DATA.slideUp.secondaryButton}
          </Button>
        </Stack>
      </SlideUpModal>
    </Box>
  )
}

export default WalletValueProposition
