import { createElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { Oval, Stroke, Attache, Trophy } from 'components/Icons'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import StepIcon from 'components/StepIcon'
import Button from 'components/Button'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import SETUP from 'constants/profileSetup.json'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import useStyles from './styles'

const icons = {
  activate: Trophy,
  businessData: Attache,
  signin: Oval,
  userData: Stroke,
}

const ProfileStepper = ({ items }) => {
  const { device } = useGlobalContext()
  const [steps, setSteps] = useState([])
  const [stopped, setStopped] = useState({ listIndex: 0, step: 0 })
  const navigate = useNavigate()
  const classes = useStyles()

  const isSubstepPending = (name, substepList) =>
    substepList.some(
      (substep) =>
        substep.stepId === name &&
        (substep.status === SETUP.STATE.pending ||
          substep.status === SETUP.STATE.kyc_rejected)
    )

  useEffect(() => {
    setSteps(
      items.map((step) => ({
        ...step,
        stepIcon: icons[step.id],
      }))
    )

    const stepIndex = items.findIndex(
      (step) => step.status === SETUP.STATE.pending
    )

    const subStepIndex = items[stepIndex]?.list.findIndex((ss) => {
      if (ss.stepId === 'kycCompleted' || ss.stepId === 'manualInformation') {
        return (
          isSubstepPending('kycCompleted', items[stepIndex].list) &&
          isSubstepPending('manualInformation', items[stepIndex].list)
        )
      }

      return ss.status !== SETUP.STATE.completed
    })

    setStopped({
      listIndex: subStepIndex,
      step: stepIndex,
    })
  }, [items])

  const isComplete = (stepCurrent) =>
    stepCurrent.status === SETUP.STATE.completed

  const handleClick = (redirect, subStep, eventName) => {
    cleverTap.event(SETUP.CLEVERTAP.EVENTS, {
      ActionType: SETUP.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: subStep,
    })
    segment.track(eventName)
    if (redirect) {
      navigate(redirect)
    }
  }

  const renderLabel = (step) => (
    <Box>
      <Typography color="black.main" variant="h4">
        {step.label}
      </Typography>
      <Typography color="black.main" variant="body0">
        {step.description}
      </Typography>
      {isComplete(step) && step.id !== 'userData' && (
        <List>
          {step.list.map((item) => (
            <ListItem key={item.stepId} sx={{ display: 'list-item' }}>
              {item.text}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )

  return (
    <Box className={classes.container}>
      <Stepper
        activeStep={stopped.step}
        className={classes.stepper}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            className={index === steps.length - 1 ? 'last' : null}
          >
            <StepLabel
              icon={
                <StepIcon badge={isComplete(step)}>
                  {createElement(step.stepIcon)}
                </StepIcon>
              }
            >
              {renderLabel(step)}
            </StepLabel>
            <StepContent>
              <Button
                color="orange"
                onClick={() =>
                  handleClick(
                    step.list[stopped.listIndex]?.url,
                    step.list[stopped.listIndex]?.text,
                    step.segmentEvent
                  )
                }
                size="small"
              >
                {index === stopped.step &&
                  step.list[stopped.listIndex]?.linkText}
              </Button>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

ProfileStepper.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string,
          stepId: PropTypes.string,
          text: PropTypes.string,
        })
      ),
      status: PropTypes.string,
    })
  ).isRequired,
}
export default ProfileStepper
