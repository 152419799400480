/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Header from 'components/Header'
import { Logo, Lock } from 'components/Icons'
import useStyles from './styles'
import dataDogInit from 'helpers/dataDog'
import { useTheme } from '@mui/material'
import DATA from './data.json'
import { isDevice } from 'utils'

const Layout = ({ children, header, skipCallback }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [headerHeight, setHeaderheight] = useState(undefined)

  useEffect(() => {
    dataDogInit()

    setHeaderheight(document.querySelector('.site-header')?.offsetHeight)
  }, [])

  const layout = () => (
    <section className={classes.layout}>
      <Header
        data={header}
        skipCallback={skipCallback}
        styles={!isDevice ? classes.positionInitial : classes.positionFixed}
      />
      <Box className={classes.boxContainer} sx={{ mt: `${headerHeight}px` }}>
        {children}
      </Box>
    </section>
  )

  return (
    <>
      {!isDevice ? (
        <Box className={classes.layoutDesktop}>
          <Box sx={{ margin: '16px auto' }}>
            <Logo size={3} />
          </Box>
          {layout()}
          <Stack
            direction="row"
            sx={{ alignItems: 'center', justifyContent: 'center', m: 2 }}
          >
            <Lock />
            <Typography fontWeight={theme.fontWeight.regular} variant="body1">
              {DATA.footnote}
            </Typography>
          </Stack>
        </Box>
      ) : (
        layout()
      )}
    </>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.shape({}),
  skipCallback: PropTypes.func,
  splash: PropTypes.shape({
    icon: PropTypes.string,
    subtitle: PropTypes.string,
    timeout: PropTypes.number,
    title: PropTypes.string,
  }),
}

Layout.defaultProps = {
  children: '',
  header: null,
  skipCallback: null,
  splash: null,
}
