import SvgIcon from '@mui/material/SvgIcon'

const RadioIconChecked = () => (
  <SvgIcon viewBox="-3 -2 24 24">
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15073_218165)">
        <rect fill="#FC4C02" height="20" rx="10" width="20" />
        <g filter="url(#filter0_d_15073_218165)">
          <circle cx="10" cy="10" fill="white" r="4" />
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="12"
          id="filter0_d_15073_218165"
          width="12"
          x="4"
          y="6"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.101961 0 0 0 0 0.211765 0 0 0 0.16 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_15073_218165"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_15073_218165"
            mode="normal"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_15073_218165">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default RadioIconChecked
