import SvgIcon from '@mui/material/SvgIcon'

const World = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#FEEBE4" height="24" rx="8" width="24" y="0.998047" />
      <path
        d="M8.66667 11.3314H12M12 11.3314L10.3333 9.66471M12 11.3314L10.3333 12.998M15.3333 14.6647H12M12 14.6647L13.6667 12.998M12 14.6647L13.6667 16.3314M16.2427 8.75552C18.586 11.0988 18.586 14.8974 16.2427 17.2407C13.8994 19.584 10.1008 19.584 7.75748 17.2407C5.41417 14.8974 5.41417 11.0988 7.75748 8.75552C10.1008 6.41222 13.8994 6.41222 16.2427 8.75552Z"
        stroke="#FC4C02"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)

export default World
