import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import FormPassword from 'components/Form/Password'
import { useUserFormContext } from 'components/Form/store/user'
import LoadingDialog from 'components/LoadingDialog'
import {
  ENV,
  ROUTE_HOME,
  ROUTE_API_SIGNUP,
  ROUTE_PASSWORD_SETUP,
  ROUTE_VALIDATION,
} from 'constants/routes'
import cleverTap from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { postInfo } from 'helpers/fetchUtils'
import segment from 'helpers/segment'

import DATA from 'constants/pages/passwordConfirmation.json'
import DIALOGS from 'constants/dialogs.json'
import SubmitError from '../components/SubmitError'

const PasswordConfirmationPage = () => {
  const { device } = useGlobalContext()
  const [success, setSuccess] = useState(false)
  const [saving, setSaving] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const navigate = useNavigate()
  const { userState: formState } = useUserFormContext()
  const inputId = DATA.FORMIKS_ID.INPUT_ID

  useEffect(() => {
    if (!formState.email) {
      navigate(ROUTE_HOME)
    }
    if (!formState.password) {
      navigate(ROUTE_PASSWORD_SETUP)
    }

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.VIEW,
      OS: device.type,
    })

    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const sendCTError = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
      ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE[0],
      OS: device.type,
    })
  }

  const checkSamePassword = (value) => {
    const returnValue = value === formState.password

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const PASSWORD_VALIDATION = yup.object().shape({
    [inputId]: yup
      .string()
      .test('test-match', DATA.errorLabel, (value) => checkSamePassword(value)),
  })

  const formik = useFormik({
    initialValues: {
      [inputId]: '',
    },
    onSubmit: () => {
      setSaving(true)
      postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_SIGNUP}`, {
        ...DATA.signup,
        email: formState.email,
        password: formState.password,
      })
        .then((response) => {
          setSuccess(response?.status === 200)
        })
        .catch(() => {
          setOpenSnackbar(true)
        })
        .finally(() => setSaving(false))
    },
    validationSchema: PASSWORD_VALIDATION,
  })

  const nextPage = () => {
    navigate(ROUTE_VALIDATION)
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={DATA.content}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        onContinue={formik.handleSubmit}
      >
        <>
          <LoadingDialog
            callback={nextPage}
            content={DIALOGS.saving}
            done={success}
            loading={saving}
          />
          <form data-testid="form" onSubmit={formik.handleSubmit}>
            <FormPassword
              error={formik.touched[inputId] && formik.errors[inputId]}
              id={inputId}
              label={DATA.label}
              onChange={formik.handleChange}
              value={formik.values[inputId]}
            />
          </form>
          <SubmitError
            onClose={() => setOpenSnackbar(false)}
            open={openSnackbar}
          />
        </>
      </StepWrapper>
    </Layout>
  )
}

export default PasswordConfirmationPage
