/* eslint-disable react/style-prop-object */
import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const ZeroPercent = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 32 32">
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="25"
        id="mask0_23368_8370"
        maskUnits="userSpaceOnUse"
        width="24"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" y="0.317383" />
      </mask>
      <g mask="url(#mask0_23368_8370)">
        <path
          d="M14.5811 12.4911C13.8651 12.4911 13.2585 12.238 12.7613 11.7318C12.2641 11.2256 12.0155 10.6145 12.0155 9.8985C12.0155 9.18246 12.2641 8.57586 12.7613 8.07868C13.2585 7.58151 13.8651 7.33292 14.5811 7.33292C15.2972 7.33292 15.9083 7.58151 16.4144 8.07868C16.9206 8.57586 17.1737 9.18246 17.1737 9.8985C17.1737 10.6145 16.9206 11.2256 16.4144 11.7318C15.9083 12.238 15.2972 12.4911 14.5811 12.4911ZM14.58 10.5335C14.7567 10.5335 14.9069 10.4716 15.0306 10.348C15.1543 10.2243 15.2161 10.0741 15.2161 9.89741C15.2161 9.72071 15.1543 9.57539 15.0306 9.46145C14.9069 9.34751 14.7567 9.29055 14.58 9.29055C14.4033 9.29055 14.258 9.34751 14.1441 9.46145C14.0301 9.57539 13.9732 9.72071 13.9732 9.89741C13.9732 10.0741 14.0301 10.2243 14.1441 10.348C14.258 10.4716 14.4033 10.5335 14.58 10.5335ZM20.4184 18.3174C19.7054 18.3174 19.0951 18.0654 18.5876 17.5614C18.08 17.0574 17.8263 16.4488 17.8263 15.7358C17.8263 15.0228 18.0794 14.4151 18.5856 13.9127C19.0917 13.4104 19.7028 13.1592 20.4189 13.1592C21.1349 13.1592 21.7441 13.4112 22.2465 13.9152C22.7488 14.4192 23 15.0277 23 15.7408C23 16.4538 22.748 17.0615 22.244 17.5639C21.74 18.0662 21.1314 18.3174 20.4184 18.3174ZM20.42 16.3598C20.5967 16.3598 20.742 16.299 20.8559 16.1774C20.9699 16.0559 21.0268 15.9083 21.0268 15.7346C21.0268 15.561 20.9699 15.4146 20.8559 15.2955C20.742 15.1764 20.5967 15.1168 20.42 15.1168C20.2433 15.1168 20.0931 15.1776 19.9694 15.2992C19.8457 15.4207 19.7839 15.5683 19.7839 15.742C19.7839 15.9156 19.8457 16.062 19.9694 16.1811C20.0931 16.3002 20.2433 16.3598 20.42 16.3598ZM13.3672 18.3174L12 16.9502L21.6483 7.31738L23 8.66908L13.3672 18.3174Z"
          fill={color}
        />
        <path
          d="M5.5 18.3174C4.1494 18.3174 3.06175 17.7969 2.23705 16.7558C1.41235 15.7148 1 14.4019 1 12.8174C1 11.2022 1.41235 9.88169 2.23705 8.85597C3.06175 7.83024 4.1494 7.31738 5.5 7.31738C6.8506 7.31738 7.93825 7.83791 8.76295 8.87896C9.58765 9.92001 10 11.2328 10 12.8174C10 14.4019 9.58765 15.7148 8.76295 16.7558C7.93825 17.7969 6.8506 18.3174 5.5 18.3174ZM5.5 16.1598C6.23506 16.1598 6.78635 15.8238 7.15388 15.1518C7.52141 14.4798 7.70518 13.7016 7.70518 12.8174C7.70518 11.9331 7.52141 11.155 7.15388 10.483C6.78635 9.81095 6.23506 9.47494 5.5 9.47494C4.76494 9.47494 4.21365 9.81095 3.84612 10.483C3.47859 11.155 3.29482 11.9331 3.29482 12.8174C3.29482 13.7016 3.47859 14.4798 3.84612 15.1518C4.21365 15.8238 4.76494 16.1598 5.5 16.1598Z"
          fill={color}
        />
      </g>
    </svg>
  </SvgIcon>
)

export default ZeroPercent

ZeroPercent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

ZeroPercent.defaultProps = {
  color: '#FC4C02',
  size: 1.5,
}
