import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const DrinkGlass = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 24">
    <svg
      fill="none"
      height="18"
      viewBox="0 0 16 18"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99987 11L14.7001 2.62469C15.2239 1.96993 14.7578 1 13.9192 1H2.08049C1.24198 1 0.775811 1.96993 1.29962 2.6247L7.99987 11ZM7.99987 11V17M3.99987 17H7.99987M7.99987 17H11.9999M3.99986 5.5H11.9999"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default DrinkGlass

DrinkGlass.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

DrinkGlass.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
