import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Plane = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 24 24">
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 21L8 22V20.2C8 20.1 8.1 19.9 8.2 19.8L10 18.2V13.4L2.7 16.6C2.4 16.8 2 16.6 2 16.2V14.5C2 14.2 2.2 13.9 2.4 13.7L10 8.7V4C10 2.9 10.9 2 12 2C13.1 2 14 2.9 14 4V8.7L21.6 13.7C21.9 13.9 22 14.2 22 14.5V16.2C22 16.6 21.6 16.8 21.3 16.7L14 13.5V18.3L15.8 19.9C15.9 20 16 20.1 16 20.3V22L12 21Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Plane

Plane.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Plane.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
