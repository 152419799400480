import { Stack, CircularProgress } from '@mui/material'

const LoadingApp = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      background: '#fff',
      height: '100%',
      width: '100%',
    }}
  >
    <CircularProgress color="primary" size="32px" />
  </Stack>
)

export default LoadingApp
