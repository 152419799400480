import PropTypes from 'prop-types'
import React from 'react'
import OTPInput from 'react-otp-input'

import DATA from './constData.json'

export const OTPFormInput = ({
  autofocus,
  containerStyle,
  disabled,
  id,
  inputStyle,
  numInputs,
  onChangeInternal,
  onPaste,
  placeholder,
  separator,
  value,
}) => (
  <OTPInput
    containerStyle={containerStyle}
    inputStyle={inputStyle}
    inputType="number"
    isInputNum
    numInputs={numInputs || DATA.inputCount}
    onChange={onChangeInternal}
    onPaste={onPaste}
    placeholder={placeholder || DATA.placeholder}
    renderInput={(props, idx) => (
      <input {...props} disabled={disabled} id={`${id}-${idx}`} />
    )}
    renderSeparator={separator}
    shouldAutoFocus={autofocus}
    value={value}
  />
)

export default OTPFormInput

OTPFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

OTPFormInput.defaultProps = {
  value: '',
}
