const MuiFormControl = (theme) => ({
  styleOverrides: {
    root: {
      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.text.primary,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.backgrounds[2],
        borderRadius: theme.space[2],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.black.main,
        borderWidth: '1px',
      },
    },
  },
  variants: [
    {
      props: { behavior: 'uneditable' },
      style: {
        pointerEvents: 'none',
      },
    },
  ],
})

export default MuiFormControl
