import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Hotel = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 18 20"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5V6M5 10V11M9 5V6M9 10V11M13 5V6M13 10V11M6 18.5V14H12V19M1 19H17M9 14V19M2 19V3C2 1.89543 2.89543 1 4 1H14C15.1046 1 16 1.89543 16 3V19H2Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Hotel

Hotel.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Hotel.defaultProps = {
  color: theme.palette.text.primary,
  size: 1.5,
}
