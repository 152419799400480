import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  password: {
    '& .MuiOutlinedInput-notchedOutline': {
      paddingLeft: `${theme.space[4]}px`,
      paddingRight: `${theme.space[4]}px`,
    },
    '& input': {
      height: 20,
      marginTop: 18,
      padding: `${theme.space[2]}px ${theme.space[4]}px`,
    },
    '& label.MuiFormLabel-root': {
      top: 1,
    },
    '& label.MuiInputLabel-shrink': {
      top: 18,
    },
  },
}))

export default useStyles
