import SvgIcon from '@mui/material/SvgIcon'

const House = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7.88889V19C19 19.5523 18.5523 20 18 20H14M5 7.88889V19C5 19.5523 5.44772 20 6 20H10M3 9L12 4L21 9M10 20V14H14V20M10 20H14"
        stroke="#5E5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default House
