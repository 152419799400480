import { useState } from 'react'
import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import Image from 'components/Image'
import Paper from 'components/Paper'
import Button from 'components/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import DATA from 'constants/pages/installments.json'
import { useNavigate } from 'react-router-dom'
import {
  ENV,
  ROUTE_CLABE,
  ROUTE_FIRST_SALE,
  SET_INSTALLMENTS,
} from 'constants/routes'
import { useTheme } from '@mui/material'
import { postInfo } from 'helpers/fetchUtils'
import segment from 'helpers/segment'
import { getVersion } from 'helpers/versionHelpers'
import { useUserFormContext } from 'components/Form/store/user'

const Installments = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const activateUrl = `${ENV.REACT_APP_API_URL_2}${SET_INSTALLMENTS}`
  const version = parseFloat(getVersion(), 10)
  const { userState } = useUserFormContext()
  const route =
    version === 1.2 && userState.kycAccepted ? ROUTE_FIRST_SALE : ROUTE_CLABE

  const activate = () => {
    setLoading(true)
    segment.track(DATA.SEGMENT.EVENTS.ACTIVATE)
    const payload = {
      configuration_term: DATA.installmentsConfig.configuration_term,
      price_threshold: DATA.installmentsConfig.price_threshold,
    }

    postInfo(activateUrl, payload)
      .then(() => {
        navigate(route)
      })
      .catch(() => {
        navigate(route)
      })
  }

  const handleOmit = () => {
    segment.track(DATA.SEGMENT.EVENTS.OMIT)
    navigate(route)
  }

  return (
    <Layout header={DATA.header}>
      <Button
        onClick={handleOmit}
        sx={{
          position: 'absolute',
          right: { md: 24, xs: 8 },
          top: { md: 24, xs: 8 },
        }}
      >
        {DATA.omit}
      </Button>
      <StepWrapper
        loading={loading}
        onContinue={() => {
          activate()
        }}
        onContinueText={DATA.continueBtn}
      >
        <Stack sx={{ textAlign: 'center' }}>
          <Image alt={DATA.title} src={DATA.image} />
          <Typography sx={{ mb: 2, mt: 2 }} variant="hl">
            {DATA.title}
          </Typography>
          <Paper sx={{ pb: 2, pl: 2 }}>
            {DATA.bullets.map((item) => (
              <Typography
                key={item}
                dangerouslySetInnerHTML={{ __html: item }}
                sx={{
                  '&::before': {
                    color: theme.palette.primary.main,
                    content: '"•"',
                    fontSize: 30,
                    lineHeight: 0,
                    marginRight: 1,
                    position: 'relative',
                    top: 3,
                  },
                  mt: 1,
                  textAlign: 'left',
                }}
                variant="h4"
              />
            ))}
            <Divider sx={{ m: 2 }} />
            <Typography variant="hxs">{DATA.disclaimer}</Typography>
          </Paper>
          <Typography sx={{ mt: 3 }} variant="body0">
            {DATA.footNote}
          </Typography>
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default Installments
