import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useTheme } from '@mui/material/styles'
import {
  Paper,
  Typography,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material'

import Layout from 'components/Layout'
import StepWrapper from 'components/StepWrapper'
import SubmitError from 'components/SubmitError'
import LoadingDialog from 'components/LoadingDialog'

import { ErrorHelper } from 'components/Icons'
import DATA from 'constants/pages/personalInformation.json'
import { fetchInfo } from 'helpers/fetchUtils'
import {
  ENV,
  ROUTE_API_USER,
  ROUTE_HOME,
  ROUTE_TAC,
  ROUTE_WALLET_PROPOSITION,
} from 'constants/routes'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import { randomString } from 'utils'

import { useNavigate } from 'react-router-dom'
import { getToken } from 'helpers/tokenHelpers'
import { useFlags } from 'launchdarkly-react-client-sdk'

const createTableData = (data) => {
  const tableData = [
    {
      label: DATA.content.nameLabel,
      value: `${data.first_name || '-'} ${data.last_name || '-'} ${
        data.second_last_name || ' '
      }`,
    },
    {
      label: DATA.content.genderLabel,
      value: data.gender || '-',
    },
    {
      label: DATA.content.birthdayLabel,
      value: data.date_of_birth || '-',
    },
    {
      label: DATA.content.birthStateAndCountryLabel,
      value: data.state ? `${data.state}, Mexico` : '-',
    },
    {
      label: DATA.content.nationalityLabel,
      value: data.nationality || '-',
    },
    {
      label: DATA.content.curpLabel,
      value: data.curp || '-',
    },
    {
      label: DATA.content.emailLabel,
      value: data.email || '-',
    },
    {
      label: DATA.content.phoneLabel,
      value: data.mobile || '-',
    },
    {
      label: DATA.content.addressLabel,
      value: `
       ${data.address.first_address || '-'}
       ${data.address.second_address || ''},
       ${data.address.postal_code || '-'},
       ${data.address.colony || '-'},
       ${data.address.municipality || '-'},
       ${data.address.state || '-'}
       `,
    },
  ]

  return tableData
}

const PersonalInformation = () => {
  const apiUrl = `${ENV.REACT_APP_API_URL}${ROUTE_API_USER}`
  const theme = useTheme()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { device } = useGlobalContext()
  const navigate = useNavigate()
  const { onboardingSemiMandatory } = useFlags()

  const [userData, setUserData] = useState({
    address: {
      colony: '',
      first_address: '',
      municipality: '',
      postal_code: '',
      second_address: '',
      state: '',
    },
    curp: '',
    date_of_birth: '',
    email: '',
    first_name: '',
    gender: '',
    last_name: '',
    mobile: '',
    nationality: '',
    second_last_name: '',
    state: '',
  })

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTE_HOME)
    }
    segment.page(DATA.SEGMENT.PAGE)

    const fetchData = async () => {
      setLoading(true)
      try {
        const { data } = await fetchInfo(apiUrl)

        setUserData(data)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        setOpenSnackbar(true)
      }

      setLoading(false)
    }

    fetchData()
  }, [])

  const formik = useFormik({
    initialValues: {
      [DATA.FORMIKS_ID.PERSONAL_INFO_CONFIRMATION]: false,
    },
    onSubmit: () => {
      cleverTap.event(DATA.CLEVERTAP.EVENTS, {
        ActionType: DATA.CLEVERTAP.ACTION_TYPE.SUBMIT,
        OS: device.type,
        Target: DATA.CLEVERTAP.TARGET.CONTINUE,
      })

      if (onboardingSemiMandatory) {
        navigate(ROUTE_WALLET_PROPOSITION)
      } else {
        navigate(ROUTE_TAC)
      }
    },
  })

  const handleSubmit = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      OS: device.type,
      Target: DATA.CLEVERTAP.TARGET.CONTINUE,
    })

    if (!formik.values[DATA.FORMIKS_ID.PERSONAL_INFO_CONFIRMATION]) {
      setError(true)

      const errorElement = document.getElementById('checkbox')

      errorElement.scrollIntoView({ behavior: 'smooth' })

      return
    }

    formik.handleSubmit()
  }

  const renderData = (data) => {
    const tableData = createTableData(data)

    return tableData.map((item) => (
      <Stack key={randomString()} sx={{ marginBottom: '15px' }}>
        <Typography variant="body2">{item.label}</Typography>
        <Typography>{item.value}</Typography>
      </Stack>
    ))
  }

  const handleChange = (e) => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.CLICK,
      CheckBoxValue: e.target.checked
        ? DATA.CLEVERTAP.CHECKBOX.SELECTED[0]
        : DATA.CLEVERTAP.CHECKBOX.SELECTED[1],
      CheckboxName: e.target.labels[0].innerText,
      OS: device.type,
      Target: DATA.CLEVERTAP.CHECKBOX.TARGET,
    })

    formik.setFieldValue(e.target.name, e.target.checked)

    setError(false)
  }

  const labelStyles = {
    '& strong': {
      color: theme.palette.primary.main,
    },
    fontWeight: theme.fontWeight.regular,
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={{ ...DATA.content }}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        onContinue={handleSubmit}
      >
        <LoadingDialog content="Loading..." loading={loading} />
        <Paper
          sx={{
            borderColor: theme.palette.surface.c400,
            padding: '12px 16px',
          }}
          variant="outlined"
        >
          {renderData(userData)}
        </Paper>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values[DATA.FORMIKS_ID.PERSONAL_INFO_CONFIRMATION]
                    }
                  />
                }
                error={error}
                id="checkbox"
                label={
                  <Typography component="span" sx={labelStyles} variant="body1">
                    {DATA.content.checkboxLabel}
                  </Typography>
                }
                name={DATA.FORMIKS_ID.PERSONAL_INFO_CONFIRMATION}
                onChange={handleChange}
                sx={{ ml: 0 }}
                value={
                  formik.values[DATA.FORMIKS_ID.PERSONAL_INFO_CONFIRMATION]
                }
              />
            </Stack>
          </FormGroup>

          <FormHelperText error={error} sx={{ display: error ? null : 'none' }}>
            <ErrorHelper ml={1.5} mr={1} />
            {DATA.content.checkboxError}
          </FormHelperText>
        </form>
      </StepWrapper>
      <SubmitError onClose={() => setOpenSnackbar(false)} open={openSnackbar} />
    </Layout>
  )
}

export default PersonalInformation
