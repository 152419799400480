import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.surface.c200,
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.surface.c500,
      borderRadius: '39px',
      height: '149px',
    },
  },
}))

export default useStyles
