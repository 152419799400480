import SvgIcon from '@mui/material/SvgIcon'

const Bank = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9V17.9995M12 9H16M12 9H8M16 9V17.9995M16 9L20 8.99951M8 9V17.9995M8 9H4M4 9H2.5V6.361L12 3L21.5 6.361V9L20 8.99951M4 9V17.9995M20 8.99951V17.9995M12 6.49951H12.03M3 17.9995H21L22 18.9995V20.9995H2V18.9995L3 17.9995Z"
        stroke="#5E5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Bank
