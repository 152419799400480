import PropTypes from 'prop-types'
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material'
import { DoneCheckCircle } from 'components/Icons'
import CustomDialog from 'components/CustomDialog'
import DATA from './constData.json'

const LoadingDialog = ({ callback, content, done, loading }) => {
  const theme = useTheme()

  if (done && typeof callback === 'function') {
    setTimeout(callback, DATA.callbackTimeout)
  }

  const renderText = () => {
    if (done) {
      return content?.done ? content.done : DATA.done
    }

    return content?.loading ? content.loading : DATA.loading
  }

  return (
    <CustomDialog open={loading || done}>
      <Stack alignItems="center" justifyContent="center">
        {!done ? (
          <CircularProgress size="18px" />
        ) : (
          <DoneCheckCircle color={theme.palette.primary.main} />
        )}
        <Typography sx={{ mt: 3, textAlign: 'center' }} variant="body1">
          {renderText()}
        </Typography>
      </Stack>
    </CustomDialog>
  )
}

export default LoadingDialog

LoadingDialog.propTypes = {
  callback: PropTypes.func,
  content: PropTypes.shape({
    done: PropTypes.string,
    loading: PropTypes.string,
  }),
  done: PropTypes.bool,
  loading: PropTypes.bool,
}

LoadingDialog.defaultProps = {
  callback: null,
  content: null,
  done: false,
  loading: false,
}
