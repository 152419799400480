import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import theme from 'theme'

const Bluetooth = ({ color, size }) => (
  <SvgIcon sx={{ fontSize: `${size}rem` }} viewBox="0 0 14 20">
    <svg
      fill="none"
      height="20"
      viewBox="0 0 14 20"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16L13 5.5L7 1V19L13 14.5L1 4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Bluetooth

Bluetooth.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

Bluetooth.defaultProps = {
  color: theme.palette.white.main,
  size: 1.5,
}
