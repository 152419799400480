import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Button from 'components/Button'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.black.dark,
    opacity: '0.8 !important',
  },
  '& .MuiDialog-paper': {
    borderRadius: theme.space[3],
    boxShadow: 'none',
    height: 200,
    justifyContent: 'center',
    minWidth: 312,
    padding: theme.space[4],
  },
}))

const GenericDialog = ({
  open,
  outsideClick,
  children,
  content,
  callback,
  onClose,
}) => {
  const renderButtons = () => {
    if (content?.buttons) {
      return (
        <Stack
          sx={{
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {content.buttons.map((btn, index) => (
            <Button
              key={btn}
              color={index === 0 ? 'orange' : 'white'}
              onClick={index === 0 ? callback : !!onClose && onClose}
              size="full-width"
              sx={{ m: 1, width: '100%' }}
            >
              {btn}
            </Button>
          ))}
        </Stack>
      )
    }

    return null
  }

  return (
    <CustomDialog onBackdropClick={outsideClick} open={open}>
      <Stack alignItems="center" justifyContent="center">
        {children || (
          <Typography align="center" sx={{ mb: 3, mt: 0 }} variant="body1">
            {content.text}
          </Typography>
        )}
        {renderButtons()}
      </Stack>
    </CustomDialog>
  )
}

export default GenericDialog

GenericDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  children: PropTypes.node,
  content: PropTypes.shape({
    buttons: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  outsideClick: PropTypes.func,
}

GenericDialog.defaultProps = {
  children: null,
  onClose: null,
  open: false,
  outsideClick: null,
}
