/* eslint-disable no-useless-return */
/* eslint-disable array-callback-return */
import * as yup from 'yup'
import Layout from 'components/Layout'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { TickSuccess } from 'components/Icons'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import StepWrapper from 'components/StepWrapper'
import { RULES } from 'utils/password-requisites'
import FormPassword from 'components/Form/Password'
import {
  ENV,
  ROUTE_VALIDATION,
  ROUTE_HOME,
  ROUTE_API_SIGNUP,
} from 'constants/routes'
import { useUserFormContext, actions } from 'components/Form/store/user'
import cleverTap from 'helpers/clevertap'
import segment from 'helpers/segment'
import { useGlobalContext } from 'store/global'
import {
  isForbidden,
  checkValidateThree,
  validateConsecutive,
  validateUserName,
} from 'helpers/account'
import SubmitError from '../components/SubmitError'
import DATA from 'constants/pages/passwordForm.json'
import { postInfo } from 'helpers/fetchUtils'

const PasswordFormPage = () => {
  const { device } = useGlobalContext()
  const { userDispatch: formDispatch, userState: formState } =
    useUserFormContext()
  const theme = useTheme()
  const navigate = useNavigate()
  const [validSteps, setValidSteps] = useState(
    Array.from({ length: RULES.length }, () => false)
  )
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputId = 'passw'

  useEffect(() => {
    if (!formState.email) {
      navigate(ROUTE_HOME)
    }

    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.VIEW,
      OS: device.type,
    })

    segment.page(DATA.SEGMENT.PAGE)
  }, [])

  const checkValidity = (value) => {
    const steps = []
    let isInvalid = false

    RULES.map((req) => {
      const reqSatified = value?.match(req.match)

      if (!reqSatified) {
        isInvalid = true
      }

      steps.push(!!reqSatified)

      return true
    })

    // set steps state to show as list
    setValidSteps(steps)

    return !isInvalid
  }

  const sendCTError = () => {
    cleverTap.event(DATA.CLEVERTAP.EVENTS, {
      ActionType: DATA.CLEVERTAP.ACTION_TYPE.ERROR,
      ErrorMessage: DATA.CLEVERTAP.ERROR_MESSAGE[0],
      OS: device.type,
    })
  }

  const checkForbidden = (value) => {
    const returnValue = isForbidden(value) === undefined

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const checkValidThree = (value) => {
    const returnValue = !checkValidateThree(value)

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const checkConsecutive = (value) => {
    const returnValue = validateConsecutive(value)

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const checkUsername = (value) => {
    const returnValue = validateUserName(formState.email, value)

    if (!returnValue) {
      sendCTError()
    }

    return returnValue
  }

  const PASSWORD_VALIDATION = yup.object().shape({
    [inputId]: yup
      .string()
      .test('test-non-empty', DATA.errorLabel, (value) => !!value)
      .test('test-validity', DATA.errorLabel, (value) => checkValidity(value))
      .test(
        'test-forbidden-text',
        (input) => `${DATA.errorLabel2} ${isForbidden(input.value)}`,
        (value) => checkForbidden(value)
      )
      .test('test-3-repeat', DATA.errorLabel3, (value) =>
        checkValidThree(value)
      )
      .test('test-3-consecutive', DATA.errorLabel4, (value) =>
        checkConsecutive(value)
      )
      .test('forbid-username', DATA.errorLabel5, (value) =>
        checkUsername(value)
      ),
  })

  const formik = useFormik({
    initialValues: {
      [inputId]: '',
    },
    onSubmit: (values) => {
      setLoading(true)
      postInfo(`${ENV.REACT_APP_API_URL}${ROUTE_API_SIGNUP}`, {
        ...DATA.signup,
        email: formState.email,
        password: values[inputId],
      })
        .then(() => {
          segment.track(DATA.SEGMENT.EVENTS.CREATE_PASSWORD)
          setLoading(false)
          navigate(ROUTE_VALIDATION)
        })
        .catch(() => {
          setLoading(false)
          setOpenSnackbar(true)
        })

      formDispatch({
        password: values[inputId],
        type: actions.UPDATE_PASSWORD,
      })
    },
    validationSchema: PASSWORD_VALIDATION,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    formik.handleSubmit()

    if (
      formik.values[inputId].length === 0 ||
      Object.keys(formik.errors).length !== 0
    ) {
      segment.track(DATA.SEGMENT.EVENTS.NON_VALID_PASSWORD_INTENT)
    }
  }

  return (
    <Layout header={DATA.header}>
      <StepWrapper
        content={{ ...DATA.content }}
        eventCleverTap={DATA.CLEVERTAP.EVENTS}
        loading={loading}
        onContinue={handleSubmit}
      >
        <Stack alignItems="space-between">
          <form onSubmit={handleSubmit}>
            <FormPassword
              error={formik.touched[inputId] && formik.errors[inputId]}
              id={inputId}
              label={DATA.label}
              onChange={formik.handleChange}
              value={formik.values[inputId]}
            />
          </form>
          <List aria-label="Requisites" variant="compact">
            {RULES.map((req, index) => (
              <ListItem
                key={req.title}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <ListItemIcon>
                  <TickSuccess
                    color={
                      validSteps[index]
                        ? theme.palette.success.checkmark
                        : theme.palette.secondary.main
                    }
                    size={1.5}
                  />
                </ListItemIcon>
                <Typography
                  fontWeight={theme.fontWeight.semibold}
                  variant="body0"
                >
                  {req.title}
                </Typography>
              </ListItem>
            ))}
          </List>
          <SubmitError
            onClose={() => setOpenSnackbar(false)}
            open={openSnackbar}
          />
        </Stack>
      </StepWrapper>
    </Layout>
  )
}

export default PasswordFormPage
