import SvgIcon from '@mui/material/SvgIcon'

const Heart = () => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4C9.98528 4 12 7.42857 12 7.42857C12 7.42857 14.0147 4 16.5 4C18.9853 4 21 6.28571 21 8.57143C21 13.1429 12 20 12 20C12 20 3 13.1429 3 8.57143C3 6.28571 5.01472 4 7.5 4Z"
        stroke="#5E5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
)

export default Heart
