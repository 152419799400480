import { useEffect, useState } from 'react'

import { ENV, GET_ZIP_CODES } from 'constants/routes'
import { fetchInfo } from 'helpers/fetchUtils'

const ZIPCODE_LENGTH = 5

const useColonies = (value) => {
  const [result, setResult] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const apiUrl = `${ENV.REACT_APP_API_URL_2}${GET_ZIP_CODES}?code=${value}`

  useEffect(() => {
    const fetchData = async () => {
      if (value?.length === ZIPCODE_LENGTH) {
        try {
          const { data } = await fetchInfo(apiUrl)
          const values = []

          data?.mexico_zipcodes.map((item) =>
            values.push({
              label: item.colony,
              municipality: item.municipality,
              state: item.state,
              value: item.colony,
            })
          )
          if (values?.length === 0) {
            setError(true)
          } else {
            setResult(values)
          }
          setLoading(false)
        } catch (err) {
          setError(true)
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      setLoading(false)
      setResult(null)
      setError(false)
    }
  }, [value])

  return {
    error,
    loading,
    result,
  }
}

export default useColonies
