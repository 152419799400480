import Layout from 'components/Layout'
import Tutorial from 'components/Tutorial'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  ROUTE_FIRST_TRANSACTION_READER,
  ROUTE_FIRST_TRANSACTION_SUCCESS,
} from 'constants/routes'
import DATA from 'constants/pages/firstTransactionTutorial.json'

const FirstTransactionTutorial = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBack = () => {
    navigate(ROUTE_FIRST_TRANSACTION_READER)
  }

  const handleContinue = () => {
    navigate(ROUTE_FIRST_TRANSACTION_SUCCESS)
  }

  return (
    <Layout>
      <Tutorial
        handleContinue={handleContinue}
        handleGoBack={handleBack}
        slides={
          location.state.device.includes(DATA.devicePro)
            ? DATA.tutorialPro.slides
            : DATA.tutorialStand.slides
        }
      />
    </Layout>
  )
}

export default FirstTransactionTutorial
