import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from 'components/TextField'
import Stack from '@mui/material/Stack'
import { IconButton, InputAdornment, useTheme } from '@mui/material'
import { ErrorIcon, ErrorHelper } from 'components/Icons'

const FormText = (props) => {
  const {
    id,
    error,
    errorHelperIcon,
    label,
    value,
    onChange,
    variant,
    className,
    onClick,
    maxLength,
    ...extrProps
  } = props
  const theme = useTheme()
  const [hidePlaceholder, setHidePlaceholder] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      document?.getElementsByTagName('input')[0]?.focus()
    }, 500)

    return () => {
      document?.getElementsByTagName('input')[0]?.focus()
    }
  }, [])

  const handleBlur = () => setHidePlaceholder(false)
  const handleFocus = () => {
    if (label) {
      setHidePlaceholder(true)
    }
  }

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiFormHelperText-root.Mui-error': { fontSize: '14px' },
      }}
      variant="outlined"
    >
      <TextField
        className={className}
        error={!!error}
        id={id}
        inputProps={
          label && {
            'data-testid': `input-${id}`,
            maxLength: maxLength || null,
            placeholder: !hidePlaceholder ? value : null,
          }
        }
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: !!error && (
            <InputAdornment position="end">
              <IconButton edge="end">
                <ErrorIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={label}
        name={id}
        onBlur={handleBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={handleFocus}
        placeholder={!hidePlaceholder ? value : null}
        sx={
          label && {
            '& input': {
              height: '20px',
              marginTop: '18px',
              padding: `${theme.space[2]}px 14px`,
            },
            '& label.MuiFormLabel-root': {
              color: error
                ? theme.palette.error.main
                : theme.palette.surface.c700,
              fontWeight: theme.fontWeight.regular,
              pl: 0,
              top: 1,
              zIndex: 'auto',
            },
            '& label.MuiInputLabel-shrink': {
              top: 18,
            },
          }
        }
        value={value}
        variant={variant}
        {...extrProps}
      />
      {error && (
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', mt: 0.5 }}
        >
          {errorHelperIcon && <ErrorHelper ml="13px" size={0.85} />}
          {typeof error !== 'boolean' ? (
            <FormHelperText
              component="div"
              error
              id={`input-error-${id}`}
              sx={{ ml: 0 }}
            >
              {error}
            </FormHelperText>
          ) : null}
        </Stack>
      )}
    </FormControl>
  )
}

export default FormText

FormText.propTypes = {
  className: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  errorHelperIcon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
}

FormText.defaultProps = {
  className: undefined,
  error: false,
  errorHelperIcon: false,
  label: '',
  maxLength: null,
  onChange: () => {},
  onClick: null,
  value: undefined,
  variant: undefined,
}
