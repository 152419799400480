import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

const Icon = ({ color, size, sx }) => (
  <SvgIcon sx={{ fontSize: `${size}rem`, ...sx }} viewBox="0 0 56 56">
    <svg
      fill="none"
      height="58pt"
      viewBox="2 0 56 58"
      width="56pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill={color}
        stroke="none"
        transform="translate(0.000000,58.000000) scale(0.100000,-0.100000)"
      >
        <path d="M249 311 c-37 -30 -2 -83 44 -65 21 8 29 32 17 54 -14 27 -36 31 -61 11z" />
      </g>
    </svg>
  </SvgIcon>
)

export default Icon

Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.shape({}),
}

Icon.defaultProps = {
  color: '#5E5F73',
  size: 1.5,
  sx: null,
}
